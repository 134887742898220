import { useWeb3React } from '@web3-react/core'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { BigNumber, ethers, Overrides } from 'ethers'
import { groupBy, map, values as _values, merge, keyBy } from 'lodash'
import marketplaceApi from 'config/abi/AngelCreedMarketPlace.json'
import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import characterAbi from 'config/abi/character.json'
import {
  getMarketplaceAddress,
  getFakeCharacterAddress,
  getCharacterAddress,
  getNFTsTransferProxyAddress,
} from 'utils/addressHelpers'
import { getFakeCharacterContract, getCharacterContract, getMarketplaceContract } from 'utils/contractHelpers'
import { _getCharactersByIds, _getListingDataByIdentifiers } from 'state/marketplace/utils/contractInteraction'

import { fetchingMarket, updateMarketPage } from './actions'

import { State } from '../../types'
import { Listing } from '../types/type'

export const fetchSellerFee = createAsyncThunk('marketplace/fetchSellerFee', async (_) => {
  const marketplaceContract = getMarketplaceContract()
  try {
    return (await marketplaceContract?.sellerFee()).toString()
  } catch (e) {
    console.log(e)
  }
})

export const fetchBuyerFee = createAsyncThunk('marketplace/fetchBuyerFee', async (_) => {
  const marketplaceContract = getMarketplaceContract()
  try {
    return (await marketplaceContract?.buyerFee()).toString()
  } catch (e) {
    console.log(e)
  }
})

export const fetchApproveCharacterStatus = createAsyncThunk(
  'marketplace/fetchApproveCharacterStatus',
  async (account: string) => {
    const characterContract = getCharacterContract()
    const fakeCharacterContract = getFakeCharacterContract()
    return {
      isApprovedCharacter: await characterContract?.isApprovedForAll(account, getNFTsTransferProxyAddress()),
      isApprovedFakeCharacter: await fakeCharacterContract?.isApprovedForAll(account, getNFTsTransferProxyAddress()),
    }
  },
)

const fetchingMarketDataByPage = async (page = 0, limit = 52, account) => {
  const marketplaceContract = getMarketplaceContract()

  // Market's NFTs listed on page {counterPage}
  let tokenListed = await marketplaceContract?.getListedTokenIdByPage(BigNumber.from(page), BigNumber.from(limit))
  let listingCharacters = await _getListingDataByIdentifiers(
    getMarketplaceAddress(),
    marketplaceApi,
    'getListingByIdentifier',
    tokenListed,
  )

  let filterListingCharacters = listingCharacters.filter(
    (item) =>
      item.seller?.toLowerCase() !== account?.toLowerCase() &&
      (item.listingNft?.toLowerCase() === getCharacterAddress().toLowerCase() ||
        item.listingNft?.toLowerCase() === getFakeCharacterAddress().toLowerCase()),
  )

  while (tokenListed.length >= limit && filterListingCharacters.length <= limit) {
    tokenListed = await marketplaceContract?.getListedTokenIdByPage(BigNumber.from(++page), BigNumber.from(limit))
    listingCharacters = await _getListingDataByIdentifiers(
      getMarketplaceAddress(),
      marketplaceApi,
      'getListingByIdentifier',
      tokenListed,
    )

    filterListingCharacters = filterListingCharacters.concat(
      listingCharacters.filter(
        (item) => item.listingNft === getCharacterAddress() || item.listingNft === getFakeCharacterAddress(),
      ),
    )
  }

  return {
    filterListingCharacters,
    page,
  }
}

export const fetchListingCharacters = createAsyncThunk(
  'marketplace/fetchListingCharacters',
  async (account, { getState, dispatch }) => {
    try {
      let characters = []
      const {
        marketplace: {
          pagintion: { limit, marketPage },
        },
      } = getState() as State

      const { filterListingCharacters, page }: any = await fetchingMarketDataByPage(marketPage, limit, account)
      const groupedListing = groupBy(filterListingCharacters, 'listingNft')

      await Promise.all([
        _getCharactersByIds(
          getCharacterAddress(),
          characterAbi,
          'getHero',
          map(groupedListing[getCharacterAddress()], 'id'),
          true,
        ),
        _getCharactersByIds(
          getFakeCharacterAddress(),
          fakeCharacterAbi,
          'getHero',
          map(groupedListing[getFakeCharacterAddress()], 'id'),
          false,
        ),
      ]).then((values) => {
        characters = [
          ..._values(merge(keyBy(values[0], '_id'), keyBy(groupedListing[getCharacterAddress()], '_id'))),
          ..._values(merge(keyBy(values[1], '_id'), keyBy(groupedListing[getFakeCharacterAddress()], '_id'))),
        ]
      })

      dispatch(fetchingMarket(false))
      dispatch(updateMarketPage(page))

      return characters
    } catch (e) {
      console.log(e)
      return []
    }
  },
)

export const addListing = createAsyncThunk(
  'marketplace/addListing',
  async ({ account, id, price, paymentToken, listingNft, options }: Listing, { getState, dispatch }) => {
    try {
      const {
        marketplace: {
          fee: { sellerFee },
        },
      } = getState() as State

      const tx = await options.contract?.addListing(
        BigNumber.from(id),
        ethers.utils.parseEther(price.toString()),
        paymentToken,
        listingNft,
      )
      await tx.wait()

      options.callback({ success: true, tx: tx.hash, message: 'Add Listing success' })
      if (options.others.length > 0) {
        options.others.map((functionCall) => functionCall())
      }

      const character = (getState() as State).marketplace.inventoryCharacters[`${listingNft}_${id}`]
      const listingTime = Date.now() / 1000
      return {
        ...character,
        seller: account,
        paymentToken,
        listingNft,
        id,
        price,
        listingTime,
      }
    } catch (e) {
      options.callback({ success: false, tx: null, message: 'Add Listing fail' })
      if (options.others.length > 0) {
        options.others.map((functionCall) => functionCall())
      }
      return {}
    }
  },
)

export const cancelListing = createAsyncThunk(
  'marketplace/cancelListing',
  async ({ listingNft, id, options }: Listing, { getState, dispatch }) => {
    try {
      const tx = await options.contract?.cancelListing(listingNft, BigNumber.from(id))
      await tx.wait()

      options.callback({ success: true, tx: tx.hash, message: 'Cancel Listing success' })
      if (options.others?.length > 0) {
        options.others?.map((functionCall) => functionCall())
      }

      const character = (getState() as State).marketplace.inventoryCharacters[`${listingNft}_${id}`]
      return {
        ...character,
        seller: '',
        paymentToken: '',
        listingNft: '',
        id: '',
        price: '',
      }
    } catch (e: any) {
      options.callback({ success: false, tx: null, message: 'User denied transaction signature.' })
      if (options.others.length > 0) {
        options.others.map((functionCall) => functionCall())
      }
      return {}
    }
  },
)

export const purchaseListing = createAsyncThunk(
  'marketplace/purchaseListing',
  async ({ listingNft, id, price, paymentToken, options }: Listing, { getState, dispatch }) => {
    try {
      const {
        marketplace: {
          fee: { buyerFee },
        },
      } = getState() as State

      if (paymentToken === ethers.constants.AddressZero) {
        const feeRequired = ethers.utils
          .parseEther(price.toString())
          .mul(BigNumber.from(buyerFee).add(BigNumber.from(100)))
          .div(BigNumber.from(100))

        const overrides = {
          value: feeRequired,
        }

        const tx = await options.contract?.purchaseListing(listingNft, BigNumber.from(id), overrides as Overrides)
        await tx.wait()

        options.callback({ success: true, tx: tx.hash, message: 'Buy success' })
        if (options.others.length > 0) {
          options.others.map((functionCall) => functionCall())
        }
      } else {
        const tx = await options.contract?.purchaseListing(listingNft, BigNumber.from(id))
        await tx.wait()

        options.callback({ success: true, tx: tx.hash, message: 'Buy success' })
        if (options.others.length > 0) {
          options.others.map((functionCall) => functionCall())
        }
      }

      const character = (getState() as State).marketplace.listings[`${listingNft}_${id}`]
      return {
        ...character,
        seller: '',
        paymentToken: '',
        listingNft: '',
        id: '',
        price: '',
      }
    } catch (e: any) {
      if (options.others.length > 0) {
        options.others.map((functionCall) => functionCall())
      }
      options.callback({ success: false, tx: null, message: 'Error' })

      const textError = e?.data?.message ?? e?.message ?? e.toString()
      //binance
      if (textError.includes('Token ID not listed')) {
        options.callback({ success: false, tx: null, message: 'NFT sold out.' })
      } else if (textError.includes('Not enought coin')) {
        options.callback({ success: false, tx: null, message: 'Insufficient funds.' })
      } else if (textError.includes('Rejected by user')) {
        options.callback({ success: false, tx: null, message: 'Rejected by user.' })
      } else if (textError.includes('insufficient funds')) {
        options.callback({ success: false, tx: null, message: 'Insufficient funds.' })
      } else if (textError.includes('MetaMask Tx Signature')) {
        options.callback({ success: false, tx: null, message: 'User denied transaction signature.' })
      }

      // metamask
      if (e.data.message.includes('Token ID not listed')) {
        options.callback({ success: false, tx: null, message: 'NFT sold out.' })
      } else if (e.data.message.includes('insufficient funds')) {
        options.callback({ success: false, tx: null, message: 'Insufficient funds.' })
      } else if (e.message.includes('MetaMask Tx Signature')) {
        options.callback({ success: false, tx: null, message: 'User denied transaction signature.' })
      } else if (e.error.includes('Rejected by user')) {
        options.callback({ success: false, tx: null, message: 'Rejected by user.' })
      }
      return {}
    }
  },
)
