import { NATIVE } from '@diviner-protocol/v2-sdk'
import CardValue from 'components/CardValue'
import gameConfig from 'config/constants/game'
import { ethers } from 'ethers'
import { useActiveWeb3React } from 'hooks'
import { useToken } from 'hooks/Tokens'
import useRefresh from 'hooks/useRefresh'
import { useEffect, useState } from 'react'
import { useGameUserData } from 'state/hooks'
// import Menu from "./Menubar";
import { useCurrencyBalances } from 'state/wallet/hooks'
import styled from 'styled-components'
import { BoxAvatar, BoxParameter, Item, WidgetMenu, BoxLeader } from './styled'
import DropdownNetwork from './DropdownNetwork'

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'block' : 'none')};
  position: absolute;
  bottom: -50px;
  right: 0;
  left: 0;
  text-align: center;
  background-color: #191326;
  color: #ffffff;
  border-radius: 16px;
  opacity: 0.7;
`

export default function HomePage({ angelBalance, onConvert = null, onLeaderBoard = null }) {
  const { account, chainId, library } = useActiveWeb3React()
  const [balanceBNB, setBalanceBNB] = useState('0')
  const { userTokenData, userGameData } = useGameUserData()
  const { fastRefresh } = useRefresh()

  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState<boolean>(false)
  const trimAccount = account ? `${account.slice(0, 4)}...${account.slice(38)}` : '0x00...0000' // account length: 42

  // const ANGEL_TOKEN = useToken(gameConfig.angelAddresses[chainId])
  // const GEM_TOKEN = useToken(gameConfig.creedAddresses[chainId])
  // const GEM_TOKEN = useToken(gameConfig.creedAddresses[chainId])

  // const balances = useCurrencyBalances(account, [NATIVE[chainId]])
  // const balanceANGEL = balances[0]?.toFixed(4) || '0'
  // const balanceBNB = balances[0]?.toFixed(4) || '0'
  useEffect(() => {
    const fetchBnbBalance = async () => {
      const bnb = (await library.getBalance(account)).toString()
      setBalanceBNB(ethers.utils.formatEther(bnb))
    }
    if (account) fetchBnbBalance()
    else setBalanceBNB('0')
  }, [account, fastRefresh])

  // const {userGameData} = useGameUserData()
  // const balances = useCurrencyBalances(account, [ANGEL_TOKEN, GEM_TOKEN])
  // const balanceANGEL = balances[0]?.toFixed(4) || '0'
  const balanceANGEL = ethers.utils.formatEther(angelBalance)
  const balanceGEM = ethers.utils.formatEther(userGameData.gemReward)

  return (
    <>
      <WidgetMenu>
        <BoxAvatar>
          <div className="img-avatar">
            <img className="box-avatar" src="./images/homepage/box-avatar.gif" alt="" />

            <div className="avatar">
              <img src="./images/homepage/avatar.png" alt="" />
            </div>
          </div>

          <span>Angels creed</span>

          <p>
            {trimAccount}
            <button
              onClick={() => {
                if (navigator.clipboard) {
                  navigator.clipboard.writeText(account)
                  setIsTooltipDisplayed(true)
                  setTimeout(() => {
                    setIsTooltipDisplayed(false)
                  }, 1000)
                }
              }}
              type="button"
              aria-label="Copy"
            >
              <img src="./images/homepage/icon-copy.png" alt="" />
            </button>
            <Tooltip isTooltipDisplayed={isTooltipDisplayed}>Copied</Tooltip>
          </p>
        </BoxAvatar>

        {/* <BoxLeader className="boxLeader" onClick={onLeaderBoard}>
          <img src="./images/homepage/leader-board.svg" alt="leader-board.svg" />
          <p>Tournament Leaderboard</p>
        </BoxLeader> */}

        <BoxParameter>
          <Item>
            <div className="content">
              <img src="./images/icon/angels-token.png" alt="" />
              <span>
                <CardValue value={parseFloat(balanceANGEL) || 0} decimals={2}></CardValue>
              </span>
              <a
                href="https://pancakeswap.finance/swap?outputCurrency=0x205f93cd558aac99c4609d0511829194b5405533"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./images/homepage/icon-plus.png" alt="" />
              </a>
            </div>
          </Item>

          <Item className="parameter__gem">
            <div className="content">
              <img className="gem-token" src="./images/icon/gem-token.png" alt="" />
              <span>
                <CardValue value={parseFloat(balanceGEM) || 0} decimals={2}></CardValue>
              </span>
              <img src="./images/homepage/icon-plus.png" alt="" onClick={onConvert} />
            </div>
          </Item>

          {}

          <Item className="parameter__bnb">
            <div className="content">
              {chainId === 137 ? (
                <img className="bnb-token" src="/images/icon/polygon-matic.svg" alt="" />
              ) : (
                <img className="bnb-token" src="/images/icon/icon-bnb.svg" alt="" />
              )}
              <span>
                <CardValue value={parseFloat(balanceBNB) || 0} decimals={2}></CardValue>
              </span>
            </div>
          </Item>

          {/* <Menu/> */}
        </BoxParameter>

        <DropdownNetwork />
      </WidgetMenu>
    </>
  )
}
