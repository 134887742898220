import { useWeb3React } from '@web3-react/core'
import { Select } from 'antd'
import Button from 'components2/Common/Button'
import PopupConnect from 'components2/Common/Popup/PopupConnect'
import GlobalModal from 'components2/static/GlobalModal'
import { useCharacterContract, useGameContract } from 'hooks/useContract'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const { Option } = Select

function Migrate({ handleShowPopupTransaction }) {
  const [loading, setLoading] = useState<boolean>(false)
  const [isApprovedForAll, setIsApprovedForAll] = useState<boolean>(false)
  const { account, chainId } = useWeb3React()
  const [isApproving, setIsApproving] = useState<boolean>(false)
  const characterContract = useCharacterContract()
  const gameContract = useGameContract()

  useEffect(() => {
    characterContract.isApprovedForAll(account, gameContract.address).then((result) => {
      setIsApprovedForAll(result)
    })
  }, [account])

  const handleMigrate = async () => {
    try {
      setLoading(true)
      const tx = await gameContract.moveToPolygon()
      const { transactionHash } = await tx?.wait()
      GlobalModal.hide()
      handleShowPopupTransaction({
        success: true,
        tx: transactionHash,
        message: 'Migrate successfully',
      })
    } catch (error) {
      console.log('error migrate : ', error)
    } finally {
      setLoading(false)
    }
  }

  const handleApproveNFT = async () => {
    setIsApproving(true)
    try {
      const tx = await characterContract.setApprovalForAll(gameContract.address, true)

      await tx?.wait()
      setIsApproving(false)
      setIsApprovedForAll(true)
    } catch (error) {
      console.log('approve nft error : ', error)
      setIsApproving(false)
    }
  }

  return (
    <>
      <PopupConnect onClick={GlobalModal.hide} title="Migrate">
        <ConvertGemStyled>
          {!isApprovedForAll ? <Title>Approve all NFT to game contract</Title> : <Title>Confirm your migration</Title>}

          <BoxIconToken>
            <div className="icon__bsc">
              <img src="./images/bsc.png" alt="bsc" />
            </div>

            <div className="icon__arrow">
              <img src="./images/arrow-1.png" alt="bsc" />
              <img src="./images/arrow-2.png" alt="bsc" />
              <img src="./images/arrow-3.png" alt="bsc" />
            </div>

            <div className="icon__polygon">
              <img src="./images/polygon.png" alt="polygon" />
            </div>
          </BoxIconToken>

          <BoxContentCenter>
            {!isApprovedForAll ? (
              <Button onClick={handleApproveNFT} isLoading={isApproving}>
                Approve NFT
              </Button>
            ) : (
              <Button onClick={handleMigrate} isLoading={loading}>
                Migrate
              </Button>
            )}
          </BoxContentCenter>
        </ConvertGemStyled>
      </PopupConnect>
    </>
  )
}

const Title = styled.h2`
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 0;
  padding-top: 50px;
`

const ConvertGemStyled = styled.div`
  text-align: center;
  padding-bottom: 3rem;
  color: #ffffff;

  h3 {
    color: #ffffff;
    font-size: 2em;
  }

  .button-upgrade {
    margin: 0 auto;
  }
`

const BoxIconToken = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 50px;
  width: 100%;
  font-size: 1.4em;
  font-weight: 600;
  text-align: left;

  .icon {
    &__bsc {
    }

    &__arrow {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        &:first-of-type {
          animation: scrollanim 1.6s ease-in-out infinite;
          animation-delay: 1.5s;
        }

        &:nth-child(2) {
          animation: scrollanim 1.6s ease-in-out infinite;
          animation-delay: 1.5s;
        }

        &:nth-child(3) {
          animation: scrollanim 1.6s ease-in-out infinite;
          animation-delay: 1.5s;
        }

        @keyframes scrollanim {
          0% {
            -webkit-transform: translate(0, -20px);
            -moz-transform: translate(0, -20px);
            -ms-transform: translate(0, -20px);
            -o-transform: translate(0, -20px);
            transform: translate(-20px, 0);
            opacity: 0;
          }

          100% {
            -webkit-transform: translate(0, 0);
            -moz-transform: translate(0, 0);
            -ms-transform: translate(0, 0);
            -o-transform: translate(0, 0);
            transform: translate(0, 0);
            opacity: 0.8;
          }
        }
      }
    }

    &__polygon {
    }
  }
`

const BoxContentCenter = styled.div`
  display: flex;
  flex-direction: column;

  .ant-input-group {
    &.ant-input-group-lg {
      margin: 0 auto;
      width: 200px;
      display: block;

      input {
        box-shadow: inset 0px 4px 6px rgb(0 0 0 / 25%);
        font-size: 1.2em;
        font-weight: 600;
      }
    }
  }

  p {
    font-size: 1.2em;
    font-weight: 500;
  }
`

const BoxDayStyled = styled.div`
  background: #00000047;
  border-top: 2px solid #ffffff85;
  box-sizing: border-box;
  box-shadow: inset 0px 4px 6px rgb(0 0 0 / 25%);
  border-radius: 16px;
  max-width: 676px;
  margin: 0 auto;
  padding: 20px 0;

  > h3 {
    position: relative;
    padding-bottom: 10px;
    font-size: 22px;

    &:before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      height: 2px;
      width: 100%;
      background-image: linear-gradient(90deg, transparent, #fafafa, transparent);
    }
  }
`

export default Migrate
