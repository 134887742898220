import { Row } from 'antd'
import BigNumber from 'bignumber.js'
// import GlobalModal from 'components2/static/GlobalModal'
import ListItemWithTabs, { CardCharacter, CardEgg, CardEggDisable } from 'components2/Common/ListItemWithTabsEgg'
import Popup from 'components2/static/GlobalModal'
import { useActiveWeb3React } from 'hooks'
import useAuth from 'hooks/useAuth'
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from 'state'
import { fetchEgg, fetchGameUserData } from 'state/eggMarket'
import { useEggs, useEggUserData } from 'state/hooks'
import ConnectWallet from 'components2/Common/Popup/ConnectWallet'
import CardValue from 'components/CardValue'
import MenuHead from './components/header'
import PopupBuy from './components/PopupBuy'
import PopupSell from './components/PopupSell'
import { ConnectStyled, Styled } from './styled'

const statusOptions = [
  { label: 'On sale', value: 'ON_SALE' },
  { label: 'Avalible', value: 'AVALIBLE' },
]

export default function Egg() {
  const [activeTabId, setActiveTabId] = useState('TAB_EGG')
  const [eggType, setEggType] = useState(3)
  const { account } = useActiveWeb3React()

  const eggs = useEggs()
  const disableEggs = eggs.slice(0, 3)
  const ableEggs = eggs.slice(3, 6)

  const userData = useEggUserData()

  const dispatch = useAppDispatch()
  const { logout } = useAuth()

  useEffect(() => {
    if (account) {
      dispatch(fetchEgg())
      dispatch(fetchGameUserData(account))
    }
  }, [account])

  const handleShowPopup = () => {
    if (activeTabId === 'MY_INVENTORY') return Popup.show(<PopupSell closePopup={Popup.hide} />)
    if (activeTabId === 'TAB_EGG') return Popup.show(<PopupBuy closePopup={Popup.hide} />)
  }

  const handleShowPopupConnect = () => {
    if (!account) {
      Popup.show(<ConnectWallet onClose={Popup.hide} />)
    } else {
      logout()
    }
  }

  return (
    <Styled>
      {/* <Mask /> */}
      <MenuHead />
      <Row justify="center" className="space-top">
        {activeTabId !== 'MY_INVENTORY' ? (
          <>
            <div className="filter-group">
              <div className="section">
                <div className="label">Ratio</div>

                <div className="box-ratio">
                  <div className="box-ratio-item">
                    <img src="./images/egg/icon-1.png" alt="" />
                    <span>{eggs[eggType]?.ratio?.n}%</span>
                  </div>

                  <div className="box-ratio-item">
                    <img src="./images/egg/icon-2.png" alt="" />
                    <span>{eggs[eggType]?.ratio?.r}%</span>
                  </div>

                  <div className="box-ratio-item">
                    <img src="./images/egg/icon-3.png" alt="" />
                    <span>{eggs[eggType]?.ratio?.sr}%</span>
                  </div>

                  <div className="box-ratio-item">
                    <img src="./images/egg/icon-4.png" alt="" />
                    <span>{eggs[eggType]?.ratio?.ssr}%</span>
                  </div>
                </div>
              </div>

              <ul className="box-list">
                <li>
                  <span>Type:</span>

                  <strong>{eggs[eggType]?.ratio?.type}</strong>
                </li>

                <li>
                  <span>Stock:</span>

                  <strong>{eggs[eggType]?.remainEgg}</strong>
                </li>

                <li>
                  <span>Price:</span>

                  <strong>
                    <CardValue
                      value={parseFloat(new BigNumber(eggs[eggType]?.angelToBuy).div(1e18).toFixed(0)) || 0}
                      decimals={0}
                    ></CardValue>
                    <span>Angel</span>
                  </strong>
                </li>
              </ul>

              <p className="noitice">
                You&lsquo;ve bought <strong>{userData.amountEgg}/10</strong> eggs
              </p>
            </div>
          </>
        ) : (
          <div className="filter-group">
            <div className="section">
              <div className="label">Your hero</div>

              <div className="box-ratio">
                <div className="box-ratio-item">
                  <img src="./images/egg/icon-1.png" alt="" />
                  <span>{userData?.amountChar[0]}</span>
                </div>

                <div className="box-ratio-item">
                  <img src="./images/egg/icon-2.png" alt="" />
                  <span>{userData?.amountChar[1]}</span>
                </div>

                <div className="box-ratio-item">
                  <img src="./images/egg/icon-3.png" alt="" />
                  <span>{userData?.amountChar[2]}</span>
                </div>

                <div className="box-ratio-item">
                  <img src="./images/egg/icon-4.png" alt="" />
                  <span>{userData?.amountChar[3]}</span>
                </div>
              </div>
            </div>

            {/* <p className="noitice-explain">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Scelerisque nibh urna cursus a feugiat. Egestas
              lacinia enim, felis a elementum porttitor. Egestas eget cras libero, orci a amet maecenas tincidunt vel.
            </p> */}
          </div>
        )}

        <div className="group">
          <ListItemWithTabs
            tabs={[
              { name: 'Egg', id: 'TAB_EGG' },
              { name: 'inventory', id: 'MY_INVENTORY' },
            ]}
            onClickTab={(tab) => setActiveTabId(tab.id)}
            activeTabId={activeTabId}
            // filter={FilterInventory}
            market
          >
            {activeTabId === 'TAB_EGG' ? (
              <>
                {ableEggs.map((egg) => {
                  return (
                    <CardEgg
                      key={egg.id}
                      id={egg.id}
                      egg={egg}
                      userData={userData}
                      onSelectEgg={() => setEggType(egg.id)}
                    />
                  )
                })}
                {disableEggs.map((egg) => {
                  return <CardEggDisable id={egg.id} egg={egg} userData={userData} />
                })}
              </>
            ) : (
              userData.characters.map((character, index) => {
                return <CardCharacter key={index} character={character} />
              })
            )}
          </ListItemWithTabs>
        </div>
      </Row>

      <ConnectStyled>
        <div className="Connect__connect" onClick={handleShowPopupConnect}>
          <img src="./images/connect/connect-wallet.png" alt="Connectwallet.png" />
          <p>{account ? 'Logout' : 'Connect Wallet'}</p>
        </div>
      </ConnectStyled>
    </Styled>
  )
}
