import GlobalModal from 'components2/static/GlobalModal'
import { enemiesConfig } from 'config/constants/game'
import { useEffect, useMemo } from 'react'
import { useAppDispatch } from 'state'
import { setFightStatus } from 'state/game'
import { useActiveCharacter, useFightResult, useFightStatus, useSelectedRound } from 'state/hooks'
import CharStartFight from './components/CharStartFight'
import CharWin from './components/CharWin'
import FightingCloud from './components/FightingCloud'
import PopupResult from './components/PopupResult'
import VSLogo from './components/VSLogo'
import { FightSceneStyled } from './Styled'

const skills = [
  { thumbImg: '/images/skill/6.1.png', lv: 1 },
  { thumbImg: '/images/skill/6.1.png', lv: 1 },
  { thumbImg: '/images/skill/6.1.png', lv: 1 },
  { thumbImg: '/images/skill/6.1.png', lv: 1 },
  { thumbImg: '/images/skill/6.1.png', lv: 1 },
  { thumbImg: '/images/skill/6.1.png', lv: 1 },
  { thumbImg: '/images/skill/6.1.png', lv: 1 },
  { thumbImg: '/images/skill/6.1.png', lv: 1 },
]

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 4,
}

const createFightScript = (fightResult = false, reward, leftChar, rightChar, setCurrentPage) => {
  const leftCharImg = leftChar.animateImg
  const rightCharImg = rightChar?.img || '/images/pve/mon.gif'
  return [
    {
      description: 'Vs logo zoom in & zoom out',
      component: (
        <>
          <div className="main-character left">
            <img src={leftChar.animateImg} alt="left character" />
          </div>
          <VSLogo />
          <div className="main-character right">
            <img src={rightCharImg} alt="right character" />
          </div>
        </>
      ),

      timeOut: 3000,
    },
    {
      component: (
        <>
          <CharStartFight src={leftCharImg} rightSide={false} />
          <CharStartFight src={rightCharImg} rightSide />
          <FightingCloud />
        </>
      ),
      action: () => {
        // setTimeout(() => {
        //   return GlobalModal.show(
        //     <PopupResult fightResult={fightResult} reward={reward} onBackToFight={setCurrentPage('angelspve')} />,
        //   )
        // }, 5000)
      },
    },
    {
      component: (
        <CharWin leftCharImg={leftChar.animateImg} rightCharImg={rightCharImg} style={{ left: '45%', zIndex: 1 }} />
      ),
      timeOut: 5000,
    },
    {
      component: <></>,
    },
  ]
}

function FightScene({ setCurrentPage }) {
  // const [currentScriptState, setCurrentScriptState] = useState(0)
  const selectedHero = useActiveCharacter()
  const selectedRound = useSelectedRound()
  const dispatch = useAppDispatch()
  const fightResult = useFightResult()
  const fightStatus = useFightStatus()

  const fightScript = useMemo(
    () =>
      createFightScript(
        fightResult.gemReward !== '0',
        fightResult,
        selectedHero,
        enemiesConfig[selectedRound],
        setCurrentPage,
      ),
    [],
  )
  useEffect(() => {
    const timeOut = fightScript[fightStatus]?.timeOut
    const action = fightScript[fightStatus]?.action
    if (action) {
      action()
    }

    if (fightStatus === 3) {
      GlobalModal.show(
        <PopupResult
          fightResult={fightResult.gemReward !== '0'}
          reward={fightResult}
          onBackToFight={() => setCurrentPage('angelspve')}
        />,
      )
      return setCurrentPage('angelspve')
    }
    if (fightStatus === 0 || fightStatus === 2) {
      setTimeout(() => {
        dispatch(setFightStatus(fightStatus + 1))
      }, timeOut)
    }
  }, [fightStatus, fightResult])
  return (
    <FightSceneStyled>
      {fightScript[fightStatus]?.component}

      {/* <SlickContainerStyled> */}
      {/* <Slider {...settings}>
          {skills.map((charater, index) => (
            <Character key={index} thumbImg={charater.thumbImg} lv={charater.lv} />
          ))}
        </Slider>
      </SlickContainerStyled> */}
    </FightSceneStyled>
  )
}

export default FightScene
