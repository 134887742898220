import React, { useState } from 'react'
import { Pagination } from 'antd'
import Back from 'components2/Common/Back'
import { pageNames } from 'Pages'
import Styled from './styled'

export default function Inventory({ setCurrentPage }) {
  return (
    <Styled>
      <div className="top-bar">
        <Back onClick={() => setCurrentPage('home')} />
      </div>

      <div className="group">
        <div className="group__island">
          <div className="group__island__item" onClick={() => setCurrentPage(pageNames.mapgame)}>
            <img src="./images/island/1.png" alt="" />
          </div>

          <div className="group__island__item">
            <img src="./images/island/inserted-image.png" alt="" />
          </div>

          <div className="group__island__item lock">
            <img src="./images/island/2.png" alt="" />
          </div>

          <div className="group__island__item">
            <img src="./images/island/inserted-image.png" alt="" />
          </div>

          <div className="group__island__item" onClick={() => setCurrentPage(pageNames.mapgametwo)}>
            <img src="./images/island/3.png" alt="" />
          </div>

          <div className="group__island__item lock">
            <img src="./images/island/4.png" alt="" />
          </div>

          <div className="group__island__item lock">
            <img src="./images/island/5.png" alt="" />
          </div>

          <div className="group__island__item  lock">
            <img src="./images/island/6.png" alt="" />
          </div>

          <div className="group__island__item lock">
            <img src="./images/island/7.png" alt="" />
          </div>
        </div>

        <div className="group-iclound">
          <div className="group__iclound__item">
            <img src="./images/island/clound-1.png" alt="" />
          </div>

          <div className="group__iclound__item">
            <img src="./images/island/clound-2.png" alt="" />
          </div>

          <div className="group__iclound__item">
            <img src="./images/island/clound-3.png" alt="" />
          </div>

          <div className="group__iclound__item">
            <img src="./images/island/clound-4.png" alt="" />
          </div>

          <div className="group__iclound__item">
            <img src="./images/island/clound-bot.png" alt="" />
          </div>
        </div>
      </div>
    </Styled>
  )
}
