import Button from 'components2/Common/Button'
import React, { useState } from 'react'
import styled from 'styled-components'

export default function PopupMarket({ data = null, closePopup, children = null }) {
  return (
    <Styled>
      <img className="close-icon" src="/images/pve/close-icon.png" alt="close-icon" onClick={closePopup} />

      <div className="character">
        <div className="character__name">
          <p>
            <span>ID: </span>
            {data.tokenId}
          </p>
          <img src={data.rarityImg} alt="img" />
        </div>

        <div className="character__img">
          <img className="upgrade-character" src={data.reviewImg} alt="upgrade-character.png" />
          <img className="character__img--item" src="/images/popup/standing-pedestal.png" alt="Previous-icon.png" />
        </div>
      </div>

      <div className="ground active">
        <div className="ground__level">
          <div className="top-info">
            <div className="level">Lv.{data.level ? data.level : '1'}</div>
            <div className="name">{data.name}</div>
          </div>

          {data.attack ? (
            <div className="ground__level--experience content">
              <div className="experience--icon">
                <img src="/images/popup/icon1.png" alt="Icon1" /> <p>Attack</p>
              </div>
              <div className="experience--span">
                <span>{data?.attack}</span>
              </div>
              <div className="experience--icon">
                <img src="/images/popup/icon2.png" alt="Icon1" /> <p>Defense</p>
              </div>
              <div className="experience--span">
                <span>{data?.defense}</span>
              </div>
              <div className="experience--icon">
                <img src="/images/popup/icon3.png" alt="Icon1" /> <p>Speed</p>
              </div>
              <div className="experience--span">
                <span>{data?.speed}</span>
              </div>
            </div>
          ) : (
            <div className="ground__level--experience inactive-text">
              <div>
                This pre-sell NFT need to be active to reveal the stats.
                <br /> Please activate this NFT in-game.
              </div>
            </div>
          )}

          {children}
        </div>
      </div>
    </Styled>
  )
}

const Styled = styled.div`
  background-image: url('/images/pve/bg-popup.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 4rem 2rem;
  p {
    font-size: 20px;
    font-weight: 400;
    padding-left: 5px;
    color: #fff;
    margin: 0;
  }

  .ground__level {
    .button-upgrade {
      width: 240px;
    }
  }

  .character__name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    p {
      font-size: 1.7rem;
      font-weight: 500;
      color: #fff;
      padding-left: 0;
      span {
        font-weight: 700;
      }
    }
    img {
      width: 100px;
    }
  }

  .ground.active {
    .ground__level--experience {
      h3 {
        text-transform: capitalize;
      }
    }

    .inactive-text {
      padding: 23px 10px;
      font-size: 23px;
      text-align: center;
    }
    .success__content {
      padding: 1rem;
      padding-top: 2rem;
      text-align: center;
    }

    .cancel {
      position: absolute;
      left: 122px;
      bottom: 18px;
    }
    .button-upgrade {
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translate(-50%, 0px);
      font-size: 18px;
    }
  }

  .ground {
    position: relative;
    background: #0d0d0d80;
    margin: 0px 2rem;
    border-radius: 12px;
    width: 320px;

    .top-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem 0px;
      position: relative;
      color: #fff;

      &::before {
        position: absolute;
        content: '';
        background: radial-gradient(
          45.45% 45.45% at 50% 50%,
          #b0aaf5 0.01%,
          rgba(174, 182, 255, 0.3) 33.85%,
          rgba(189, 166, 255, 0) 100%
        );
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
      }
      .level,
      .name {
        font-family: Hind;
        font-weight: bold;
        font-size: 28px;
        line-height: 45px;
        text-align: center;
        color: #ffffff;
        margin-left: 35px;
      }
    }

    &::before {
      position: absolute;
      content: '';
      background: linear-gradient(
        0.01deg,
        rgba(189, 166, 255, 0) -8.32%,
        rgba(174, 182, 255, 0.3) 34.81%,
        #b0aaf5 108.32%
      );
      top: 0px;
      height: 10px;
      width: 100%;
      left: 0;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .ground__level--experience {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 1rem 0px;
      position: relative;
      color: #fff;
      &::before {
        position: absolute;
        content: '';
        background: radial-gradient(
          45.45% 45.45% at 50% 50%,
          #b0aaf5 0.01%,
          rgba(174, 182, 255, 0.3) 33.85%,
          rgba(189, 166, 255, 0) 100%
        );
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
      }
      h3 {
        color: #fff;
        font-weight: 700;
        font-size: 1.7rem;
        margin: 0;
        text-transform: uppercase;
      }
      .span--update {
        color: #567bff;
        padding-left: 10px;
      }
    }

    .ground__level--experience.content {
      display: grid;
      grid-template-columns: auto auto;

      .info {
        display: flex;
        justify-content: space-between;
        width: 205px;
      }

      .experience--span {
        display: flex;
        justify-content: left;
        align-items: center;
      }
      span {
        font-size: 20px;
        font-weight: 400;
      }
    }

    .ground__price {
      display: flex;
      justify-content: center;
      align-items: center;
      .experience--icon {
        padding: 1rem 10px;
      }
    }

    .experience--icon {
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 1rem 0px;
    }

    .ground__success {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: ease all 0.5s;
      padding: 10px 0px 5px 0px;

      img {
        margin-right: 10px;
      }
      &:hover {
        opacity: 0.8;
      }
      p {
        padding-left: 0;
        padding-right: 10px;
        color: #fff;
      }
    }
    .button {
      margin: 0px 40px;
    }
  }

  .close-icon {
    position: absolute;
    right: -25px;
    top: -33px;
    cursor: pointer;
  }
  .character {
    padding: 0px 2rem;

    .character__img {
      width: 320px;
      max-height: 385px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .character__img--item {
        position: absolute;
        bottom: -45px;
        left: 50%;
        transform: translate(-50%, 0%);
      }

      .upgrade-character {
        z-index: 10;
        width: 100%;
        height: 100%;
      }

      .next {
        transform: scaleX(-1);
      }
    }
  }

  .button-upgrade {
    margin: auto;
    font-weight: 500;
    font-size: 22px;
    line-height: 60px;
    text-align: center;
    color: #4b1b00;
    color: #fff;
    transition: 0.5s ease-in-out;

    &.disable {
      filter: grayscale();
      background-image: url('/images/popup/button-bg.gif');
      transition: 0.5s ease-in-out;
      cursor: no-drop;
      color: #211915;

      &:hover {
        opacity: 1;
      }
    }
  }
`
