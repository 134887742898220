import priceAbi from 'config/abi/PriceOracle.json'
import chestsConfig from 'config/constants/chest'
import multicall from 'utils/multicall'
import { getOracleAddress } from '../../utils/addressHelpers'

export const fetchChestData = async () => {
  try {
    const calls = []
    for (let i = 1; i <= 3; i++)
      calls.push({ address: getOracleAddress(), name: 'fragmentsNeedToOpenHeroChest', params: [i] })
    const rawChests = await multicall(priceAbi, calls, {
      requireSuccess: false,
    })
    const chests = rawChests.map((gemToOpen, index) => {
      return {
        gemToOpen: gemToOpen.toString(),
        ratio: chestsConfig[index],
        type: index + 1,
      }
    })
    return chests
  } catch (error) {
    console.log('error', error)
  }
}
