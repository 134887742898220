import styled from 'styled-components'

const Styled = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/images/mapgame/bg-map-2.png');
  padding: 60px;
  position: relative;

  .top-bar {
    display: flex;
  }

  .box-content {
    .item {
      position: relative;
      background: url('../images/mapgame/bg-waiting.png') no-repeat;
      background-size: contain;
      background-position: center;
      width: 80px;
      height: 65px;
      position: relative;
      color: #ffffff;
      font-size: 1.5em;
      font-weight: 600;
      text-align: center;
      line-height: 65px;
      cursor: pointer;
      transition: 0.5s ease-in-out;

      &:hover {
        transition: 0.5s ease-in-out;
        filter: drop-shadow(2px 4px 6px black);
      }

      &:first-of-type {
        position: absolute;
        top: 478px;
        left: 485px;
      }

      &:nth-child(2) {
        position: absolute;
        top: 555px;
        left: 700px;
      }

      &:nth-child(3) {
        position: absolute;
        top: 511px;
        left: 1109px;
      }

      &:nth-child(4) {
        position: absolute;
        top: 315px;
        left: 1059px;
      }

      &:nth-child(5) {
        position: absolute;
        top: 293px;
        left: 1328px;
      }

      &:nth-child(6) {
        position: absolute;
        top: 550px;
        left: 1394px;
      }

      &:nth-child(7) {
        position: absolute;
        top: 686px;
        left: 1158px;
      }

      &:nth-child(8) {
        position: absolute;
        top: 832px;
        left: 700px;
      }

      &:nth-child(9) {
        position: absolute;
        top: 832px;
        left: 1028px;
      }

      &:nth-child(10) {
        position: absolute;
        top: 794px;
        right: 438px;
      }

      &.active-star {
        background: url('../images/mapgame/bg-blue.png') no-repeat;
        height: 67px;
        line-height: 70px;

        &:before {
          content: '';
          position: absolute;
          top: -33px;
          left: -3px;
          background: url('../images/mapgame/icon-star.png') no-repeat;
          background-size: contain;
          background-position: center;
          width: 84px;
          height: 37px;
        }
      }

      &.active-knife {
        &:before {
          content: '';
          position: absolute;
          top: -45px;
          left: 13px;
          background: url('../images/mapgame/icon-knife.png') no-repeat;
          background-size: contain;
          background-position: center;
          width: 51px;
          height: 45px;
        }
      }

      &.active-win {
        background: url('../images/mapgame/bg-win.png') no-repeat;
        width: 72px;
        height: 69px;
        line-height: 69px;

        &:before {
          content: '';
          position: absolute;
          top: -65px;
          left: -30px;
          background: url('../images/mapgame/icon-monster.png') no-repeat;
          background-size: contain;
          background-position: center;
          width: 127px;
          height: 64px;
          .done {
            background: url('../images/mapgame/icon-monster-bad.png') no-repeat !important;
          }
        }
      }

      &.done {
        background: url('../images/mapgame/bg-win.png') no-repeat;
        width: 72px;
        height: 69px;
        line-height: 69px;

        &:before {
          content: '';
          position: absolute;
          top: -65px;
          left: -30px;
          background: url('../images/mapgame/icon-monster-bad.png') no-repeat;

          background-size: contain;
          background-position: center;
          width: 127px;
          height: 64px;
        }
      }
    }
  }

  .box-diamond-chest {
    position: absolute;
    z-index: 3;
    top: 72px;
    right: 189px;

    .ring:hover {
      cursor: pointer;
    }

    img {
      width: 317px;
    }
  }
`

export default Styled
