import Button from 'components2/Common/Button'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { gameCharacter } from 'config/constants/game'
import CardValue from 'components/CardValue'
import { getCharacterContract } from 'utils/contractHelpers'
import PopupConnect from 'components2/Common/Popup/PopupConnect'

import styled from 'styled-components'
import { useEffect, useState } from 'react'

export default function PopupUpgrade({ onClose, newHero }) {
  const [hero, setHero] = useState(null)
  const characterContract = getCharacterContract()
  const newCharacter = {
    id: newHero?._heroId.toString(),
    ...gameCharacter[newHero?.heroRarity.toNumber()][newHero?.heroName.toNumber()],
  }

  useEffect(() => {
    const fetchNewCharacter = async () => {
      try {
        setHero(await characterContract.getHero(newCharacter.id))
      } catch (error) {
        console.log('error to fetch new  character', error)
      }
    }
    fetchNewCharacter()
  }, [])
  return (
    <PopupConnect onClick={onClose} title="congratulations">
      <Styled>
        <img className="close-icon" src="/images/pve/close-icon.png" alt="close-icon" onClick={onClose} />

        <div className="character">
          <div className="character__name">
            <div>
              <p>ID: {newCharacter.id}</p>
            </div>
            <div>
              <img src={newCharacter.rarityImg} alt="" />
            </div>
          </div>

          <div className="character__img">
            {newCharacter && (
              <img className="upgrade-character" src={newCharacter?.reviewImg} alt="upgrade-character.png" />
            )}

            <img className="character__img--item" src="/images/popup/standing-pedestal.png" alt="Previous-icon.png" />
          </div>
        </div>

        <div className="ground active">
          <div className="ground__level">
            <div className="ground__level--experience">
              <>
                <h3>Lv.1</h3>
                <h3 className="name">{newCharacter.name}</h3>
              </>
            </div>
            <>
              <div className="ground__level--experience content">
                <div className="experience--icon">
                  <img src="/images/popup/icon1.png" alt="Icon1" /> <p>Attack</p>
                </div>
                <div className="experience--span">
                  <span>
                    <CardValue value={parseFloat(hero?.attack.toString()) || 0} decimals={0} />
                  </span>
                </div>
                <div className="experience--icon">
                  <img src="/images/popup/icon2.png" alt="Icon1" /> <p>Defense</p>
                </div>
                <div className="experience--span">
                  <span>
                    <CardValue value={parseFloat(hero?.defense.toString()) || 0} decimals={0} />
                  </span>
                </div>
                <div className="experience--icon">
                  <img src="/images/popup/icon3.png" alt="Icon1" /> <p>Speed</p>
                </div>
                <div className="experience--span">
                  <span>
                    <CardValue value={parseFloat(hero?.speed.toString()) || 0} decimals={0} />
                  </span>
                </div>
              </div>
            </>
            <>{/* <p className="success__content">lorem ipsum dolor sit amet, consectetur adip</p> */}</>
          </div>
        </div>
      </Styled>
    </PopupConnect>
  )
}

const Styled = styled.div`
  background-image: url('/images/pve/bg-popup.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 4rem 2rem;
  p {
    font-size: 20px;
    font-weight: 400;
    padding-left: 5px;
    color: #fff;
    margin: 0;
  }

  .ground__level {
    .button-upgrade {
      width: 250px;
    }
  }

  .character__name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 100px;
      margin-left: 109px;
      text-align: right;
    }
    p {
      text-transform: capitalize;
      font-size: 1.7rem;
      font-weight: 700;
      color: #fff;
      padding-left: 0;
    }
  }

  .ground {
    position: relative;
    background: #0d0d0d7a;
    margin: 0px 2rem;
    border-radius: 12px;
    width: 320px;
    height: 500px;

    &::before {
      position: absolute;
      content: '';
      background: linear-gradient(
        0.01deg,
        rgba(189, 166, 255, 0) -8.32%,
        rgba(174, 182, 255, 0.3) 34.81%,
        #b0aaf5 108.32%
      );
      top: 0px;
      height: 10px;
      width: 100%;
      left: 0;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    &.active {
      .ground__level--experience {
        h3 {
          text-transform: capitalize;
        }
        .name {
          margin-right: 76px;
        }
      }

      .success__content {
        padding: 1rem;
        padding-top: 2rem;
        text-align: center;
      }

      .button-upgrade {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }

    .ground__level--experience {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 1rem 20px;
      position: relative;
      color: #fff;
      &::before {
        position: absolute;
        content: '';
        background: radial-gradient(
          45.45% 45.45% at 50% 50%,
          #b0aaf5 0.01%,
          rgba(174, 182, 255, 0.3) 33.85%,
          rgba(189, 166, 255, 0) 100%
        );
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
      }
      h3 {
        color: #fff;
        font-weight: 700;
        font-size: 1.7rem;
        margin: 0;
        text-transform: uppercase;
      }
      .span--update {
        color: #567bff;
        padding-left: 10px;
      }
    }

    .ground__level--experience.content {
      display: grid;
      grid-template-columns: 200px 50px;
      .experience--span {
        display: flex;
        justify-content: left;
        align-items: center;
      }
      span {
        font-size: 20px;
        font-weight: 400;
      }
    }

    .ground__price {
      display: flex;
      justify-content: center;
      align-items: center;

      .experience--icon {
        padding: 0.7rem 10px;

        img {
          max-width: 34px;
        }
      }
    }

    .experience--icon {
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 1rem 0px;
    }

    .ground__success {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: ease all 0.5s;
      &:hover {
        opacity: 0.8;
      }
      p {
        padding-left: 0;
        padding-right: 10px;
        color: #567bff;
      }
    }
  }

  .close-icon {
    position: absolute;
    right: -25px;
    top: -33px;
    cursor: pointer;
  }
  .character {
    padding: 0px 2rem;
    .character__img {
      width: 320px;
      height: 425px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .character__img--item {
        position: absolute;
        bottom: 80px;
        left: 11%;
      }
      .upgrade-character {
        width: 316px;
        z-index: 10;
        margin-bottom: 52px;
      }
      .next {
        transform: scaleX(-1);
        :hover {
          transform: scaleX(-1.2);
          cursor: pointer;
        }
      }
      .previous:hover {
        transform: scaleX(1.2);
        cursor: pointer;
      }
    }
  }

  .button-upgrade {
    margin: auto;
    font-weight: 500;
    font-size: 22px;
    line-height: 60px;
    text-align: center;
    color: #4b1b00;
    padding: 0px 3rem;
    color: #fff;
    margin-top: 1rem;
    margin-bottom: 2rem;
    transition: 0.5s ease-in-out;

    &.disable {
      filter: grayscale();
      background-image: url('/images/popup/button-bg.gif');
      cursor: no-drop;
      color: #211915;
      transition: 0.5s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }
`
