import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import AutoScaleContain from 'components2/Common/AutoScaleContain'
import GlobalModal from 'components2/static/GlobalModal'
import useWeb3ReactManager from 'hooks/useWeb3ReactManager'
import React, { useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { changeNetwork, setupNetwork } from 'utils/wallet'
import Egg from 'views/Egg'
import Market from 'views/Market'
import Popups from './components/Popups'
import { ALL_SUPPORTED_CHAIN_IDS } from './constants'
import './index.scss'
import Pages from './Pages'

const fmt = {
  prefix: '',
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
  secondaryGroupSize: 0,
  fractionGroupSeparator: ' ',
  fractionGroupSize: 0,
  suffix: '',
}
// This config is required for number formating
BigNumber.config({ FORMAT: fmt, EXPONENTIAL_AT: 1000, DECIMAL_PLACES: 80 })

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released 12
  const [sizeWindow, setSizeWindow] = useState<any>()
  useEffect(() => {
    console.warn = () => null
    setSizeWindow({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, [])

  const { chainId } = useWeb3React()

  useWeb3ReactManager()

  useEffect(() => {
    if (chainId) {
      if (ALL_SUPPORTED_CHAIN_IDS.includes(chainId)) {
        window.localStorage.setItem('chainId', chainId.toString())
      } else {
        changeNetwork()
      }
    }
  }, [chainId])

  return (
    <HashRouter>
      <Popups />
      <FullScreenStyled sizeWindow={sizeWindow}>
        <AutoScaleContain>
          <FixedScreenSizeStyled>
            <Switch>
              <Route path="/" exact>
                <Pages />
              </Route>
              <Route path="/treasure-box">
                <Egg />
              </Route>
              <Route path="/market">
                <Market />
              </Route>
            </Switch>
            <GlobalModal />
          </FixedScreenSizeStyled>
        </AutoScaleContain>
      </FullScreenStyled>
    </HashRouter>
  )
}

interface Props {
  sizeWindow: any
}
const FullScreenStyled = styled.div<Props>`
  width: ${(props) => props.sizeWindow?.width + 'px'};
  height: ${(props) => props.sizeWindow?.height + 'px'};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
`

const FixedScreenSizeStyled = styled.div`
  width: 1920px;
  height: 1080px;
  background: url('../images/homepage/bg-home.png') no-repeat;
  position: relative;
  overflow: hidden;
  @media (max-width: 767px) {
    transform: rotate(90deg) scale(1.777);
    transform-origin: center;
  }
`

export default React.memo(App)
