import Connect from 'components2/Common/Connect/index'
import ConnectWallet from 'components2/Common/Popup/ConnectWallet'
import ConvertGem from 'components2/Common/Popup/ConvertGem'
import Migrate from 'components2/Common/Popup/Migrate'
import PopupNotice from 'components2/Common/Popup/PopupNotice'
import PopupTransaction from 'components2/Common/Popup/PopupTransition'
import PopupUpgrade from 'components2/Common/Popup/PopupUpgrade/index'
import GlobalModal from 'components2/static/GlobalModal'
import { useActiveWeb3React } from 'hooks'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useGameUserData } from 'state/hooks'
import ClaimReward from './Component/ClaimRewald'
import Faucet from './Component/Faucet'
import MenuHead from './Component/header'
import LeaderBoard from './Component/LeaderBoard'
import { BoxVideo, ControlMenu, Item, ItemControlMenu, MainContent, WidgetHome } from './styled'

export default function HomePage({ setCurrentPage }) {
  const { account, chainId } = useActiveWeb3React()
  const [transaction, setTransaction] = useState(<> </>)

  const listHero = [
    './images/char/generals-1.png',
    './images/char/generals-2.png',
    './images/char/generals-3.png',
    './images/char/generals-4.png',
    './images/char/generals-5.png',
  ]

  const gameUserData = useGameUserData()

  const onClickChangePage = (e) => {
    if (!account) {
      GlobalModal.show(<ConnectWallet onClose={GlobalModal.hide} />)
    } else {
      setCurrentPage(e)
    }
  }

  const handleShowPopupUpgrade = () => {
    if (!account) {
      GlobalModal.show(<ConnectWallet onClose={GlobalModal.hide} />)
    } else if (gameUserData.characters.length === 0)
      GlobalModal.show(
        <PopupNotice
          onClose={GlobalModal.hide}
          textNotice="You don't have any character"
          buttonTitle="Buy Now"
          onClick={() => {
            setCurrentPage('inventory')
            GlobalModal.hide()
          }}
        />,
      )
    else GlobalModal.show(<PopupUpgrade closePopup={GlobalModal.hide} />)
  }

  const handleToPvePage = () => {
    if (!account) {
      GlobalModal.show(<ConnectWallet onClose={GlobalModal.hide} />)
    } else if (gameUserData.characters.length === 0)
      GlobalModal.show(
        <PopupNotice
          onClose={GlobalModal.hide}
          textNotice="You don't have any character"
          buttonTitle="Buy Now"
          onClick={() => {
            setCurrentPage('inventory')
            GlobalModal.hide()
          }}
        />,
      )
    else setCurrentPage('island')
  }

  const handleToInventory = () => {
    if (!account) {
      GlobalModal.show(<ConnectWallet onClose={GlobalModal.hide} />)
    } else setCurrentPage('inventory')
  }

  const handleShowPopupConnect = () => {
    return GlobalModal.show(<ConnectWallet onClose={GlobalModal.hide} />)
  }

  const handleShowClaimReward = () => {
    if (!account) {
      GlobalModal.show(<ConnectWallet onClose={GlobalModal.hide} />)
    } else {
      GlobalModal.show(<ClaimReward />)
    }
  }

  const handleShowFaucetModal = () => {
    if (!account) {
      GlobalModal.show(<ConnectWallet onClose={GlobalModal.hide} />)
    } else {
      GlobalModal.show(<Faucet />)
    }
  }

  const handleShowMysteryEgg = () => {
    // if (!account) {
    //   GlobalModal.show(<ConnectWallet onClose={GlobalModal.hide} />)
    // } else {
    //   GlobalModal.show(<MysteryEgg />)
    // }
  }

  const handleShowLeaderBoard = () => {
    if (!account) {
      GlobalModal.show(<ConnectWallet onClose={GlobalModal.hide} />)
    } else {
      GlobalModal.show(<LeaderBoard onClose={GlobalModal.hide} />)
    }
  }

  const handleShowConvertGem = () => {
    if (!account) {
      GlobalModal.show(<ConnectWallet onClose={GlobalModal.hide} />)
    } else {
      GlobalModal.show(<ConvertGem />)
    }
  }

  const handleShowPopupTransaction = ({ success, tx, message }) => {
    setTransaction(<PopupTransaction success={success} tx={tx} message={message} chainId={chainId} />)
    setTimeout(() => {
      setTransaction(<></>)
    }, 5000)
  }

  const handleShowMigrate = () => {
    if (!account) {
      GlobalModal.show(<ConnectWallet onClose={GlobalModal.hide} />)
    } else {
      GlobalModal.show(<Migrate handleShowPopupTransaction={handleShowPopupTransaction} />)
    }
  }

  return (
    <>
      {transaction}
      <WidgetHome>
        <BoxVideo>
          <video autoPlay playsInline loop muted>
            <source src="/images/homepage/bgvideo.webm" type="video/webm" />
            <source src="/images/homepage/bgvideo.mov" type="video/quicktime" />
          </video>
        </BoxVideo>

        <MenuHead
          angelBalance={gameUserData.userTokenData.angelBalance}
          onConvert={handleShowConvertGem}
          onLeaderBoard={handleShowLeaderBoard}
        />

        <MainContent>
          {listHero.map((item) => {
            return (
              <Item>
                <img src={item} alt="" />
              </Item>
            )
          })}
        </MainContent>

        <ControlMenu>
          <ItemControlMenu>
            <Link to="/market">
              {/* <img className="temp" src="./images/homepage/market-lock.png" alt="" /> */}
              <img className="temp" src="./images/homepage/icon-marketplace.png" alt="" />
            </Link>
          </ItemControlMenu>

          <ItemControlMenu>
            <div className="itemControl__no-drop">
              <div className="content">
                <img src="./images/homepage/icon-pvp.png" alt="" />
              </div>
            </div>
          </ItemControlMenu>

          <ItemControlMenu onClick={handleToPvePage}>
            <img src="./images/homepage/icon-pve.png" alt="" />
          </ItemControlMenu>

          <ItemControlMenu onClick={handleToInventory}>
            <div className="content">
              <img src="./images/homepage/icon-inventory.png" alt="" />
            </div>
          </ItemControlMenu>

          <ItemControlMenu>
            <div className="content">
              <img src="./images/homepage/icon-upgrade.png" alt="" onClick={handleShowPopupUpgrade} />
            </div>
          </ItemControlMenu>
        </ControlMenu>

        <Connect
          Wallet={() => handleShowPopupConnect()}
          Claim={() => handleShowClaimReward()}
          onFaucet={handleShowFaucetModal}
          onMysteryEgg={() => onClickChangePage('chest')}
          onConvertGem={handleShowConvertGem}
          onMygrate={handleShowMigrate}
        />
      </WidgetHome>
    </>
  )
}
