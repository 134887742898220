import styled from 'styled-components'

const CharStartFightStyled = styled.div`
  position: absolute;
  animation-name: char-animate;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  img {
    height: 536px;
  }

  @keyframes char-animate {
    0% {
      left: 0;
      opacity: 1;
      transform: scale(1);
    }

    100% {
      left: 50%;
      opacity: 0;
      transform: scale(0.5);
    }
  }
  &.right {
    animation-name: char-animate-right;
  }

  @keyframes char-animate-right {
    0% {
      right: 0;
      opacity: 1;
      transform: scale(1);
    }

    100% {
      right: 50%;
      opacity: 0;
      transform: scale(0.5);
    }
  }
`

export default function CharStartFight({ src, rightSide }) {
  return (
    <CharStartFightStyled className={rightSide && 'right'}>
      <img src={src} alt="char-big-img" style={!rightSide ? { transform: 'scaleX(-1)' } : {}} />
    </CharStartFightStyled>
  )
}
