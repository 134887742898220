import React, { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import GlobalModal from 'components2/static/GlobalModal'
import { useBuyEgg } from 'hooks/useEggMarket'
import { useApproveCallbackCustom } from 'hooks/useApproveCallback'
import game from 'config/constants/game'
import { fetchGameUserData, fetchEgg } from 'state/eggMarket'
import { useAppDispatch } from 'state'
import { getEggMarketAddress } from 'utils/addressHelpers'
import Mask from 'components2/Common/EggAnimationMask'
// import {BigNumber} from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import PopupCongratulation from './PopupEgg'
import PopupNotice from '../Popup/PopupNotice'

const ItemStyledEgg = styled.div`
  background-image: url('/images/inventory/bg-item.png');
  background-repeat: no-repeat;
  background-size: contain;
  margin: 12px 12px;
  width: 204px;
  height: 284px;
  display: flex;
  position: relative;
  cursor: pointer;

  img.img-egg {
    transition: 0.5s ease-in-out;
    transform: scale(1);
  }

  &:hover {
    img.img-egg {
      transition: 0.5s ease-in-out;
      transform: scale(1.2);
    }
  }

  .price {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100px;
    background-image: url(/images/inventory/bg_button.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3px;
    cursor: pointer;
  }

  .bg-id {
    position: absolute;
    top: 15px;
    left: 54%;
    z-index: 4;
    transform: translateX(-50%);
    width: 145px;
    font-style: normal;
    font-weight: 900;
    font-size: 1.7em;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Hind', sans-serif;
    text-shadow: 0px 0px 1.99024px #ffffff;
  }

  .bg-item {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .item-img {
    margin: auto;
    margin-top: 47px;
    margin-left: 16px;
  }

  .property {
    position: absolute;
    top: 55px;
    right: 15px;
    z-index: 4;
  }
`
const BoxContentStyled = styled.div`
  position: absolute;
  top: 84px;
  left: 53%;
  height: 63%;
  transform: translateX(-50%);
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-egg {
    width: 70px;
    margin-bottom: 10px;
  }
  .angel-token {
    width: 33px;
  }
`

const BoxButtonStyled = styled.button`
  background: url('images/inventory/bg-btn.png') no-repeat;
  width: 144px;
  height: 44px;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  margin-top: auto;

  &:hover {
    opacity: 0.7;
    transition: 0.5s ease-in-out;
  }

  :disabled {
    opacity: 0.7;
    transition: 0.5s ease-in-out;
    color: #211915;
    cursor: not-allowed;
    filter: grayscale(1);
  }

  span {
    padding-left: 5px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
  }

  img {
    max-width: 30px;
  }
`

const balanceMessageError = 'not enough balance create hero'
const maxUserEggMessageError = 'you have bought the allowed egg'
const messageError = 'Out of egg with type'

function errorMessage(text = null) {
  switch (true) {
    case !text:
      return 'Error'
    case text.includes(balanceMessageError):
      return "You don't have enough balance."
    case text.includes(maxUserEggMessageError):
      return 'You have bought 10/10 eggs.'
    case text.includes(messageError):
      return 'Out of stock.'
    default:
      return 'Error'
  }
}

export default function CardEgg({
  id = null,
  egg = null,
  userData = null,
  onSelectEgg = null,
  onShowBrokenEgg = null,
}) {
  const [isApproving, setIsApproving] = useState<boolean>(false)
  const [isCreating, setIsCreating] = useState<boolean>(false)

  const { account, chainId } = useWeb3React()
  const dispatch = useAppDispatch()

  const { onBuyEgg } = useBuyEgg()
  const [onApproveAngel] = useApproveCallbackCustom(game.angelAddresses[chainId], getEggMarketAddress())

  const handleApprove = async () => {
    setIsApproving(true)
    try {
      const tx = await onApproveAngel()
      await tx?.wait()
      dispatch(fetchGameUserData(account) as any)
      setIsApproving(false)
    } catch (error) {
      setIsApproving(false)
    }
  }

  const handleBuyEgg = async () => {
    try {
      setIsCreating(true)
      const tx = await onBuyEgg(id)
      GlobalModal.show(<Mask eggType={id} />)

      // addTransaction(tx, {
      //   summary: 'Create successfully!',
      // })

      const results = await tx?.wait()

      dispatch(fetchGameUserData(account) as any)
      dispatch(fetchEgg())
      GlobalModal.hide()

      GlobalModal.show(<PopupCongratulation onClose={GlobalModal.hide} newHero={results.events[3].args} />)

      setIsCreating(false)
    } catch (error: any) {
      const errorText = error?.data?.message ?? error.toString()

      setIsCreating(false)

      GlobalModal.show(<PopupNotice onClose={GlobalModal.hide} textNotice={errorMessage(errorText)} />)
    }
  }

  return (
    <>
      <ItemStyledEgg className="item" onClick={onSelectEgg}>
        <div className="bg-id">{egg?.ratio?.type}</div>
        <img className="bg-item" src="images/inventory/bg-item.png" alt="" />
        <img className="item-img" src="images/inventory/bg-diff.png" alt="" />

        <BoxContentStyled>
          <img className="img-egg" src={`images/egg/egg-${id + 1}.png`} alt="" />

          {/* <BoxButtonStyled>
            <img src="images/inventory/icon-diamond.png" alt="" />
            <span>205</span>
          </BoxButtonStyled> */}

          {userData.allowance === '0' ? (
            <BoxButtonStyled onClick={() => handleApprove()} disabled={isApproving}>
              <img src="images/icon/angels-token.png" alt="" />
              <span>Enable</span>
            </BoxButtonStyled>
          ) : (
            <BoxButtonStyled onClick={() => handleBuyEgg()} disabled={isCreating}>
              <img src="images/icon/angels-token.png" alt="" />
              <span> {new BigNumber(egg?.angelToBuy).div(1e18).toFixed(0)}</span>
            </BoxButtonStyled>
          )}
        </BoxContentStyled>
      </ItemStyledEgg>
    </>
  )
}
