import styled from 'styled-components'
const CenterImg = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  &.vs-logo {
    left: 54%;
    animation-name: vs-animate;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }
  &.flare {
    animation-name: rotation;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  @keyframes vs-animate {
    0% {
      transform: translate(-50%, -50%) scale(5);
    }

    50% {
      transform: translate(-50%, -50%) scale(1);
    }
    75% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }

    99% {
      transform: translate(-50%, -50%) scale(5);
      opacity: 0;
      display: block;
    }
    100% {
      transform: translate(-50%, -50%) scale(5);
      opacity: 0;
      display: none;
    }
  }

  @keyframes rotation {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    50% {
      opacity: 1;
      transform: translate(-50%, -50%) rotate(180deg);
    }

    99% {
      opacity: 0;
      transform: translate(-50%, -50%) rotate(360deg);
    }

    100% {
      opacity: 0;
      display: none;
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`

export default function VSLogo() {
  return (
    <>
      <CenterImg src="/images/pve/flare.png" alt="" className="position-center flare" />
      <CenterImg src="/images/pve/vs2.png" alt="" className="position-center vs-logo" />
    </>
  )
}
