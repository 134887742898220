import styled from 'styled-components'

export const Styled = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/images/market/bg-market.png');
  padding: 60px;

  .space-top {
    padding: 49px 0;
  }

  .top-bar {
    display: flex;
  }

  .button {
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #43261c;
    margin: 16px 72px;
    display: flex;
    align-items: center;
    img {
      margin-right: 20px;
    }
  }

  .filter-group {
    height: 704px;
    width: 570px;
    background-image: url('/images/market/bg-filter.png');
    margin-top: 172px;
    margin-right: 40px;
    padding: 75px;
    color: #ffffff;

    .btn-dropdown {
      width: max-content;
      height: 40px;
      background: #84462e;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 5px;
      font-family: Montserrat;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      padding: 6px;
      margin-bottom: 16px;
    }

    .section {
      padding-bottom: 20px;
      margin: 20px 0px;

      :last-child {
        border: none;
      }

      .price {
        /* width: 80px; */
        display: flex;
        margin-right: 10px;
        align-items: center;
        .input-price {
          border: none;
          border-radius: 3px;
          width: 90px;
          height: 40px;
          padding: 5px 10px;
          margin-left: 5px;
          color: #000;
          :focus {
            outline: none;
          }
        }
      }

      .label {
        font-weight: bold;
        font-size: 1.5em;
        margin-bottom: 16px;
      }
      .ant-slider {
        margin-left: 20px;
        .ant-slider-handle {
          background-color: #f7cd32;
          :focus {
            outline: none;
          }
          z-index: 1;
        }
        .ant-slider-track {
          background: url('/images/market/slider-track.png');
          background-size: cover;
          background-repeat: no-repeat;
          left: 17px;
          right: auto;
          height: 10px;
          top: 8px;
          z-index: 1;
          transform: translateX(13px);
        }

        .ant-slider-rail {
          background: url(/images/market/slider-step.png);
          height: 22px;
          left: -30px;
          width: unset;
          right: -24px;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .ant-slider-handle {
          width: 36px;
          height: 40px;
          margin-top: -16px;
          border-radius: 50%;
          background: url('/images/market/dot.png');
          background-size: contain;
          background-repeat: no-repeat;
          border: none;
          top: 6px;
        }
      }
      .ant-checkbox-wrapper {
        color: #ffffff;
        font-size: 16px;
        .ant-checkbox-inner {
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
        }
        .ant-checkbox-inner::after {
          width: 9px;
          height: 14px;
        }
      }

      .ant-radio-group {
        .ant-radio-button-wrapper {
          width: 40px;
          height: 40px;
          border: 1px solid #ffffff;
          background: none;
          color: #ffffff;
          border-radius: 50px;
          text-align: center;
          margin-right: 20px;
          padding: 2px 10px;
          :not(:first-child)::before {
            content: unset;
          }
        }
      }
    }
  }

  .box-ratio {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: -25px;
      left: 0px;
      height: 2px;
      width: 100%;
      background-image: linear-gradient(90deg, transparent, rgb(250, 250, 250), transparent);
    }

    &-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      span {
        font-size: 1.5em;
        font-weight: 500;
      }
    }
  }

  .box-list {
    li {
      width: 100%;
      border-bottom: 1px solid #ffffff;
      padding: 20px 0;
      display: flex;
      flex-direction: row;

      > span,
      strong {
        font-size: 1.5em;
      }

      > span {
        font-size: 1.5em;
        font-weight: 400;
      }

      strong {
        padding-left: 20px;
        font-weight: 600;
      }
    }
  }

  .noitice {
    font-size: 1.5em;

    strong {
      font-weight: 600;
    }

    &-explain {
      text-align: center;
      font-size: 1.5em;
    }
  }
`

export const ConnectStyled = styled.div`
  position: absolute;
  top: 18px;
  right: 86px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;

  img {
    transition: 0.5s ease-in-out;
    transform: scale(1);

    &:hover {
      transform: scale(1.2);
      transition: 0.5s ease-in-out;
    }
  }
`
