import { createSelector } from '@reduxjs/toolkit'
import Character from 'views/AngelsPVE/components/Character'
import { State } from '../../types'

export const paginationSelector = (state: State) => state.marketplace?.pagintion
export const sortByPriceSelector = (state: State) => state.marketplace?.sorts?.price
export const filtersByOnSaleSelector = (state: State) => state.marketplace?.filters?.onSale
export const filterByRankSelector = (state: State) => state.marketplace?.filters?.rank
export const filterByClassSelector = (state: State) => state.marketplace?.filters?.classCharacter
export const filterByLevelSelector = (state: State) => state.marketplace?.filters?.level
export const filterByRangePriceSelector = (state: State) => state.marketplace?.filters?.rangePrice
export const filterByTokenSelector = (state: State) => state.marketplace?.filters?.token
export const listedCharactersSelector = (state: State) => Object.values(state.marketplace?.listings)
export const ownedInventoryCharactersSelector = (state: State) => Object.values(state.marketplace?.inventoryCharacters)
export const fetchingSelector = (state: State) => state.marketplace?.fetching
export const fetchUserAngelsToken = (state: State) => state.marketplace?.user?.angels
export const fetchBuyerFee = (state: State) => state.marketplace.fee?.buyerFee
export const fetchSellerFee = (state: State) => state.marketplace.fee?.sellerFee
export const fetchApproveCharacterStatusSelector = (state: State) => state.marketplace?.approveCharacterStatus

export const filteredListedCharactersSelector = createSelector(
  listedCharactersSelector,
  sortByPriceSelector,
  filterByRankSelector,
  filterByClassSelector,
  filterByLevelSelector,
  filterByRangePriceSelector,
  filterByTokenSelector,
  (
    listedCharacter: any,
    order: number,
    rank: number[],
    classCharacter: number[],
    level: number[],
    rangePrice: {
      from: number
      to: number
    },
    token: string[],
  ) => {
    if (rank && rank.length > 0) {
      listedCharacter = listedCharacter.filter(
        (character) => character.heroRarity && rank.includes(parseInt(character.heroRarity)),
      )
    }

    if (level && level.length > 0) {
      listedCharacter = listedCharacter.filter(
        (character) =>
          !character.level || (parseInt(character.level) >= level[0] && parseInt(character.level) <= level[1]),
      )
    }

    if (rangePrice.from > 0) {
      listedCharacter = listedCharacter.filter(
        (character) => character.price && parseFloat(character.price) >= rangePrice.from,
      )
    }

    if (rangePrice.to > 0) {
      listedCharacter = listedCharacter.filter(
        (character) => character.price && parseFloat(character.price) <= rangePrice.to,
      )
    }

    if (token.length > 0) {
      token = token.map((tokenItem) => tokenItem.toLowerCase())
      listedCharacter = listedCharacter.filter((character) => token.includes(character.paymentToken.toLowerCase()))
    }

    return listedCharacter.sort(
      (a, b) =>
        parseFloat(b.listingTime) - parseFloat(a.listingTime) || (parseFloat(a.price) - parseFloat(b.price)) * order,
    )
  },
)

export const filteredInventoryCharactersSelector = createSelector(
  ownedInventoryCharactersSelector,
  filtersByOnSaleSelector,
  (characters: any, isOnSale: boolean) => {
    if (!isOnSale) return characters

    return characters.filter((character) => !!character?.id === isOnSale)
  },
)
