import styled from 'styled-components'

export const MusicButtonStyled = styled.div`
  .music {
    display: flex;
    justify-content: center;
  }
  img {
    transition: 0.5s ease all;
    cursor: pointer;

    &:hover {
      filter: drop-shadow(-2px 0px 2px #ff9502) drop-shadow(-2px 2px 2px #ff9502) drop-shadow(2px 2px 2px #ff9502)
        drop-shadow(2px -2px 2px #ff9502);
    }
  }
`
