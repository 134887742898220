import Button from 'components2/Common/Button'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Radio } from 'antd'
import { useWeb3React } from '@web3-react/core'
import { paymentToken as PAYMENT_METHOD } from 'config/constants/marketplace'
import { addListing } from 'state/marketplace/actions/marketActions'
import { useActiveWeb3React } from 'hooks'
import { useCharacterContract, useFakeCharacterContract, useMarketplaceContract } from 'hooks/useContract'
import { getCharacterAddress, getFakeCharacterAddress, getNFTsTransferProxyAddress } from 'utils/addressHelpers'
import { BigNumber, ethers } from 'ethers'
import Popup from 'components2/static/GlobalModal'
import PopupMarket from './PopupMarket'

export default function PopupUpgrade({ closePopup, callback, others, data = null, handleCancel = null }) {
  const [disable, setDisable] = useState(false)
  const { account, chainId } = useActiveWeb3React()

  const [paymentToken, setPaymentToken] = useState('')

  const [price, setPrice] = useState(0)

  const marketplaceContract = useMarketplaceContract()

  const dispatch = useDispatch()

  const options = [
    { label: <img src={PAYMENT_METHOD[chainId][1].image} alt="" />, value: PAYMENT_METHOD[chainId][1].address },
    { label: <img src={PAYMENT_METHOD[chainId][0].image} alt="" />, value: PAYMENT_METHOD[chainId][0].address },
  ]

  const onClosePopupWithoutSell = () => {
    closePopup()
    if (others.length > 0) {
      others.map((functionCall) => functionCall())
    }
  }

  const onSell = async () => {
    if (paymentToken === '' || price === 0) {
      if (others.length > 0) {
        others.map((functionCall) => functionCall())
      }
      return alert('Plese enter price and choose payment token')
    }

    const { tokenId, nftAddress } = data

    dispatch(
      addListing({
        account,
        id: tokenId,
        price,
        paymentToken,
        listingNft: nftAddress,
        options: { contract: marketplaceContract, callback, others },
      }),
    )
  }

  const onCancel = async () => {
    closePopup()
    handleCancel()
  }

  const processingSell = () => {
    onSell()
    setDisable(true)
    Popup.hide()
  }

  return (
    <Styled>
      <PopupMarket closePopup={onClosePopupWithoutSell} data={data}>
        {data.id && parseInt(data.id) !== 0 ? (
          <>
            <div className="popupBuy__img">
              {(data.paymentToken === ethers.constants.AddressZero ? 0 : 1) ? (
                <>
                  <img src={PAYMENT_METHOD[chainId][1].image} alt="Icon1" /> <p>{data.price}</p>
                </>
              ) : (
                <>
                  <img src={PAYMENT_METHOD[chainId][0].image} alt="Icon1" /> <p>{data.price}</p>
                </>
              )}
            </div>
            <div className="button cancel">
              <Button onClick={onCancel} isLoading={disable}>
                CANCEL
              </Button>
            </div>
          </>
        ) : (
          <>
            <Radio.Group defaultValue={options} options={options} onChange={(e) => setPaymentToken(e.target.value)} />

            <input
              type="number"
              className="input-price"
              placeholder="Enter price"
              onChange={(e) => setPrice(parseFloat(e.target.value))}
            ></input>

            <div className="button">
              <Button onClick={() => processingSell()} isLoading={disable}>
                SELL
              </Button>
            </div>
          </>
        )}
      </PopupMarket>
    </Styled>
  )
}

const Styled = styled.div`
  .popupBuy__img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    padding-top: 30px;
    p {
      font-size: 20px;
    }
    img {
      width: 45px;
    }
  }

  .ant-checkbox-wrapper {
    color: #ffffff;
    font-size: 16px;
    margin-left: 45px;
    margin-top: 8px;

    .ant-checkbox {
      span {
        padding-bottom: 10px;
      }
    }

    .ant-checkbox-inner {
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      border-radius: 50px;
    }

    .ant-checkbox-inner::after {
      width: 9px;
      height: 14px;
    }
  }

  .input-price {
    margin: 14px 55px;
    border: none;
    background: #ffffff;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: 210px;
    height: 40px;
    padding: 5px 20px;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #b8b8b8;
  }

  .button.cancel {
    .button-upgrade {
      background: url('./images/market/price-cancel.png') no-repeat;
      height: 70px;
      background-size: 100% 100%;
      transform: translate(-50%, -30%);
    }
  }

  .button-upgrade {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, 0%);
    margin: 0;
  }

  .ant-radio-group {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 5px;
  }

  .ant-radio-wrapper {
    img {
      max-width: 38px;
      width: 45px;
    }
  }

  .ground {
    position: relative;
    background: #0d0d0d80;
    margin: 0px 2rem;
    border-radius: 12px;
    width: 320px;
    height: 500px;

    &.active {
      .ground__level--experience {
        h3 {
          text-transform: capitalize;
        }
      }

      .success__content {
        padding: 1rem;
        padding-top: 2rem;
        text-align: center;
      }
    }

    .top-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 1em 0 0.5rem;
      position: relative;
      color: #fff;

      &::before {
        position: absolute;
        content: '';
        background: radial-gradient(
          45.45% 45.45% at 50% 50%,
          #b0aaf5 0.01%,
          rgba(174, 182, 255, 0.3) 33.85%,
          rgba(189, 166, 255, 0) 100%
        );
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
      }
      .level,
      .name {
        font-family: Hind;
        font-weight: bold;
        font-size: 28px;
        line-height: 45px;
        text-align: center;
        color: #ffffff;
        margin-left: 35px;
      }
    }

    &::before {
      position: absolute;
      content: '';
      background: linear-gradient(
        0.01deg,
        rgba(189, 166, 255, 0) -8.32%,
        rgba(174, 182, 255, 0.3) 34.81%,
        #b0aaf5 108.32%
      );
      top: 0px;
      height: 10px;
      width: 100%;
      left: 0;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    .ground__level--experience {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 1rem 0px;
      position: relative;
      color: #fff;
      &::before {
        position: absolute;
        content: '';
        background: radial-gradient(
          45.45% 45.45% at 50% 50%,
          #b0aaf5 0.01%,
          rgba(174, 182, 255, 0.3) 33.85%,
          rgba(189, 166, 255, 0) 100%
        );
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
      }
      h3 {
        color: #fff;
        font-weight: 700;
        font-size: 1.7rem;
        margin: 0;
        text-transform: uppercase;
      }
      .span--update {
        color: #567bff;
        padding-left: 10px;
      }
    }

    .ground__level--experience.content {
      display: grid;
      grid-template-columns: auto auto;

      .info {
        display: flex;
        justify-content: space-between;
        width: 205px;
      }

      .experience--span {
        display: flex;
        justify-content: left;
        align-items: center;
      }
      span {
        font-size: 20px;
        font-weight: 400;
      }
    }

    .ground__price {
      display: flex;
      justify-content: center;
      align-items: center;
      .experience--icon {
        padding: 1rem 10px;
      }
    }

    .experience--icon {
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 1rem 0px;
    }

    .ground__success {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: ease all 0.5s;
      padding: 10px 0px 5px 0px;

      img {
        margin-right: 10px;
      }
      &:hover {
        opacity: 0.8;
      }
      p {
        padding-left: 0;
        padding-right: 10px;
        color: #fff;
      }
    }

    .ant-radio {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      &.ant-radio-checked {
        + span {
          filter: drop-shadow(2px 1px 6px #ffffff);
        }
      }
    }
  }
`
