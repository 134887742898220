import { ChainId } from '@diviner-protocol/v2-sdk'
import marketplaceAbi from 'config/abi/AngelCreedMarketPlace.json'
import gameAbi from 'config/abi/BNBHero.json'
import cakeAbi from 'config/abi/cake.json'
import characterAbi from 'config/abi/character.json'
import eggMarketAbi from 'config/abi/EggMarket.json'
import equipmentAbi from 'config/abi/equipment.json'
import bep20Abi from 'config/abi/erc20.json'
import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import faucetAbi from 'config/abi/faucet.json'
import gameWikiAbi from 'config/abi/gameWiki.json'
import ifoAbi from 'config/abi/ifo.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import multicall from 'config/abi/Multicall.json'
import poolAbi from 'config/abi/Pool.json'
import priceOracleAbi from 'config/abi/PriceOracle.json'
import addresses from 'config/constants/contracts'
import { ethers } from 'ethers'
// Addresses
import {
  getCakeAddress,
  getCharacterAddress,
  getEggMarketAddress,
  getEquipmentAddress,
  getFakeCharacterAddress,
  getFaucetAddress,
  getGameAddress,
  getGameWikiAddress,
  getMarketplaceAddress,
  getMulticallAddress,
  getOracleAddress,
} from 'utils/addressHelpers'
import { simpleRpcProvider } from 'utils/providers'
import { getPoolAddress } from './addressHelpers'
import { getChainId } from './web3React'

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider
  return new ethers.Contract(address, abi, signerOrProvider)
}

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer)
}
export const getLpContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lpTokenAbi, address, signer)
}
export const getIfoContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(ifoAbi, address, signer)
}

export const getCakeContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(cakeAbi, getCakeAddress(chainId), signer)
}

export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(multicall, getMulticallAddress(chainId), signer)
}

// game
export const getGameContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(gameAbi, getGameAddress(chainId), signer)
}
export const getCharacterContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  if (getCharacterAddress(chainId) === '') return null
  return getContract(characterAbi, getCharacterAddress(chainId), signer)
}
export const getEquipmentContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(equipmentAbi, getEquipmentAddress(chainId), signer)
}

export const getGameWikiContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(gameWikiAbi, getGameWikiAddress(chainId), signer)
}

export const getPriceOracleContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(priceOracleAbi, getOracleAddress(chainId), signer)
}

// faucet
export const getFaucetContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(faucetAbi, getFaucetAddress(chainId), signer)
}

// egg market

export const getEggMarketContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(eggMarketAbi, getEggMarketAddress(chainId), signer)
}
export const getFakeCharacterContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  const localChainId = getChainId()
  if (!addresses.fakeCharacter[localChainId]) {
    return null
  }
  return getContract(fakeCharacterAbi, getFakeCharacterAddress(chainId), signer)
}

export const getPoolContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  return getContract(poolAbi, getPoolAddress(chainId), signer)
}

// Marketplace
export const getMarketplaceContract = (signer?: ethers.Signer | ethers.providers.Provider, chainId?: ChainId) => {
  if (getMarketplaceAddress(chainId) === '') return null

  return getContract(marketplaceAbi, getMarketplaceAddress(chainId), signer)
}
