import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components2/Common/Button'
import CardValue from 'components/CardValue'
import GlobalModal from 'components2/static/GlobalModal'

import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
function Reward({ reward }) {
  const { angelReward, gemReward, keyReward } = reward
  return (
    <PopupPVEStyled>
      <div className="PopupPVE">
        <div className="PopupPVE__reward">
          <p>REWARD</p>
        </div>
        <div className="PopupPVE__price">
          {/* <div className="PopupPVE__price--angles">
            <img src="./images/popup/angels.png" alt="Angels.png" />
            <p>
              <CardValue value={parseFloat(formatEther(BigNumber.from(angelReward))) || 0} decimals={2}></CardValue>
            </p>
          </div> */}
          <div className="PopupPVE__price--angles">
            <img src="./images/popup/gem.png" alt="Gem.png" />
            <p>
              <CardValue value={parseFloat(formatEther(BigNumber.from(gemReward))) || 0} decimals={0}></CardValue>
            </p>
          </div>
          {/* <div className="PopupPVE__price--angles">
            <img src="./images/popup/key.png" alt="Key.png" />
            <p>
              <CardValue value={parseFloat(keyReward) || 0} decimals={2}></CardValue>
            </p>
          </div> */}
        </div>
        <div className="PopupPVE__button" onClick={GlobalModal.hide}>
          <Button>Close</Button>
        </div>
      </div>
    </PopupPVEStyled>
  )
}

export const PopupPVEStyled = styled.div`
  width: 450px;
  margin: 0 auto;
  position: absolute;
  bottom: 11%;
  left: 50%;
  transform: translate(-50%, 0%);

  p {
    margin: 0;
    color: #fff;
    font-size: 28px;
    font-weight: 500;
  }
  .PopupPVE {
    position: relative;
  }
  .PopupPVE__reward {
    font-weight: 700;
    text-align: center;
    position: relative;
    padding-bottom: 20px;
    &::before {
      position: absolute;
      content: '';
      bottom: 0;
      background: radial-gradient(
        45.45% 45.45% at 50% 50%,
        #b0aaf5 0.01%,
        rgba(174, 182, 255, 0.3) 33.85%,
        rgba(189, 166, 255, 0) 100%
      );
      width: 80%;
      height: 10px;
      left: 50%;
      transform: translate(-50%, 0%);
    }
  }
  .PopupPVE__price {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 20px;
    .PopupPVE__price--angles {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        padding-left: 10px;
      }
    }
  }
  .PopupPVE__button {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 40px;
  }
`
export default Reward
