import { useState } from 'react'
import styled from 'styled-components'

function Character({ lv, thumbImg, onClick, className }) {
  return (
    <CharacterStyled>
      <div className={className} onClick={onClick}>
        <img className="Character__frames" src="./images/pve/frame.png" alt="Frames.svg" />
        <div className="function__img">
          <img src={thumbImg} alt=" Character.png" />
        </div>
      </div>
    </CharacterStyled>
  )
}

export const CharacterStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  .character {
    position: relative;
    cursor: pointer;
    margin: 10px;
    &:hover {
      .Character__frames {
        box-shadow: white 0px 0px 10px 5px;
      }
    }
    .Character__frames {
      opacity: 1;
      height: 138px;
      transition: ease all 0.5s;
    }

    .function__img {
      position: absolute;
      top: 6px;
      height: 127px;
      left: 6px;
      width: 127px;
      overflow: hidden;

      img {
        opacity: 0.7;
        transition: ease all 0.5s;
        &:hover {
          opacity: 1;
        }
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .active {
      box-shadow: white 0px 0px 10px 5px;
    }


  .character.active {
    .function__img {
      background: linear-gradient(0deg, #240505 0%, rgba(36, 5, 5, 0) 49.97%);
      &::before {
        background: unset;
      img {
        opacity: 1;
      }
    }
    .Character__frames {
      box-shadow: white 0px 0px 10px 5px;
    }
  }
`

export default Character
