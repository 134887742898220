import { createAsyncThunk } from '@reduxjs/toolkit'
import multicall, { Call } from 'utils/multicall'
import { BigNumber } from 'ethers'
import erc20ABI from 'config/abi/erc20.json'
import gameConfig from 'config/constants/game'
import { getAddress, getTokenTransferProxyAddress } from 'utils/addressHelpers'

export const fetchUserToken = createAsyncThunk(
  'marketplace/fetchUserToken',
  async (account: string, { getState, dispatch }) => {
    try {
      const angelToken = getAddress(gameConfig.angelAddresses)
      const calls: Call[] = []

      calls.push({ address: angelToken, name: 'balanceOf', params: [account] })
      calls.push({ address: angelToken, name: 'allowance', params: [account, getTokenTransferProxyAddress()] })

      const [angelBalance, angelAllowance] = await multicall(erc20ABI, calls, {
        requireSuccess: false,
      })

      return {
        angels: {
          balance: angelBalance.toString(),
          allowance: angelAllowance.toString(),
          isAllowance: BigNumber.from(angelAllowance.toString()).gt(BigNumber.from(0)),
        },
      }
    } catch (e) {
      console.log(e)
    }
  },
)
