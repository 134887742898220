import { useWeb3React } from '@web3-react/core'
import { Input, Select } from 'antd'
import Button from 'components2/Common/Button'
import PopupConnect from 'components2/Common/Popup/PopupConnect'
import GlobalModal from 'components2/static/GlobalModal'
import game from 'config/constants/game'
import { ethers } from 'ethers'
import { useApproveCallbackCustom } from 'hooks/useApproveCallback'
import { useConvert } from 'hooks/useGame'
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from 'state'
import { fetchUserGameInfo, fetchUserTokenData } from 'state/game'
import styled from 'styled-components'
import { amountAfterTax, amountBeforeTax, toPrecision } from 'utils'
import { getGameAddress } from 'utils/addressHelpers'
import { getTimeRemainDraw } from 'utils/time'
import { usePool } from '../../../hooks/usePool'

const { Option } = Select

function ConvertGem({ input = 'angel', output = 'gem' }) {
  const [loading, setLoading] = useState<boolean>(false)
  const { rateGemToAngel = 10, angelBalance, gemBalance, chargeFeePercent, lastTimeClaim, angelAllowance } = usePool()
  const [countdown, setCountdown] = useState<string>(null)
  const [tokenNameInput, setTokenNameInput] = useState<string>(input)
  const [tokenNameOutput, setTokenNameOutput] = useState<string>(output)
  const [amountOut, setAmountOut] = useState<number>(0)
  const [amountIn, setAmountIn] = useState<number>(0)
  const { onConvertAngelToGem, onConvertGemToAngel } = useConvert()
  const { account, chainId } = useWeb3React()
  const [isApproving, setIsApproving] = useState<boolean>(false)
  const [onApproveAngel] = useApproveCallbackCustom(game.angelAddresses[chainId], getGameAddress())

  const dispatch = useAppDispatch()

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(getTimeRemainDraw(lastTimeClaim + 604800000)) // 1 tuan
    }, 1000)

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [])

  const handleInputTokenNameChange = (tokenName) => {
    if (tokenName !== tokenNameInput) {
      swapTokenNameIO()
      if (tokenName === 'gem') {
        setAmountOut(amountAfterTax(amountIn / rateGemToAngel, chargeFeePercent))
      } else {
        setAmountOut(amountIn * rateGemToAngel)
      }
    }
  }

  const handleOutputTokenNameChange = (tokenName) => {
    if (tokenName !== tokenNameOutput) {
      swapTokenNameIO()
      if (tokenName === 'angel') {
        setAmountOut(amountAfterTax(amountIn / rateGemToAngel, chargeFeePercent))
      } else {
        setAmountOut(amountIn * rateGemToAngel)
      }
    }
  }

  const swapTokenNameIO = () => {
    const oldInput = tokenNameInput
    setTokenNameInput(tokenNameOutput)
    setTokenNameOutput(oldInput)
  }

  const handleSwap = () => {
    const oldInput = tokenNameInput

    swapTokenNameIO()
    if (amountIn === 0) {
      setAmountOut(0)
    } else if (oldInput === 'angel') {
      setAmountOut(amountAfterTax((amountIn * amountIn) / amountOut, chargeFeePercent))
    } else {
      setAmountOut((amountIn * amountIn) / amountBeforeTax(amountOut, chargeFeePercent))
    }
  }

  const handleAmountIn = (e) => {
    let { value } = e.target

    value = parseFloat(value)
    setAmountIn(value)
    if (!value) {
      setAmountOut(0)
    } else if (tokenNameInput === 'gem') {
      setAmountOut(amountAfterTax(value / rateGemToAngel, chargeFeePercent))
    } else {
      setAmountOut(value * rateGemToAngel)
    }
  }

  const handleConvert = async () => {
    try {
      setLoading(true)
      let tx
      if (tokenNameInput === 'angel') {
        tx = await onConvertAngelToGem(ethers.utils.parseEther(amountIn.toString()))
      } else {
        tx = await onConvertGemToAngel(ethers.utils.parseEther(amountIn.toString()))
      }
      await tx?.wait()
      dispatch(fetchUserTokenData(account) as any)
      dispatch(fetchUserGameInfo(account) as any)
    } catch (error) {
      console.log('error convert : ', error)
    } finally {
      setLoading(false)
    }
  }

  const handleApprove = async () => {
    setIsApproving(true)
    try {
      const tx = await onApproveAngel()

      await tx?.wait()
      dispatch(fetchUserTokenData(account) as any)
      setIsApproving(false)
    } catch (error) {
      setIsApproving(false)
    }
  }

  return (
    <PopupConnect onClick={GlobalModal.hide} title="transfer">
      <ConvertGemStyled>
        <BoxFormStyled>
          <InputStyled>
            From:
            <Input.Group compact>
              <Select value={tokenNameInput} onChange={handleInputTokenNameChange}>
                <Option className="token-option" value="gem">
                  <img src="./images/icon/gem-token.png" alt="" />
                  GEM
                </Option>
                <Option className="token-option" value="angel">
                  <img src="./images/icon/angels-token.png" alt="" />
                  Angel
                </Option>
              </Select>
            </Input.Group>
          </InputStyled>

          <Transfer onClick={handleSwap}>
            <img src="./images/icon/icon-transfer.png" alt="" />
          </Transfer>

          <InputStyled>
            To:
            <Input.Group compact>
              <Select value={tokenNameOutput} onChange={handleOutputTokenNameChange}>
                <Option className="token-option" value="angel">
                  <img src="./images/icon/angels-token.png" alt="" />
                  Angel
                </Option>
                <Option className="token-option" value="gem">
                  <img src="./images/icon/gem-token.png" alt="" />
                  GEM
                </Option>
              </Select>
            </Input.Group>
          </InputStyled>
        </BoxFormStyled>

        <BoxContentCenter>
          <Input.Group size="large">
            <Input type="number" min="0" onChange={handleAmountIn} value={amountIn} />
          </Input.Group>

          <p>
            <img src="./images/icon/icon-approximately.png" alt="" /> {toPrecision(amountOut)}{' '}
            {tokenNameInput === 'gem' ? 'Angel' : 'Gem'}
          </p>

          {tokenNameInput === 'angel' && angelAllowance === '0' ? (
            <Button onClick={handleApprove} isLoading={isApproving}>
              Enable
            </Button>
          ) : (
            <Button onClick={handleConvert} isLoading={loading}>
              Convert
            </Button>
          )}

          {tokenNameInput === 'gem' && (
            <p>
              Convert tax: <span>{chargeFeePercent}%</span>{' '}
            </p>
          )}
        </BoxContentCenter>

        {tokenNameInput === 'gem' && (
          <BoxDayStyled>
            <h3>Free tax after 7 days since your first time rewarded</h3>

            <div className="count-down">
              <p>Count down to free tax:</p>
              <h3>
                <span>{countdown}</span>
              </h3>
            </div>
          </BoxDayStyled>
        )}
      </ConvertGemStyled>
    </PopupConnect>
  )
}

const ConvertGemStyled = styled.div`
  text-align: center;
  padding-bottom: 3rem;
  color: #ffffff;

  h3 {
    color: #ffffff;
    font-size: 2em;
  }

  .button-upgrade {
    margin: 0 auto;
  }
`

const BoxFormStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  width: 100%;
  font-size: 1.4em;
  font-weight: 600;
  text-align: left;
`

const InputStyled = styled.div`
  .ant-select-selector {
    width: 200px !important;
    height: 44px !important;
    box-shadow: inset 0px 4px 6px rgb(0 0 0 / 25%);
    border-radius: 4px;

    .ant-select-selection-item {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      font-size: 1.2em;
      font-weight: 600;

      img {
        width: 30px;
        margin-right: 7px;
      }
    }
  }
`

const Transfer = styled.div`
  margin-top: 30px;
  cursor: pointer;
  img {
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
  }

  &:hover {
    img {
      transform: rotate(180deg);
      transition: 0.5s ease-in-out;
    }
  }
`

const BoxContentCenter = styled.div`
  display: flex;
  flex-direction: column;

  .ant-input-group {
    &.ant-input-group-lg {
      margin: 0 auto;
      width: 200px;
      display: block;

      input {
        box-shadow: inset 0px 4px 6px rgb(0 0 0 / 25%);
        font-size: 1.2em;
        font-weight: 600;
      }
    }
  }

  p {
    font-size: 1.2em;
    font-weight: 500;
  }
`

const BoxDayStyled = styled.div`
  background: #00000047;
  border-top: 2px solid #ffffff85;
  box-sizing: border-box;
  box-shadow: inset 0px 4px 6px rgb(0 0 0 / 25%);
  border-radius: 16px;
  max-width: 676px;
  margin: 0 auto;
  padding: 20px 0;

  > h3 {
    position: relative;
    padding-bottom: 10px;
    font-size: 22px;

    &:before {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      height: 2px;
      width: 100%;
      background-image: linear-gradient(90deg, transparent, #fafafa, transparent);
    }
  }
`

export default ConvertGem
