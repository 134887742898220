import { createSlice } from '@reduxjs/toolkit'
import { ChestState } from '../types'
import { fetchChestData } from './fetchChestData'

const initialState: ChestState = {
  activeCharacterId: 0,
  chests: [
    {
      type: 1,
      gemToOpen: '0',
      ratio: { type: '', n: '0', r: '0', sr: '0', ssr: '0' },
    },
    {
      type: 2,
      gemToOpen: '0',
      ratio: { type: '', n: '0', r: '0', sr: '0', ssr: '0' },
    },
    {
      type: 3,
      gemToOpen: '0',
      ratio: { type: '', n: '0', r: '0', sr: '0', ssr: '0' },
    },
  ],
  isLoading: true,
}

export const gameSlice = createSlice({
  name: 'Game',
  initialState,
  reducers: {
    setActiveRoundId: (state, action) => {
      state.activeCharacterId = action.payload
    },

    setChest: (state, action) => {
      const { chestData } = action.payload

      state.chests = chestData.map((chest, index) => {
        return { ...chest }
      })
    },
  },
})

export const fetchChest = () => async (dispatch) => {
  const chestData = await fetchChestData()

  dispatch(setChest({ chestData }))
}

// actions
export const { setActiveRoundId, setChest } = gameSlice.actions

export default gameSlice.reducer
