import React, { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import { useBuyEgg } from 'hooks/useEggMarket'
import { useApproveCallbackCustom } from 'hooks/useApproveCallback'
import game from 'config/constants/game'
import { fetchGameUserData } from 'state/eggMarket'
import { useAppDispatch } from 'state'
import { getEggMarketAddress } from 'utils/addressHelpers'

interface Props {
  id: boolean
}

const ItemStyled = styled.div`
  background-image: url('/images/market/bg-item.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 215px;
  height: 284px;
  margin: 12px 12px;
  display: flex;
  position: relative;
  transition: 0.5s ease-in-out;
  transform: scale(1);
  cursor: pointer;

  &:hover {
    transition: 0.5s ease-in-out;
    transform: scale(1.1);
  }

  .price {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 144px;
    height: 44px;

    background-repeat: no-repeat;
    background-size: cover;
    padding: 3px;
    cursor: pointer;
    padding: 7px 10px 10px 52px;
    color: #fff;
  }

  .item-img {
    margin: auto;
    margin-top: 40px;
  }

  .property {
    position: absolute;
    top: 52px;
    right: 15px;
    max-width: 50px;
  }

  .bg-id {
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 145px;
    height: 31px;
    font-family: 'Hind', sans-serif;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 19.9024px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 0px 1.99024px #ffffff;
  }
  .token-id {
    position: absolute;
    top: 55px;
    left: 28px;
    z-index: 4;
    color: white;

    font-weight: 600;
    font-size: 17px;
    text-transform: uppercase;
  }
  .token-color {
    color: #a01010;
  }
`

function color(name) {
  const lowerName = name.toLowerCase()
  switch (lowerName) {
    case 'dizo':
      return true
    case 'evilay':
      return true
    case 'robins':
      return true
    default:
      return false
  }
}

export default function CardCharacter({ character = null }) {
  return (
    <>
      <ItemStyled className="item">
        <div className="bg-id">{character.name}</div>
        <img className="item-img" src={character.inventoryImg} alt="" />
        <img className="property" src={character.rarityImg} alt="" />
        <span className={`token-id ${color(character.name) ? null : 'token-color'}`}>#{character.tokenId}</span>
      </ItemStyled>
    </>
  )
}
