import styled from 'styled-components'

export const WidgetMenu = styled.div`
  width: 100%;
  position: absolute;
  top: 50px;
`

export const BoxAvatar = styled.div`
  position: relative;

  .img-avatar {
    position: relative;

    img.box-avatar {
      position: relative;
      top: 0;
      left: 0;
      z-index: 2;
      max-width: 443px;
    }

    .avatar {
      &:before {
        content: '';
        background: #ffffff;
        width: 120px;
        height: 120px;
        position: absolute;
        top: 27px;
        left: 20px;
      }

      img {
        position: absolute;
        top: 35px;
        left: 26px;
        z-index: 1;
        max-width: 110px;
      }
    }
  }

  span {
    position: absolute;
    top: 20%;
    left: 23%;
    color: #ffc165;
    font-size: 24px;
    font-weight: 600;
    z-index: 3;
  }

  p {
    position: absolute;
    top: 61%;
    left: 23%;
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    z-index: 3;
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
      background: transparent;
      border: none;
      padding-left: 17px;
      cursor: pointer;
      transition: 0.5s ease-in-out;

      &:hover {
        opacity: 0.7;
        transition: 0.5s ease-in-out;
      }
    }
  }
`

export const BoxParameter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-right: 9rem; */
`

export const Item = styled.div`
  position: relative;

  &:before {
    content: '';
    background: #00000050;
    width: 89%;
    height: 42px;
    position: absolute;
    top: 8px;
    left: 14px;
    border-top-right-radius: 50px 50px;
    border-bottom-right-radius: 50px 50px;
  }

  .content {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 50px 1fr 60px;
    align-items: center;

    span {
      color: #ffffff;
      font-size: 26px;
      font-weight: 500;
      /* -webkit-text-stroke: 1.5px #662e2a; */
    }

    > img {
      width: 53px;
      height: 55px;
      margin-left: -10px;

      &.gem-token {
        width: 51px;
        height: 57px;
      }
    }

    a {
      width: auto;
      height: 50px;
      background: transparent;
      border: none;
      position: relative;
      cursor: pointer;
      transition: 0.5s ease-in-out;

      &:hover {
        opacity: 0.7;
        transition: 0.5s ease-in-out;
      }

      img {
        position: absolute;
        top: 5px;
        right: 7px;
        z-index: 2;
        width: 40px;
      }
    }
  }
  .token {
    display: flex;
    justify-content: center;
  }
`

export const ControlMenu = styled.div``

export const ItemControlMenu = styled.div``

export const ITem = styled.div``
