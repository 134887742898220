import styled from 'styled-components'
export { default as CardItem } from './CardItem'

interface Props {
  activeTabId: string
}

const ListItemWithTabStyled = styled.div<Props>`
  margin-top: 100px;
  width: 1188px;

  .tab-pen {
    display: flex;
    align-items: flex-end;
    padding-left: 68px;

    .tab {
      background-image: url('/images/inventory/bg-tab-pen.png');
      background-repeat: no-repeat;
      height: 64px;
      width: 212px;
      margin-right: 10px;
      font-weight: 500;
      font-size: 2em;
      line-height: 35px;
      text-align: center;
      cursor: pointer;
      color: #949494;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 22px;
      padding-left: 10px;
      transition: 0.5s ease-in-out;
      font-family: 'Hind', sans-serif;

      &.active {
        background-image: url('/images/inventory/bg-tab-pen-avtive.png');
        transition: 0.5s ease-in-out;
        color: #ffffff;
      }
    }
  }

  .wrapper-list-inventory {
    background-image: url('/images/inventory/bg-list-item.png');
    background-repeat: no-repeat;
    padding: 46px 40px;
    width: 1017px;

    .no-item {
      width: 100%;
      height: 100%;
    }

    .list-item {
      height: ${(props) => (props.activeTabId === 'MY_INVENTORY' ? '525px' : '689px')};
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 20px;
      position: relative;

      ::-webkit-scrollbar {
        width: 14px;
        background-color: #f5f5f5;
        border-radius: 5px;
      }
      ::-webkit-scrollbar-thumb {
        background: #f08005;
        border-radius: 5px;
      }
      ::-webkit-scrollbar-track {
        background-color: #935743;
      }

      img.no-item {
        height: 182px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .box-pagination {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);

      .ant-pagination-options {
        display: none;
      }

      .ant-pagination-item {
        border-radius: 50%;
        background-color: transparent;
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;

        &.ant-pagination-item-active {
          background-color: #ffffff;
          border-color: 0;

          a {
            color: #000000;
          }
        }

        a {
          color: #ffffff;
        }
      }

      .ant-pagination-item-container {
        border: 1px solid rgb(255 255 255);
        border-radius: 50%;
      }

      .ant-pagination-jump-prev {
        .ant-pagination-item-container {
          .ant-pagination-item-ellipsis,
          .ant-pagination-item-link-icon {
            color: rgb(255 255 255);
          }
        }
      }

      .ant-pagination-jump-next {
        .ant-pagination-item-container {
          .ant-pagination-item-link-icon {
            color: rgb(255 255 255);
          }

          .ant-pagination-item-ellipsis {
            color: rgb(255 255 255);
          }
        }
      }

      li.ant-pagination-prev {
        &:before {
          content: '';
          transform: rotate(180deg);
          top: -3px;
          left: -12px;
        }
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        position: relative;

        &:hover {
          opacity: 0.7;
          transition: 0.5s ease-in-out;
        }

        &:before {
          content: '';
          background: url('images/inventory/icon-arrow.png');
          background-size: cover;
          width: 39px;
          height: 39px;
          position: absolute;
          top: 50%;
          left: 5px;
          transform: translateY(-50%);
        }

        button {
          display: none;
        }
      }
    }
  }
`

const ListItemWithTabsInventory = ({ tabs = [], activeTabId, onClickTab, children, filter }) => {
  const Filter = filter
  return (
    <ListItemWithTabStyled activeTabId={activeTabId}>
      <div className="tab-pen">
        {tabs.map((tab) => (
          <div className={activeTabId === tab.id ? 'tab active' : 'tab'} onClick={() => onClickTab(tab)}>
            {tab.name}
          </div>
        ))}
      </div>

      <div className="wrapper-list-inventory">
        {activeTabId === 'MY_INVENTORY' && <Filter />}
        <div className="list-item">{children}</div>
      </div>
    </ListItemWithTabStyled>
  )
}

export default ListItemWithTabsInventory
