import { useState } from 'react'
// import Menu from "./Menubar";
import { useCurrencyBalances } from 'state/wallet/hooks'
import gameConfig from 'config/constants/game'
import { useActiveWeb3React } from 'hooks'
import { useToken } from 'hooks/Tokens'
import { useGameUserData } from 'state/hooks'
import CardValue from 'components/CardValue'
import styled from 'styled-components'

import { WidgetMenu, BoxAvatar, BoxParameter, Item } from './styled'

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
  display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'block' : 'none')};
  position: absolute;
  bottom: -50px;
  right: 0;
  left: 0;
  text-align: center;
  background-color: #191326;
  color: #ffffff;
  border-radius: 16px;
  opacity: 0.7;
`

export default function HomePage() {
  const { account, chainId } = useActiveWeb3React()

  const [isTooltipDisplayed, setIsTooltipDisplayed] = useState<boolean>(false)
  const trimAccount = account ? `${account.slice(0, 4)}...${account.slice(38)}` : '0x00...0000' // account length: 42

  const ANGEL_TOKEN = useToken(gameConfig.angelAddresses[chainId])
  const GEM_TOKEN = useToken(gameConfig.creedAddresses[chainId])
  const balances = useCurrencyBalances(account, [ANGEL_TOKEN, GEM_TOKEN])
  const balanceANGEL = balances[0]?.toFixed(4) || '0'
  const balanceGEM = balances[1]?.toFixed(4) || '0'
  const { userGameData } = useGameUserData()

  return (
    <>
      <WidgetMenu>
        <BoxAvatar>
          <div className="img-avatar">
            <img className="box-avatar" src="./images/homepage/box-avatar.png" alt="" />

            <div className="avatar">
              <img src="./images/homepage/avatar.png" alt="" />
            </div>
          </div>

          <span>Angels creed</span>

          <p>
            {trimAccount}
            <button
              onClick={() => {
                if (navigator.clipboard) {
                  navigator.clipboard.writeText(account)
                  setIsTooltipDisplayed(true)
                  setTimeout(() => {
                    setIsTooltipDisplayed(false)
                  }, 1000)
                }
              }}
              type="button"
              aria-label="Copy"
            >
              <img src="./images/homepage/icon-copy.png" alt="" />
            </button>
            <Tooltip isTooltipDisplayed={isTooltipDisplayed}>Copied</Tooltip>
          </p>
        </BoxAvatar>

        <BoxParameter>
          <Item>
            <div className="content">
              <img src="./images/icon/angels-token.png" alt="" />
              <span>
                <CardValue value={parseFloat(balanceANGEL) || 0} decimals={2}></CardValue>
              </span>
              <a
                href="https://pancakeswap.finance/swap?outputCurrency=0x205f93cd558aac99c4609d0511829194b5405533"
                target="_blank"
                rel="noreferrer"
              >
                <img src="./images/homepage/icon-plus.png" alt="" />
              </a>
            </div>
          </Item>

          {/* <Item>
            <div className="content">
              <img className="gem-token" src="./images/icon/gem-token.png" alt="" />
              <span>
                <CardValue value={parseFloat(balanceGEM) || 0} decimals={2}></CardValue>
              </span>
              <a href="/">
                <img src="./images/homepage/icon-plus.png" alt="" />
              </a>
            </div>
          </Item> */}

          {/* <Item>
            <div className="content">
              <img src="./images/icon/key-reward.png" alt="" />
              <span>
                <CardValue value={parseFloat(userGameData?.keyReward) || 0} decimals={2}></CardValue>
              </span>
              <a href="/">
                <img src="./images/homepage/icon-plus.png" alt="" />
              </a>
            </div>
          </Item> */}
        </BoxParameter>
      </WidgetMenu>
    </>
  )
}
