import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import PopupConnect from 'components2/Common/Popup/PopupConnect'
import { getTimeRemainDraw } from 'utils/time'
import { usePool } from 'hooks/usePool'

function PopupNotice({ onClose, textNotice, onClick = null, buttonTitle = null, lastFightTime = null }) {
  const [countdown, setCountdown] = useState<string>(null)

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(getTimeRemainDraw(lastFightTime + 86400000)) // 1 ngay
    }, 1000)

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [])
  return (
    <PopupConnect onClick={onClose} title="Warning">
      <PopupConnectStyled>
        <TextNotice>{textNotice}</TextNotice>
        {textNotice === "You don't have enough balance." && (
          <a
            className="link"
            href="https://pancakeswap.finance/swap?outputCurrency=0x205f93cd558aac99c4609d0511829194b5405533"
            target="_blank"
            rel="noreferrer"
          >
            <ButtonStyled>Buy</ButtonStyled>
          </a>
        )}
        <TextNotice>{lastFightTime && countdown}</TextNotice>

        {onClick && <ButtonStyled onClick={onClick}>{buttonTitle}</ButtonStyled>}
      </PopupConnectStyled>
    </PopupConnect>
  )
}

const ButtonStyled = styled.button`
  margin: 0 auto;
  background-image: url('../images/popup/button-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: max-content;
  cursor: pointer;
  -webkit-transition: ease all 0.5s;
  transition: ease all 0.5s;
  text-transform: capitalize;
  width: 240px;
  height: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  transform: scale(1);
  transition: 0.5s ease-in-out;

  a {
    color: #ffffff;
  }

  &:hover {
    transform: scale(1.1);
    transition: 0.5s ease-in-out;
  }
`

const PopupConnectStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  padding: 70px 0;
`

const TextNotice = styled.div`
  font-size: 35px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: center;

  span {
    font-weight: 500;
  }
`

const CenterStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .bg-bot {
    margin-top: -47px;
  }
`

const CharacterStyled = styled.div`
  position: relative;
  z-index: 2;

  img {
    width: 300px;
  }
`
const TitleNameStyled = styled.div`
  font-size: 2.6em;
  font-weight: 600;
  color: #ffffff;
  margin-top: 15px;
`

const RightStyled = styled.div`
  text-align: center;

  img {
    width: 100px;
  }
`
export default PopupNotice
