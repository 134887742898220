import React from 'react'

export default function CharacterReview({ characters, selectedCharacter, handleSelectHero, onClose }) {
  return (
    <>
      <img className="close-icon" src="/images/pve/close-icon.png" alt="close-icon" onClick={onClose} />

      <div className="character">
        <div className="character__name">
          <p>{characters[selectedCharacter]?.name}</p>
        </div>

        <div className="character__img">
          <img
            onClick={() => handleSelectHero(-1)}
            className="previous"
            src="/images/pve/previous-icon.png"
            alt="Previous-icon.png"
          />
          {characters[selectedCharacter] && (
            <img
              className="upgrade-character"
              src={characters[selectedCharacter]?.reviewImg}
              alt="upgrade-character.png"
            />
          )}

          <img
            onClick={() => handleSelectHero(1)}
            className="next"
            src="/images/pve/previous-icon.png"
            alt="Previous-icon.png"
          />
          <img className="character__img--item" src="/images/popup/standing-pedestal.png" alt="Previous-icon.png" />
        </div>
      </div>
    </>
  )
}
