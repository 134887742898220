import React, { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import GlobalModal from 'components2/static/GlobalModal'
import { useBuyEgg } from 'hooks/useEggMarket'
import { useApproveCallbackCustom } from 'hooks/useApproveCallback'
import game from 'config/constants/game'
import { fetchGameUserData, fetchEgg } from 'state/eggMarket'
import { useAppDispatch } from 'state'
import { getEggMarketAddress } from 'utils/addressHelpers'
import Mask from 'components2/Common/EggAnimationMask'
import CardValue from 'components/CardValue'
import ConvertGem from 'components2//Common/Popup/ConvertGem'

import { fetchUserCharacters, fetchUserGameInfo } from 'state/game'
// import {BigNumber} from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import PopupCongratulation from './PopupEgg'
import PopupNotice from '../Popup/PopupNotice'
import { fetchChest } from '../../../state/chest/index'
import { useOpenChest } from '../../../hooks/useEggMarket'

const ItemStyledEgg = styled.div`
  background-image: url('/images/inventory/bg-item.png');
  background-repeat: no-repeat;
  background-size: contain;
  margin: 12px 12px;
  width: 204px;
  height: 284px;
  display: flex;
  position: relative;
  cursor: pointer;

  img.img-egg {
    transition: 0.5s ease-in-out;
    transform: scale(1);
  }

  &:hover {
    img.img-egg {
      transition: 0.5s ease-in-out;
      transform: scale(1.2);
    }
  }

  .price {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100px;
    background-image: url(/images/inventory/bg_button.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3px;
    cursor: pointer;
  }

  .bg-id {
    position: absolute;
    top: 15px;
    left: 54%;
    z-index: 4;
    transform: translateX(-50%);
    width: 145px;
    font-style: normal;
    font-weight: 900;
    font-size: 1.7em;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Hind', sans-serif;
    text-shadow: 0px 0px 1.99024px #ffffff;
  }

  .bg-item {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .item-img {
    margin: auto;
    margin-top: 47px;
    margin-left: 16px;
  }

  .property {
    position: absolute;
    top: 55px;
    right: 15px;
    z-index: 4;
  }
`
const BoxContentStyled = styled.div`
  position: absolute;
  top: 84px;
  left: 53%;
  height: 63%;
  transform: translateX(-50%);
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-egg {
    width: 70px;
    margin-bottom: 10px;
  }
  .angel-token {
    width: 33px;
  }
`

const BoxButtonStyled = styled.button`
  background: url('images/inventory/bg-btn.png') no-repeat;
  width: 144px;
  height: 44px;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
  margin-top: auto;

  &:hover {
    opacity: 0.7;
    transition: 0.5s ease-in-out;
  }

  :disabled {
    opacity: 0.7;
    transition: 0.5s ease-in-out;
    color: #211915;
    cursor: not-allowed;
    filter: grayscale(1);
  }

  span {
    padding-left: 5px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
  }

  img {
    max-width: 30px;
  }
`

const gemMessageError = 'not enough gem'

export default function CardChest({
  type = null,
  chest = null,
  userData = null,
  onSelectEgg = null,
  onShowBrokenEgg = null,
}) {
  const [isCreating, setIsCreating] = useState<boolean>(false)

  const { account, chainId } = useWeb3React()
  const dispatch = useAppDispatch()

  const { onOpenChest } = useOpenChest()

  const handleOpenChest = async () => {
    try {
      setIsCreating(true)
      const tx = await onOpenChest(type)
      GlobalModal.show(<Mask eggType={type} />)

      // addTransaction(tx, {
      //   summary: 'Create successfully!',
      // })

      const results = await tx?.wait()

      // dispatch(fetchGameUserData(account) as any)
      // dispatch(fetchChest())
      GlobalModal.hide()
      const newHero = {
        player: results.events[1].args.player,
        _heroId: results.events[1].args.id,
        heroRarity: results.events[1].args.rarity,
        heroName: results.events[1].args.name,
      }

      GlobalModal.show(<PopupCongratulation onClose={GlobalModal.hide} newHero={newHero} />)
      dispatch(fetchUserGameInfo(account))
      dispatch(fetchUserCharacters(account))

      setIsCreating(false)
    } catch (error: TypeError | any) {
      setIsCreating(false)
      const errorText = error?.data?.message ?? error.toString()

      if (errorText.includes(gemMessageError)) {
        GlobalModal.show(
          <PopupNotice
            onClose={GlobalModal.hide}
            textNotice="Not enough Gem."
            onClick={() => GlobalModal.show(<ConvertGem />)}
            buttonTitle="Convert"
          />,
        )
        return
      }

      GlobalModal.show(<PopupNotice onClose={GlobalModal.hide} textNotice="Error" />)
    }
  }

  return (
    <>
      <ItemStyledEgg className="item" onClick={onSelectEgg}>
        <div className="bg-id">{chest?.ratio?.type}</div>
        <img className="bg-item" src="images/inventory/bg-item.png" alt="" />
        <img className="item-img" src="images/inventory/bg-diff.png" alt="" />

        <BoxContentStyled>
          <img className="img-egg" src={`images/egg/egg-${type + 1}.png`} alt="" />

          {/* <BoxButtonStyled>
            <img src="images/inventory/icon-diamond.png" alt="" />
            <span>205</span>
          </BoxButtonStyled> */}

          <BoxButtonStyled onClick={() => handleOpenChest()} disabled={isCreating}>
            <img src="images/icon/gem-token.png" alt="" />
            <span>
              <CardValue
                value={parseFloat(new BigNumber(chest?.gemToOpen).div(1e18).toFixed(0)) || 0}
                decimals={0}
              ></CardValue>
            </span>
          </BoxButtonStyled>
        </BoxContentStyled>
      </ItemStyledEgg>
    </>
  )
}
