import styled from 'styled-components'

const Styled = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: url('../images/island/bg-home.png') no-repeat;

  .top-bar {
    display: flex;
  }
  .lock {
    filter: grayscale(1);

    img {
      &:hover {
        cursor: not-allowed;
        filter: unset !important;
        transform: scale(1) !important;
      }
    }
  }

  .group {
    &__island {
      &__item {
        img {
          cursor: pointer;
          transition: 0.5s ease-in-out;
          transform: scale(1);

          &:hover {
            transition: 0.5s ease-in-out;
            transform: scale(1.1);
            filter: drop-shadow(-2px 0px 2px #ffffff) drop-shadow(-2px 2px 2px #ffffff) drop-shadow(2px 2px 2px #ffffff)
              drop-shadow(2px -2px 2px #ffffff);
          }
        }

        &:first-of-type {
          position: absolute;
          top: 202px;
          left: 470px;
          width: 366px;
          height: 411px;
          z-index: 1;
        }

        &:nth-child(2) {
          position: absolute;
          top: 439px;
          left: 427px;
          width: 250px;
          height: 270px;
          z-index: 4;

          img {
            &:hover {
              transform: unset;
            }
          }
        }

        &:nth-child(3) {
          position: absolute;
          top: 26px;
          left: 1251px;
          width: 429px;
          height: 490px;
          z-index: 2;
        }

        &:nth-child(4) {
          position: absolute;
          top: 174px;
          left: 1108px;
          z-index: 4;
          transform: scaleX(-1);

          img {
            max-width: 167px;

            &:hover {
              transform: unset;
            }
          }
        }

        &:nth-child(5) {
          position: absolute;
          top: 350px;
          left: 670px;
          width: 628px;
          height: 794px;
          z-index: 2;
        }

        &:nth-child(6) {
          position: absolute;
          top: 442px;
          left: 1089px;
          z-index: 4;

          img {
            width: 450px;
            height: 416px;
          }
        }

        &:nth-child(7) {
          position: absolute;
          bottom: -25px;
          left: 965px;
          z-index: 2;

          img {
            width: 440px;
            height: 320px;
          }
        }

        &:nth-child(8) {
          position: absolute;
          top: 580px;
          left: 630px;
          z-index: 4;

          img {
            width: 403px;
            height: 451px;
          }
        }

        &:nth-child(9) {
          position: absolute;
          top: 60px;
          left: 840px;
          z-index: 3;

          img {
            width: 304px;
            height: 362px;
          }
        }
      }
    }

    &__iclound {
      position: relative;

      &__item {
        &:first-of-type {
          position: absolute;
          top: 0;
          left: -60px;
          width: 100%;
          z-index: 0;
          animation: animationCloud1 70s infinite;
          animation-direction: alternate;

          img {
            width: 100%;
          }

          @keyframes animationCloud1 {
            100% {
              left: -7%;
              top: 0%;
            }

            0% {
              left: 107%;
              top: 20%;
            }
          }
        }

        &:nth-child(2) {
          position: absolute;
          top: 311px;
          right: 289px;
          z-index: 2;
          animation: animationCloud2 65s infinite;
          animation-direction: alternate;

          @keyframes animationCloud2 {
            100% {
              right: -12%;
              top: 50%;
            }

            0% {
              right: 107%;
              top: 40%;
            }
          }
        }

        &:nth-child(3) {
          position: absolute;
          bottom: 220px;
          right: 140px;
          z-index: 4;
          animation: animationCloud3 54s infinite;
          animation-direction: alternate;

          @keyframes animationCloud3 {
            100% {
              right: -11%;
              top: 70%;
            }

            0% {
              right: 107%;
              top: 30%;
            }
          }
        }

        &:nth-child(4) {
          position: absolute;
          bottom: 100px;
          left: 350px;
          z-index: 4;
          animation: animationCloud4 57s infinite;
          animation-direction: alternate;

          @keyframes animationCloud4 {
            100% {
              left: -12%;
              top: 70%;
            }

            0% {
              left: 107%;
              top: 70%;
            }
          }
        }

        &:nth-child(5) {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          z-index: 1;
          animation: animationCloud5 10s infinite;
          animation-direction: alternate;

          @keyframes animationCloud5 {
            100% {
              left: 0%;
              top: 40%;
            }

            0% {
              left: 1%;
              top: 40%;
            }
          }

          img {
            width: 100%;
            height: 714px;
          }
        }
      }
    }
  }
`

export default Styled
