import multicall, { Call } from 'utils/multicall'
import { gameCharacter } from 'config/constants/game'
import { ethers } from 'ethers'

export const _getCharactersByIds = async (
  contractAddress: string,
  contractAbi: any,
  functionCall: string,
  characterIds: any,
  activated: boolean,
) => {
  try {
    if (characterIds.length <= 0) return []

    const calls: Call[] = characterIds.map((token) => {
      const call: Call = {
        address: contractAddress,
        name: functionCall,
        params: [token.toString()],
      }
      return call
    })

    const results = await multicall([...contractAbi], calls, { requireSuccess: false })

    return results
      .map((result) => {
        return {
          _id: `${contractAddress}_${result[0].tokenId.toString()}`,
          nftAddress: contractAddress,
          heroRarity: result[0].heroRarity?.toString(),
          heroName: result[0].heroName?.toString(),
          attack: result[0].attack?.toString(),
          defense: result[0].defense?.toString(),
          speed: result[0].speed?.toString(),
          energy: result[0].energy?.toString(),
          tokenId: result[0].tokenId?.toString(),
          level: result[0].level?.toString(),
          swordId: result[0].swordId?.toString(),
          armorId: result[0].armorId?.toString(),
          shoesId: result[0].shoesId?.toString(),
          ...gameCharacter[result[0].heroRarity?.toNumber()][result[0].heroName?.toNumber()],
          activated,
        }
      })
      .sort((a, b) => b.heroRarity - a.heroRarity)
  } catch (e) {
    console.log(e)
    return []
  }
}

export const _getListingDataByIdentifiers = async (
  contractAddress: string,
  contractAbi: any,
  functionCall: string,
  identifiers: any,
) => {
  try {
    if (identifiers.length <= 0) return []

    const calls: Call[] = identifiers.map((token) => {
      const call: Call = {
        address: contractAddress,
        name: functionCall,
        params: [token],
      }
      return call
    })

    const results = await multicall([...contractAbi], calls, { requireSuccess: false })

    return results.map((result, index) => {
      return {
        _id: `${result[0].listingNft}_${result[0].id?.toString()}`,
        seller: result[0].seller?.toString(),
        paymentToken: result[0].paymentToken?.toString(),
        listingNft: result[0].listingNft?.toString(),
        id: result[0].id?.toString(),
        price: ethers.utils.formatEther(result[0].price?.toString()),
        listingTime: result[0].listingTime?.toNumber(),
      }
    })
  } catch (e) {
    console.log(e)
  }
}
