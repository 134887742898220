import { ChainId } from '@diviner-protocol/v2-sdk'
import { useWeb3React } from '@web3-react/core'
import { Input, Select } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { changeNetwork } from 'utils/wallet'

const { Option } = Select

function Network() {
  const { chainId } = useWeb3React()

  const changeNetworkHandler = (value) => {
    changeNetwork(value)
  }

  return (
    <ButtonNetwork>
      <Input.Group compact>
        <Select value={chainId} onChange={changeNetworkHandler}>
          <Option className="token-option" value={ChainId.BSC}>
            <img src="./images/connect/icon-bnb.png" alt="bsc" />
            BSC
          </Option>

          <Option className="token-option" value={ChainId.MATIC}>
            <img src="./images/connect/icon-polygon.png" alt="polygon" />
            Polygon
          </Option>
        </Select>
      </Input.Group>
    </ButtonNetwork>
  )
}

export const ButtonNetwork = styled.div`
  margin-left: -5rem;

  .ant-select-selector {
    width: 200px !important;
    height: 44px !important;
    box-shadow: inset 0px 4px 6px rgb(0 0 0 / 25%);
    border-radius: 4px;

    .ant-select-selection-item {
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translateY(-50%);
      font-size: 1.2em;
      font-weight: 600;

      img {
        width: 30px;
        margin-right: 20px;
      }
    }
  }
`
export default Network
