import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import _ from 'lodash'
import { GameState, GamePrice, UserData } from '../types'
import fetchEnemies from './fetchEnemies'
import fetchGamePrice from './fetchGamePrice'
import {
  fetchGameUserTokenInfo,
  fetchGameUserInfo,
  fetchUserCharacter,
  fetchUserEquipment,
  fetchCharacter,
  fetchUserFakeCharacter,
} from './fetchGameUserData'

const initialState: GameState = {
  activeCharacterId: 0,
  selectedRound: 0,
  fightStatus: 0,
  fightResult: {
    angelReward: '0',
    gemReward: '0',
    keyReward: '0',
  },
  gamePrice: {
    createNewHeroPrice: {
      angel: '0',
      busd: '0',
    },
    unlockLevelPrice: [
      {
        angel: '0',
        gem: '0',
        successChance: '0',
      },
    ],
    fightPrice: [],
    regain1EnergyPrice: '0',
    openMysteryBoxPrice: '0',
  },
  userData: {
    userTokenData: {
      angelBalance: '0',
      gemBalance: '0',
      angelAllowance: '0',
      busdAllowance: '0',
    },
    userGameData: {
      currentRound: '0',
      angelReward: '0',
      gemReward: '0',
      lastTimeClaim: '0',
    },
    characters: [],
    fakeCharacters: [],
    equipments: [],
  },
  enemies: [],
  isLoading: true,
}

export const gameSlice = createSlice({
  name: 'Game',
  initialState,
  reducers: {
    setActiveRoundId: (state, action) => {
      state.activeCharacterId = action.payload
    },

    setSelectedRound: (state, action) => {
      state.selectedRound = action.payload
    },
    setFightStatus: (state, action) => {
      state.fightStatus = action.payload
    },

    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },

    setFightResult: (state, action) => {
      state.fightResult = action.payload
    },

    setUserCharacter: (state, action) => {
      state.userData.characters.map((c) => {
        if (c.tokenId === action.payload.heroId) c = action.payload.character
        return c
      })
    },
    setEnergy: (state, action) => {
      const { tokenId, newEnergy } = action.payload
      const index = _.findIndex(state.userData.characters, { tokenId })
      state.userData.characters[index].energy = newEnergy
    },

    resetUserData: (state) => {
      state.userData = {
        userTokenData: {
          angelBalance: '0',
          gemBalance: '0',
          angelAllowance: '0',
          busdAllowance: '0',
        },
        userGameData: {
          currentRound: '0',
          angelReward: '0',
          gemReward: '0',
          lastTimeClaim: '0',
        },
        characters: [],
        fakeCharacters: [],
        equipments: [],
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchGameUserData.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchGameUserData.fulfilled, (state, action) => {
      state.userData = action.payload
      state.isLoading = false
    })
    builder.addCase(fetchUserTokenData.fulfilled, (state, action) => {
      state.userData.userTokenData = action.payload
    })
    builder.addCase(fetchUserGameInfo.fulfilled, (state, action) => {
      state.userData.userGameData = action.payload
    })

    builder.addCase(fetchEnemiesData.fulfilled, (state, action) => {
      state.enemies = action.payload
    })
    builder.addCase(fetchUserCharacters.fulfilled, (state, action) => {
      state.userData.characters = action.payload
    })
    builder.addCase(fetchUserFakeCharacters.fulfilled, (state, action) => {
      state.userData.fakeCharacters = action.payload
    })
    builder.addCase(fetchCharacterWithTokenId.fulfilled, (state, action) => {
      const index = _.findIndex(state.userData.characters, { tokenId: action.payload.tokenId })
      state.userData.characters[index] = action.payload
    })
    builder.addCase(fetchPrice.fulfilled, (state, action: PayloadAction<GamePrice>) => {
      state.gamePrice = action.payload
    })
  },
})

// thunk

export const fetchGameUserData = createAsyncThunk<UserData, string>('game/fetchGameUserData', async (account) => {
  const fetchGameUserTokenInfoPromise = fetchGameUserTokenInfo(account)
  const fetchGameUserInfoPromise = fetchGameUserInfo(account)
  const fetchUserCharacterPromise = fetchUserCharacter(account)
  const fetchUserFakeCharacterPromise = fetchUserFakeCharacter(account)
  const fetchUserEquipmentPromise = fetchUserEquipment(account)
  const [userTokenData, userGameData, characters, fakeCharacters, equipments] = await Promise.all([
    fetchGameUserTokenInfoPromise,
    fetchGameUserInfoPromise,
    fetchUserCharacterPromise,
    fetchUserFakeCharacterPromise,
    fetchUserEquipmentPromise,
  ])

  return { userTokenData, userGameData, characters, fakeCharacters, equipments }
})

export const fetchUserTokenData = createAsyncThunk('game/fetchUserTokenData', async (account: string) => {
  const userTokenData = await fetchGameUserTokenInfo(account)
  return userTokenData
})

export const fetchUserGameInfo = createAsyncThunk('game/fetchUserGameInfo', async (account: string) => {
  const userGameData = await fetchGameUserInfo(account)
  return userGameData
})

export const fetchEnemiesData = createAsyncThunk('game/fetchEnemiesData', async () => {
  const enemies = await fetchEnemies()
  return enemies
})

export const fetchUserCharacters = createAsyncThunk('game/fetchUserCharacters', async (account: string) => {
  const userCharacters = await fetchUserCharacter(account)
  return userCharacters
})

export const fetchUserFakeCharacters = createAsyncThunk('game/fetchUserFakeCharacters', async (account: string) => {
  const userCharacters = await fetchUserFakeCharacter(account)
  return userCharacters
})

export const fetchCharacterWithTokenId = createAsyncThunk('game/fetchCharacterWithTokenId', async (heroId: string) => {
  const character = await fetchCharacter(heroId)

  return character
})

export const fetchPrice = createAsyncThunk<GamePrice>('game/fetchPrice', async () => {
  const priceData = await fetchGamePrice()
  return priceData
})

export const fillEnergy = (tokenId: string) => async (dispatch) => {
  dispatch(
    setEnergy({
      tokenId,
      newEnergy: '5',
    }),
  )
}

// actions
export const {
  setActiveRoundId,
  setFightResult,
  setIsLoading,
  setEnergy,
  setSelectedRound,
  setUserCharacter,
  resetUserData,
  setFightStatus,
} = gameSlice.actions

export default gameSlice.reducer
