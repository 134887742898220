import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Reward from './Reward'

function PopupResult({ fightResult, reward, onBackToFight }) {
  return (
    <PopupLoseStyled fightResult={fightResult} onClick={onBackToFight}>
      <Reward reward={reward} />
    </PopupLoseStyled>
  )
}

interface Prop {
  fightResult: boolean
}

export const PopupLoseStyled = styled.div<Prop>`
  background: url(${(props) => (props.fightResult ? './images/popup/win.png' : './images/popup/lose.png')});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1300px;
  height: 725px;
  position: relative;
`
export default PopupResult
