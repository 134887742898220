import { BigNumber } from 'bignumber.js'
import eggMarketAbi from 'config/abi/EggMarket.json'
import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import { getEggMarketAddress, getFakeCharacterAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'
import eggsConfig from 'config/constants/eggMarket'
import { getChainId } from 'utils/web3React'

export const fetchEggData = async () => {
  try {
    const calls = []
    for (let i = 0; i < 6; i++) calls.push({ address: getEggMarketAddress(), name: 'eggs', params: [i] })
    const rawEggs = await multicall(eggMarketAbi, calls, {
      requireSuccess: false,
    })
    const eggs = rawEggs.map((egg, index) => {
      return {
        angelToBuy: egg.angelToBuy.toString(),
        remainEgg: egg.remainEgg.toString(),
        ratio: eggsConfig[index],
      }
    })
    return eggs
  } catch (error) {
    console.log('error', error)
  }
}
