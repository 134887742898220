import styled from 'styled-components'

const Styled = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/images/market/bg-market.png');
  padding: 60px;

  @media (max-width: 767px) {
    position: fixed;
    z-index: 1;
  }

  .marketStyled {
    position: relative;
  }

  .parameter__gem {
    display: none;
  }

  .parameter__bnb {
    display: flex;
  }

  .top-bar {
    display: flex;
  }

  .button {
    font-family: Montserrat;
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #43261c;
    margin: 16px 72px;
    display: flex;
    align-items: center;
    img {
      margin-right: 20px;
    }
  }

  .filter-group {
    height: 780px;
    width: 570px;
    background-image: url('/images/market/bg-filter.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 172px;
    margin-right: 40px;
    padding: 75px;
    color: #ffffff;

    .btn-dropdown {
      width: max-content;
      height: 40px;
      background: #84462e;
      border: 1px solid #ffffff;
      box-sizing: border-box;
      border-radius: 5px;
      font-family: Montserrat;
      font-size: 16px;
      line-height: 20px;
      color: #ffffff;
      padding: 6px;
      margin-bottom: 16px;
    }
    .section {
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      padding-bottom: 20px;
      margin: 20px 0px;
      :last-child {
        border: none;
      }

      .checkbox__price {
        display: flex;
        align-items: center;
        img {
          width: 35px;
        }
        .ant-checkbox-wrapper {
          display: flex;
          justify-content: left;
          align-items: center;
          .ant-checkbox {
            top: 0;
          }
        }
      }

      .price {
        display: flex;
        margin-right: 10px;
        align-items: center;

        > div {
          font-weight: 600;
        }

        .input-price {
          border: none;
          border-radius: 4px;
          width: 90px;
          height: 40px;
          padding: 5px 10px;
          margin-left: 5px;
          color: #000;
          box-shadow: inset 0px 4px 6px rgb(0 0 0 / 25%);

          :focus {
            outline: none;
          }
        }
      }

      .label {
        font-family: Montserrat;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 16px;
      }
      .ant-slider {
        margin-left: 20px;
        .ant-slider-handle {
          background-color: #f7cd32;
          :focus {
            outline: none;
          }
          z-index: 1;
        }
        .ant-slider-track {
          background: url('/images/market/slider-track.png');
          background-size: cover;
          background-repeat: no-repeat;
          left: 17px;
          right: auto;
          height: 10px;
          top: 8px;
          z-index: 1;
          transform: translateX(13px);
        }

        .ant-slider-rail {
          background: url('/images/market/slider-step.png');
          height: 22px;
          left: -30px;
          width: unset;
          right: -24px;
          background-size: contain;
          background-repeat: no-repeat;
        }
        .ant-slider-handle {
          width: 36px;
          height: 40px;
          margin-top: -16px;
          border-radius: 50%;
          background: url('/images/market/dot.png');
          background-size: contain;
          background-repeat: no-repeat;
          border: none;
          top: 6px;
        }
      }
      .ant-checkbox-wrapper {
        color: #ffffff;
        font-size: 16px;
        .ant-checkbox-inner {
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
        }
        .ant-checkbox-inner::after {
          width: 9px;
          height: 14px;
        }
      }

      .ant-radio-group {
        .ant-radio-button-wrapper {
          width: 40px;
          height: 40px;
          border: 1px solid #ffffff;
          background: none;
          color: #ffffff;
          border-radius: 50px;
          text-align: center;
          margin-right: 20px;
          padding: 2px 10px;
          :not(:first-child)::before {
            content: unset;
          }
        }
      }
    }

    .ant-checkbox-group {
      display: flex;
      flex-direction: row;

      .ant-checkbox-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        .ant-checkbox {
          font-size: 0px;
        }
      }
    }

    .ant-checkbox-inner {
      border: 2px solid #fff;
      filter: drop-shadow(0px 0px 2px #ffffff);
    }
  }
`

export const ConnectStyled = styled.div`
  position: absolute;
  top: 18px;
  right: 86px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;

  img {
    transition: 0.5s ease-in-out;
    transform: scale(1);

    &:hover {
      transform: scale(1.2);
      transition: 0.5s ease-in-out;
    }
  }
`

export const PlayGameStyled = styled.div`
  position: absolute;
  top: 18px;
  right: 260px;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;

  a {
    color: #ffffff;
  }

  img {
    transition: 0.5s ease-in-out;
    transform: scale(1);

    &:hover {
      transform: scale(1.2);
      transition: 0.5s ease-in-out;
    }
  }
`

export default Styled
