import React from 'react'
import Button from 'components2/Common/Button'
import { useGameUserData } from 'state/hooks'

export default function Warning({ handleShowNote, isApproving, isUpgrading, handleApprove, handleUpgrade }) {
  const { userTokenData } = useGameUserData()

  return (
    <div className="ground active">
      <div className="ground__level">
        <div className="ground__level--experience">
          <h3>Warning!!</h3>
        </div>

        <p className="success__content">
          The NFT will be burn
          <br /> If upgrade fail
        </p>
        <div className="button-note">
          {userTokenData.angelAllowance === '0' ? (
            <Button onClick={() => handleApprove()} isLoading={isApproving}>
              <img src="/images/icon/angels-token.png" alt="angels-token.png" />
              Enable
            </Button>
          ) : (
            <Button onClick={handleUpgrade} isLoading={isUpgrading}>
              Confirm
            </Button>
          )}

          <Button onClick={handleShowNote}>Cancel</Button>
        </div>
      </div>
    </div>
  )
}
