import styled from 'styled-components'

const MaskStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000a6;
  z-index: 99;

  .wishes {
    text-align: center;
    margin: 0 auto;
    width: 800px;
    background: #f5f5f5;
    border: 2px solid #ddd;
    position: fixed;
    top: -200px;
    left: 50%;
    margin-left: -400px;
  }

  .wishes-animation {
    -webkit-animation: wishes-animation 0.5s ease-in-out 13s 1 normal forwards;
    -moz-animation: wishes-animation 0.5s ease-in-out 13s 1 normal forwards;
    -o-animation: wishes-animation 0.5s ease-in-out 13s 1 normal forwards;
    animation: wishes-animation 0.5s ease-in-out 13s 1 normal forwards;
  }

  .egg-c {
    left: 10%;
    position: absolute;
    top: 83px;
    height: 100%;
    width: 100%;
    z-index: 9;
  }

  .egg {
    line-height: 0px;
    position: absolute;
    left: -160px;
    bottom: 0px;
    z-index: 8;
  }

  .egg-bottom {
    bottom: -292px;
  }

  .crack {
    line-height: 0px;
    height: 1px;
    background: #000;
    border-bottom: 1px solid #ccc;
    position: absolute;
    z-index: 9;
  }

  .cr1 {
    width: 15px;
    top: 169px;
    left: 2px;
  }

  .cr2 {
    width: 11px;
    top: 173px;
    left: 14px;
    -webkit-transform: rotate(50deg);
    -moz-transform: rotate(50deg);
    -ms-transform: rotate(50deg);
    -o-transform: rotate(50deg);
    transform: rotate(50deg);
  }

  .cr3 {
    width: 15px;
    top: 172px;
    left: 21px;
    -webkit-transform: rotate(-38deg);
    -moz-transform: rotate(-38deg);
    -ms-transform: rotate(-38deg);
    -o-transform: rotate(-38deg);
    transform: rotate(-38deg);
  }

  .cr4 {
    width: 13px;
    top: 175px;
    left: 27px;
    -webkit-transform: rotate(26deg);
    -moz-transform: rotate(26deg);
    -ms-transform: rotate(26deg);
    -o-transform: rotate(26deg);
    transform: rotate(26deg);
  }

  .cr5 {
    width: 30px;
    top: 191px;
    left: 149px;
    -webkit-transform: rotate(42deg);
    -moz-transform: rotate(42deg);
    -ms-transform: rotate(42deg);
    -o-transform: rotate(42deg);
    transform: rotate(42deg);
  }

  .cr6 {
    width: 19px;
    top: 173px;
    left: 140px;
    -webkit-transform: rotate(65deg);
    -moz-transform: rotate(65deg);
    -ms-transform: rotate(65deg);
    -o-transform: rotate(65deg);
    transform: rotate(65deg);
  }

  .cr7 {
    width: 25px;
    top: 183px;
    left: 130px;
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
    -ms-transform: rotate(-1deg);
    -o-transform: rotate(-1deg);
    transform: rotate(-1deg);
  }

  .cr8 {
    width: 10px;
    top: 180px;
    left: 132px;
    -webkit-transform: rotate(35deg);
    -moz-transform: rotate(35deg);
    -ms-transform: rotate(35deg);
    -o-transform: rotate(35deg);
    transform: rotate(35deg);
  }

  .cr9 {
    width: 15px;
    top: 172px;
    left: 135px;
    -webkit-transform: rotate(8deg);
    -moz-transform: rotate(8deg);
    -ms-transform: rotate(8deg);
    -o-transform: rotate(8deg);
    transform: rotate(8deg);
  }

  .animation-fly {
    -webkit-animation: fly 1s ease-in-out 8s 1 normal forwards;
    -moz-animation: fly 1s ease-in-out 8s 1 normal forwards;
    -o-animation: fly 1s ease-in-out 8s 1 normal forwards;
    animation: fly 1s ease-in-out 8s 1 normal forwards;
  }

  .animation-shake {
    -webkit-animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) 3s 5;
    -moz-animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) 3s 5;
    -o-animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) 3s 5;
    animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) 3s 5;
    -webkit-transform: translate3d(0px, 0px, 0px);
    -moz-transform: translate3d(0px, 0px, 0px);
    -o-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
  }

  @-webkit-keyframes shake {
    0% {
      -webkit-transform: translate3d(0px, 0px, 0px);
      transform: translate3d(0px, 0px, 0px);
    }
    20%,
    90% {
      -webkit-transform: translate3d(-1px, 0px, 0px);
      transform: translate3d(-1px, 0px, 0px);
    }
    30%,
    80% {
      -webkit-transform: translate3d(2px, 0px, 0px);
      transform: translate3d(2px, 0px, 0px);
    }
    40%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0px, 0px);
      transform: translate3d(-4px, 0px, 0px);
    }
    45%,
    60% {
      -webkit-transform: translate3d(4px, 0px, 0px);
      transform: translate3d(4px, 0px, 0px);
    }
  }

  @-moz-keyframes shake {
    0% {
      -moz-transform: translate3d(0px, 0px, 0px);
      transform: translate3d(0px, 0px, 0px);
    }
    20%,
    90% {
      -moz-transform: translate3d(-1px, 0px, 0px);
      transform: translate3d(-1px, 0px, 0px);
    }
    30%,
    80% {
      -moz-transform: translate3d(2px, 0px, 0px);
      transform: translate3d(2px, 0px, 0px);
    }
    40%,
    50%,
    70% {
      -moz-transform: translate3d(-4px, 0px, 0px);
      transform: translate3d(-4px, 0px, 0px);
    }
    45%,
    60% {
      -moz-transform: translate3d(4px, 0px, 0px);
      transform: translate3d(4px, 0px, 0px);
    }
  }

  @-o-keyframes shake {
    0% {
      -o-transform: translate3d(0px, 0px, 0px);
      transform: translate3d(0px, 0px, 0px);
    }
    20%,
    90% {
      -o-transform: translate3d(-1px, 0px, 0px);
      transform: translate3d(-1px, 0px, 0px);
    }
    30%,
    80% {
      -o-transform: translate3d(2px, 0px, 0px);
      transform: translate3d(2px, 0px, 0px);
    }
    40%,
    50%,
    70% {
      -o-transform: translate3d(-4px, 0px, 0px);
      transform: translate3d(-4px, 0px, 0px);
    }
    45%,
    60% {
      -o-transform: translate3d(4px, 0px, 0px);
      transform: translate3d(4px, 0px, 0px);
    }
  }

  @keyframes shake {
    0% {
      -webkit-transform: translate3d(0px, 0px, 0px);
      -moz-transform: translate3d(0px, 0px, 0px);
      -ms-transform: translate3d(0px, 0px, 0px);
      -o-transform: translate3d(0px, 0px, 0px);
      transform: translate3d(0px, 0px, 0px);
    }
    20%,
    90% {
      -webkit-transform: translate3d(-1px, 0px, 0px);
      -moz-transform: translate3d(-1px, 0px, 0px);
      -ms-transform: translate3d(-1px, 0px, 0px);
      -o-transform: translate3d(-1px, 0px, 0px);
      transform: translate3d(-1px, 0px, 0px);
    }
    30%,
    80% {
      -webkit-transform: translate3d(2px, 0px, 0px);
      -moz-transform: translate3d(2px, 0px, 0px);
      -ms-transform: translate3d(2px, 0px, 0px);
      -o-transform: translate3d(2px, 0px, 0px);
      transform: translate3d(2px, 0px, 0px);
    }
    40%,
    50%,
    70% {
      -webkit-transform: translate3d(-4px, 0px, 0px);
      -moz-transform: translate3d(-4px, 0px, 0px);
      -ms-transform: translate3d(-4px, 0px, 0px);
      -o-transform: translate3d(-4px, 0px, 0px);
      transform: translate3d(-4px, 0px, 0px);
    }
    45%,
    60% {
      -webkit-transform: translate3d(4px, 0px, 0px);
      -moz-transform: translate3d(4px, 0px, 0px);
      -ms-transform: translate3d(4px, 0px, 0px);
      -o-transform: translate3d(4px, 0px, 0px);
      transform: translate3d(4px, 0px, 0px);
    }
  }

  @-webkit-keyframes fly {
    0% {
      bottom: 0px;
      left: 0px;
    }
    50% {
      bottom: 400px;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    100% {
      bottom: 0px;
      left: 250px;
      -webkit-transform: rotate(160deg);
      transform: rotate(160deg);
    }
  }

  @-o-keyframes fly {
    0% {
      bottom: 0px;
      left: 0px;
    }
    50% {
      bottom: 400px;
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    100% {
      bottom: 0px;
      left: 250px;
      -o-transform: rotate(160deg);
      transform: rotate(160deg);
    }
  }

  @keyframes fly {
    0% {
      bottom: 0px;
      left: 0px;
    }
    50% {
      bottom: 400px;
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    100% {
      bottom: 0px;
      left: 250px;
      -webkit-transform: rotate(160deg);
      -moz-transform: rotate(160deg);
      -ms-transform: rotate(160deg);
      -o-transform: rotate(160deg);
      transform: rotate(160deg);
    }
  }

  @-webkit-keyframes chicken-animation {
    0% {
      bottom: -150px;
    }
    100% {
      bottom: -40px;
    }
  }

  @-moz-keyframes chicken-animation {
    0% {
      bottom: -150px;
    }
    100% {
      bottom: -40px;
    }
  }

  @-o-keyframes chicken-animation {
    0% {
      bottom: -150px;
    }
    100% {
      bottom: -40px;
    }
  }

  @keyframes chicken-animation {
    0% {
      bottom: -150px;
    }
    100% {
      bottom: -40px;
    }
  }
`

const AnimationEggMask = ({ onClose = null, eggType }) => {
  return (
    <MaskStyled onClick={onClose}>
      <div className="box-mask">
        <div className="mask-container">
          <div className="animation-shake egg-c">
            <div className="egg egg-top animation-fly">
              <img src={`./images/egg/${eggType + 1}a.png`} alt="egg-top" />
            </div>

            <div className="egg egg-bottom">
              <img src={`./images/egg/${eggType + 1}b.png`} alt="egg-bottom" />
              {/* <div className="crack cr1"></div>
              <div className="crack cr2"></div>
              <div className="crack cr3"></div>
              <div className="crack cr4"></div>
              <div className="crack cr5"></div>
              <div className="crack cr6"></div>
              <div className="crack cr7"></div>
              <div className="crack cr8"></div>
              <div className="crack cr9"></div> */}
            </div>
          </div>
        </div>
      </div>
    </MaskStyled>
  )
}

export default AnimationEggMask
