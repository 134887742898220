import styled from 'styled-components'

export const AngelsStyled = styled.div`
  position: relative;
  padding-top: 155px;
  background: url('./images/pve/bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;

  .box__boost__tooltip {
    position: relative;

    @media screen and (max-width: 414px) {
      transform: rotate(90deg);
      left: -15px;
    }

    .box__boost__tooltip--title {
      display: none;
      position: absolute;
      width: 350px;
      background: #00000075;
      font-size: 18px;
      font-weight: 700;
      color: #fff;
      padding: 10px;
      top: 0px;
      margin: 0;
      left: 50%;
      transform: translate(-50%, -120%);

      @media screen and (max-width: 414px) {
        font-size: 12px;
      }
    }
    &:hover {
      .box__boost__tooltip--title {
        display: block;
      }
    }
  }

  .angelspve__green-monster {
    .CharaterHero__img {
      img {
        transform: unset;
        /* height: 320px; */
      }
    }

    .monster {
      display: block;
    }
    .CharaterHero__attribute {
      display: none;
    }
  }

  .center-modal.safe-area {
    transform: translate(0%, -50%);
  }

  .container {
    max-width: 1520px;
    margin: 0 auto;
  }

  .angelspve__vs {
    &--video {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -70px;

      video {
        max-width: 440px;
      }
    }

    &--svg {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: ease all 0.5s;
      margin-bottom: 30px;

      &:hover {
        opacity: 0.8;
      }

      img {
        padding: 1rem 7rem;
      }

      .button-upgrade {
        transition: 0.5s ease-in-out;

        &.disable {
          filter: grayscale();
          cursor: no-drop;
          color: #211915;
          transition: 0.5s ease-in-out;
        }

        img {
          padding: 0;
        }
      }
    }
  }

  .angelspve {
    justify-content: space-around;
    display: grid;
    grid-template-columns: auto auto auto;
    margin-bottom: 9.8rem;
  }

  .show__gem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -80px;

    img {
      max-width: 35px;
    }

    span {
      font-size: 1.6em;
      font-weight: 700;
      color: #ffffff;
    }
  }

  .box {
    &__boost {
      text-align: center;
    }

    &__tooltip {
      position: relative;

      p {
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        background: #000000d6;
        color: #ffffff;
        margin-top: -38px;
        font-size: 1.2em;
        padding: 10px;
        opacity: 0;
        transition: 0.5s ease-in-out;
      }

      &:hover {
        transition: 0.5s ease-in-out;
        transform: scale(1.1);
        cursor: pointer;

        p {
          opacity: 1;
          transition: 0.5s ease-in-out;
        }
      }

      img {
        width: 35px;
        cursor: pointer;
        transition: 0.5s ease-in-out;
        transform: scale(1);
        &:hover {
          opacity: 0.7;
          transition: 0.5s ease-in-out;
        }
      }
    }

    &__boost__title {
      font-size: 1.6em;
      font-weight: 700;
      color: #ffffff;
      text-align: center;
      text-transform: capitalize;
    }

    &__boost__item {
      max-width: 382px;
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 5px;
      margin: 0 auto;
      justify-items: center;

      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background: #7095bf;
        border: 2px solid #a3b7ff;
        box-sizing: border-box;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        line-height: 54px;
        font-size: 1.2em;
        font-weight: 600;
        color: #ffffff;
        cursor: pointer;
        transition: 0.5s ease-in-out;
        transform: scale(1);

        &:hover {
          transition: 0.5s ease-in-out;
          transform: scale(1.1);
        }

        &.active {
          background: radial-gradient(64.81% 64.81% at 50% 35.19%, #4173f4 0%, #0036c1 100%);
        }
      }
    }
  }

  .angelspve__slick {
    position: relative;
    .slick-prev {
      top: 40%;
      left: -50px;
      &:before {
        content: url('./images/pve/icon-slick-left.png');
      }
    }

    .slick-next:before,
    .slick-prev:before {
      transition: ease all 0.5s;
    }

    .slick-next {
      top: 40%;
      &:before {
        content: url('./images/pve/icon-slick-right.png');
      }
    }

    .slick-list {
      overflow: hidden;
      .slick-track {
        display: flex;
        justify-content: center;
        align-items: center;
        .slick-slide.slick-cloned {
          margin: 0 auto;
        }
      }
    }
  }
`
