import styled from 'styled-components'
import PopupConnect from 'components2/Common/Popup/PopupConnect'
import GlobalModal from 'components2/static/GlobalModal'

function NoticeUpgrade({ isWin, onClose }) {
  return (
    <BoxVideo onClick={onClose}>
      <video autoPlay playsInline controls>
        {isWin ? (
          <>
            <source className="video" src="/images/videoupgrade/noitice-success.webm" type="video/webm" />
            <source src="/images/videoupgrade/noitice-success.mov" type="video/quicktime" />
          </>
        ) : (
          <>
            <source className="video" src="/images/videoupgrade/noitice-fail.webm" type="video/webm" />
            <source src="/images/videoupgrade/noitice-fail.mov" type="video/quicktime" />
          </>
        )}
      </video>
    </BoxVideo>
  )
}

export const BoxVideo = styled.div`
  video {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #000000cc;
    transform: translate(-50%, -50%);

    ::-webkit-media-controls {
      display: none !important;
    }
  }
`
export default NoticeUpgrade
