import { Row } from 'antd'
import BigNumber from 'bignumber.js'
import Back from 'components2/Common/Back'
// import GlobalModal from 'components2/static/GlobalModal'
import ListItemWithTabs, { CardCharacter } from 'components2/Common/ListItemWithTabsEgg'
import CardChest from 'components2/Common/ListItemWithTabsEgg/CardChest'
import ConnectWallet from 'components2/Common/Popup/ConnectWallet'
import Popup from 'components2/static/GlobalModal'
import { useActiveWeb3React } from 'hooks'
import useAuth from 'hooks/useAuth'
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from 'state'
import { useGameUserData } from 'state/hooks'
import CardValue from 'components/CardValue'

import { fetchChest } from '../../state/chest/index'
import { useChests } from '../../state/hooks'
import MenuHead from './components/header'
import PopupBuy from './components/PopupBuy'
import PopupSell from './components/PopupSell'
import { Styled } from './styled'

export default function Chest({ setCurrentPage }) {
  const [activeTabId, setActiveTabId] = useState('TAB_CHEST')
  const [chestType, setChestType] = useState(1)
  const { account } = useActiveWeb3React()
  const { characters: inventoryCharacter } = useGameUserData()

  const { chests } = useChests()

  const dispatch = useAppDispatch()
  const { logout } = useAuth()

  useEffect(() => {
    if (account) {
      dispatch(fetchChest())
    }
  }, [account])

  const handleShowPopup = () => {
    if (activeTabId === 'MY_INVENTORY') return Popup.show(<PopupSell closePopup={Popup.hide} />)
    if (activeTabId === 'TAB_CHEST') return Popup.show(<PopupBuy closePopup={Popup.hide} />)
  }

  const handleShowPopupConnect = () => {
    if (!account) {
      Popup.show(<ConnectWallet onClose={Popup.hide} />)
    } else {
      logout()
    }
  }

  return (
    <Styled>
      {/* <Mask /> */}
      <MenuHead />
      <Back onClick={() => setCurrentPage('home')} />
      <Row justify="center" className="space-top">
        {activeTabId !== 'MY_INVENTORY' ? (
          <>
            <div className="filter-group">
              <div className="section">
                <div className="label">Ratio</div>

                <div className="box-ratio">
                  <div className="box-ratio-item">
                    <img src="./images/egg/icon-1.png" alt="" />
                    <span>{chests[chestType - 1]?.ratio?.n}%</span>
                  </div>

                  <div className="box-ratio-item">
                    <img src="./images/egg/icon-2.png" alt="" />
                    <span>{chests[chestType - 1]?.ratio?.r}%</span>
                  </div>

                  <div className="box-ratio-item">
                    <img src="./images/egg/icon-3.png" alt="" />
                    <span>{chests[chestType - 1]?.ratio?.sr}%</span>
                  </div>

                  <div className="box-ratio-item">
                    <img src="./images/egg/icon-4.png" alt="" />
                    <span>{chests[chestType - 1]?.ratio?.ssr}%</span>
                  </div>
                </div>
              </div>

              <ul className="box-list">
                <li>
                  <span>Type:</span>

                  <strong>{chests[chestType - 1]?.ratio?.type}</strong>
                </li>
                <li>
                  <span>Price:</span>

                  <strong>
                    <CardValue
                      value={parseFloat(new BigNumber(chests[chestType - 1]?.gemToOpen).div(1e18).toFixed(0)) || 0}
                      decimals={0}
                    ></CardValue>
                    <span>Gem</span>
                  </strong>
                </li>
              </ul>
            </div>
          </>
        ) : (
          <div className="filter-group">
            <div className="section">
              <div className="label">Your hero</div>

              <div className="box-ratio">
                {[1, 2, 3, 4].map((item) => (
                  <div className="box-ratio-item">
                    <img src={`./images/egg/icon-${item.toString()}.png`} alt="" />
                    <span>
                      {inventoryCharacter.reduce((prev, cur) => {
                        if (cur.heroRarity === (item - 1).toString()) return ++prev
                        return prev
                      }, 0)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <div className="group">
          <ListItemWithTabs
            tabs={[
              { name: 'Egg', id: 'TAB_CHEST' },
              { name: 'inventory', id: 'MY_INVENTORY' },
            ]}
            onClickTab={(tab) => setActiveTabId(tab.id)}
            activeTabId={activeTabId}
            // filter={FilterInventory}
            market
          >
            {activeTabId === 'TAB_CHEST' ? (
              <>
                {chests.map((chest) => {
                  return (
                    <CardChest
                      key={chest.type}
                      type={chest.type}
                      chest={chest}
                      onSelectEgg={() => setChestType(chest.type)}
                    />
                  )
                })}
              </>
            ) : (
              inventoryCharacter.map((character, index) => {
                return <CardCharacter key={index} character={character} />
              })
            )}
          </ListItemWithTabs>
        </div>
      </Row>

      {/* <ConnectStyled>
        <div className="Connect__connect" onClick={handleShowPopupConnect}>
          <img src="./images/connect/connect-wallet.png" alt="Connectwallet.png" />
          <p>{account ? 'Logout' : 'Connect Wallet'}</p>
        </div>
      </ConnectStyled> */}
    </Styled>
  )
}
