import React from 'react'
import styled from 'styled-components'

function PopupLeaderBoard({ onClick, title, children }) {
  return (
    <PopupLeaderBoardStyled>
      <div className="popupLeaderBoard">
        <img className="close-icon" src="/images/pve/close-icon.png" alt="close-icon" onClick={onClick} />
        <div className="popupLeaderBoard--img">
          <p>{title}</p>
        </div>
      </div>
      {children}
    </PopupLeaderBoardStyled>
  )
}

const PopupLeaderBoardStyled = styled.div`
  background: url('./images/connect/bg-leader-board.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  width: 820px;
  padding: 70px 50px;
  .close-icon {
    position: absolute;
    right: -25px;
    top: -33px;
    cursor: pointer;
  }

  .popupLeaderBoard--img {
    background: url('./images/connect/wallet-title.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: -28px;
    left: 50%;
    width: 350px;
    text-align: center;
    transform: translate(-50%, 0px);
    z-index: 2;
    p {
      margin: 0;
      font-size: 22px;
      font-weight: 700;
      color: #fff;
      padding: 1rem 0px;
      text-transform: uppercase;
    }
  }
`
export default PopupLeaderBoard
