import Button from 'components2/Common/Button'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import { paymentToken } from 'config/constants/marketplace'
import { ethers } from 'ethers'
import numberWithCommas from 'utils/separator'
import { fetchBuyerFee } from 'state/marketplace/selectors/selector'
import styled from 'styled-components'
import PopupMarket from './PopupMarket'

export default function PopupUpgrade({ data = null, closePopup, handleBuy, handleCancel }) {
  const { account, chainId } = useWeb3React()
  const buyerFee = useSelector(fetchBuyerFee)

  const onBuy = async () => {
    closePopup()
    handleBuy()
  }

  const onCancel = async () => {
    closePopup()
    handleCancel()
  }

  return (
    <PopupMarket data={data} closePopup={closePopup}>
      <Styled>
        {data.seller && data.seller === account ? (
          <>
            <div className="popupBuy__img">
              <img
                src={paymentToken[chainId][data.paymentToken === ethers.constants.AddressZero ? 0 : 1].image}
                alt="Icon1"
              />
              <p>{numberWithCommas(((parseFloat(data.price) * (parseFloat(buyerFee) + 100)) / 100).toFixed(2))}</p>
            </div>
            <div className="button cancel">
              <Button onClick={onCancel}>CANCEL</Button>
            </div>
          </>
        ) : (
          <>
            <div className="popupBuy__img">
              <img
                src={paymentToken[chainId][data.paymentToken === ethers.constants.AddressZero ? 0 : 1].image}
                alt="Icon1"
              />
              <p>{numberWithCommas(((parseFloat(data.price) * (parseFloat(buyerFee) + 100)) / 100).toFixed(2))}</p>
            </div>
            <Button onClick={onBuy}>BUY NOW</Button>
          </>
        )}
      </Styled>
    </PopupMarket>
  )
}

const Styled = styled.div`
  .popupBuy__img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    margin-bottom: 80px;
    p {
      font-size: 20px;
    }
    img {
      width: 35px;
    }
  }
  .button.cancel {
    .button-upgrade {
      background: url('./images/market/price-cancel.png') no-repeat;
      height: 60px;
      background-size: 100% 100%;
      transform: translate(-50%, 0%);
    }
  }
`
