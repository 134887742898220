import { EggMarketConfig } from './types'

const chests: EggMarketConfig[] = [
  {
    type: 'Rock',
    n: '50',
    r: '43',
    sr: '5',
    ssr: '2',
  },
  {
    type: 'Glass',
    n: '40',
    r: '38',
    sr: '16',
    ssr: '6',
  },
  {
    type: 'Copper',
    n: '20',
    r: '30',
    sr: '25',
    ssr: '25',
  },
]

export default chests
