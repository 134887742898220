import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { useAppDispatch } from 'state'
import { fetchGameUserData } from 'state/actions'
import { calculateGasMargin } from 'utils'
import { useEggMarketContract, useGameContract } from './useContract'

export const useBuyEgg = () => {
  const { account } = useWeb3React()
  const eggMarketContract = useEggMarketContract()

  const handleBuyEgg = useCallback(
    async (id: string) => {
      const estimatedGas = await eggMarketContract.estimateGas.buyEgg(id).catch(() => {
        return eggMarketContract.estimateGas.buyEgg(id)
      })
      const txHash = await eggMarketContract.buyEgg(id, {
        gasLimit: calculateGasMargin(estimatedGas),
      })

      return txHash
    },
    [account, eggMarketContract],
  )

  return { onBuyEgg: handleBuyEgg }
}

export const useOpenChest = () => {
  const { account } = useWeb3React()
  const gameContract = useGameContract()

  const handleOpenChest = useCallback(
    async (type: number) => {
      const estimatedGas = await gameContract.estimateGas.openHeroChest(type).catch(() => {
        return gameContract.estimateGas.openHeroChest(type)
      })
      const txHash = await gameContract.openHeroChest(type, {
        gasLimit: calculateGasMargin(estimatedGas),
      })

      return txHash
    },
    [account, gameContract],
  )

  return { onOpenChest: handleOpenChest }
}
