import styled from 'styled-components'
export { default as CardItem } from './CardItem'

const ListItemWithTabStyled = styled.div`
  margin-top: 100px;
  justify-content: center;
  /* width: 1188px; */
  .tab-pen {
    display: flex;
    align-items: flex-end;
    margin-left: 50px;
    .tab {
      background-image: url('/images/market/bg-tab-pen.png');
      background-repeat: no-repeat;
      width: 204px;
      height: 64px;
      margin-right: 10px;
      text-align: center;
      cursor: pointer;
      color: #ffc165;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      padding: 22px;
      font-weight: 500;
      font-size: 24px;
      line-height: 38px;
      text-align: center;
      color: #949494;
      font-family: 'Hind', sans-serif;

      &.active {
        height: 76px;
        line-height: 53px;
        color: #fff;
        cursor: default;
      }
      &:hover {
        color: #fff;
      }
    }
  }
  .wrapper-list-inventory {
    background-image: url('/images/market/bg-list-item.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 46px 40px;
    width: ${(props) => (props.market ? '1100px' : '1182px')};
    height: 780px;
    .no-item {
      width: 100%;
      height: 100%;
    }
    .list-item {
      height: 614px;
      display: flex;
      flex-wrap: wrap;
      overflow-y: auto;
      display: flex;
      justify-content: center;
      ::-webkit-scrollbar {
        width: 14px;
        background-color: #f5f5f5;
        border-radius: 5px;
      }
      ::-webkit-scrollbar-thumb {
        background: #020069;
        border-radius: 5px;
      }
      ::-webkit-scrollbar-track {
        background: #ffffff;
        opacity: 0.4;
      }
    }

    .items-loadmore {
      display: block;
      width: 100%;
      margin-top: 10px;
      height: 20px;
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      color: #ffffff;
    }
  }
`

const ListItemWithTabs = ({ tabs = [], activeTabId, onClickTab, children, market }) => {
  return (
    <ListItemWithTabStyled activeTabId={activeTabId} market={market}>
      <div className="tab-pen">
        {tabs.map((tab) => (
          <div className={activeTabId === tab.id ? 'tab active' : 'tab'} onClick={() => onClickTab(tab)} key={tab.id}>
            {tab.name}
          </div>
        ))}
      </div>
      <div className="wrapper-list-inventory">
        <div className="list-item">{children}</div>
      </div>
    </ListItemWithTabStyled>
  )
}

export default ListItemWithTabs
