import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useCallback } from 'react'
import { calculateGasMargin } from 'utils'
import { useFaucetContract, useGameContract } from './useContract'

export const useCreateNewHero = () => {
  const { account } = useWeb3React()
  const gameContract = useGameContract()

  const handleCreateNewHero = useCallback(async () => {
    const estimatedGas = await gameContract.estimateGas.createNewHero().catch(() => {
      return gameContract.estimateGas.createNewHero()
    })
    const txHash = await gameContract.createNewHero({
      gasLimit: calculateGasMargin(estimatedGas),
    })
    return txHash
  }, [account, gameContract])

  return { onCreateNewHero: handleCreateNewHero }
}

export const useClaimRewards = () => {
  const { account } = useWeb3React()
  const gameContract = useGameContract()

  const handleClaimRewards = useCallback(async () => {
    const txHash = await gameContract.claimRewards()
    return txHash
  }, [account, gameContract])

  return { onClaimRewards: handleClaimRewards }
}

export const useFight = (isRound10) => {
  const { account } = useWeb3React()
  const gameContract = useGameContract()
  const handleFight = useCallback(
    async (attackingHero: string, round: string, timesAutoFight: number) => {
      // const estimatedGas = await gameContract.fight(attackingHero, round)
      const estimatedGas = await gameContract.estimateGas.fight(attackingHero, round, timesAutoFight).catch(() => {
        return gameContract.estimateGas.fight(attackingHero, round, timesAutoFight)
      })

      const gas = isRound10 ? 290000 + Math.floor(Math.random() * 10000) : calculateGasMargin(estimatedGas)
      const txHash = await gameContract.fight(attackingHero, round, timesAutoFight, {
        gasLimit: gas,
      })
      return txHash
    },
    [account, gameContract],
  )
  return { onFight: handleFight }
}

export const useBuyEnergy = () => {
  const { account } = useWeb3React()
  const gameContract = useGameContract()
  const handleBuyEnergy = useCallback(
    async (heroId: string) => {
      // const estimatedGas = await gameContract.fight(heroId, round)
      const estimatedGas = await gameContract.estimateGas.buyEnergy(heroId).catch(() => {
        return gameContract.estimateGas.buyEnergy(heroId)
      })
      const txHash = await gameContract.buyEnergy(heroId, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      return txHash
    },
    [account, gameContract],
  )
  return { onBuyEnergy: handleBuyEnergy }
}

export const useUpgrade = (lv: string) => {
  const { account } = useWeb3React()
  const gameContract = useGameContract()
  const handleUpgrade = useCallback(
    async (heroId: string) => {
      const estimatedGas = await gameContract.estimateGas.unLockLevel(heroId).catch(() => {
        return gameContract.estimateGas.unLockLevel(heroId)
      })
      const gas = 300000 + Math.floor(Math.random() * 10000)
      const txHash = await gameContract.unLockLevel(heroId, {
        gasLimit: estimatedGas,
      })
      return txHash
    },
    [account, gameContract, lv],
  )
  return { onUpgrade: handleUpgrade }
}

export const useFaucet = () => {
  const { account } = useWeb3React()
  const faucetContract = useFaucetContract()
  const handleSendToken = useCallback(async () => {
    const txHash = await faucetContract.sendToken()
    return txHash
  }, [account, faucetContract])
  return { onSendToken: handleSendToken }
}

export const useConvert = () => {
  const { account } = useWeb3React()
  const gameContract = useGameContract()

  const handleConvertAngelToGem = useCallback(
    async (amountIn: ethers.BigNumber) => {
      const txHash = await gameContract.convertAngelToGem(amountIn)
      return txHash
    },
    [account, gameContract],
  )

  const handleConvertGemToAngel = useCallback(
    async (amountIn: ethers.BigNumber) => {
      const txHash = await gameContract.convertGemToAngel(amountIn)

      return txHash
    },
    [account, gameContract],
  )
  return { onConvertAngelToGem: handleConvertAngelToGem, onConvertGemToAngel: handleConvertGemToAngel }
}

export const useMigratePreSale = () => {
  const { account } = useWeb3React()
  const gameContract = useGameContract()

  const handleMigrate = useCallback(
    async (heroId: string) => {
      const txHash = await gameContract.migrateHeroFromPreSale(heroId)
      return txHash
    },
    [account, gameContract],
  )

  return { onMigrate: handleMigrate }
}
