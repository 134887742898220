import Home from 'views/Home'
import Inventory from 'views/Inventory'
import AngelsPVE from 'views/AngelsPVE'
import Market from 'views/Market'
import Fight from 'views/Fight'
import MapGame from 'views/Mapgame'
import Chest from 'views/Chest'
import Island from 'views/Island'
import MapgameTwo from 'views/MapgameTwo'
import MapgameThree from 'views/MapgameThree'
import { Link } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from 'state'
import { fetchEnemiesData, fetchGameUserData, fetchPrice, setIsLoading } from 'state/actions'
import { fetchUserTokenData, resetUserData } from 'state/game'
import { useIsLoading, useGameUserData, useGameEnemies } from 'state/hooks'
import { useWeb3React } from '@web3-react/core'
import useRefresh from 'hooks/useRefresh'
import LoadingFullScreen from 'components2/Common/LoadingFullScreen'

export const pageNames = {
  inventory: 'inventory',
  home: 'home',
  angelspve: 'angelspve',
  market: 'market',
  fight: 'fight',
  egg: 'egg',
  mapgame: 'mapgame',
  mapgametwo: 'mapgametwo',
  mapgamethree: 'mapgamethree',
  chest: 'chest',
  island: 'island',
}

const pages = {
  home: Home,
  inventory: Inventory,
  angelspve: AngelsPVE,
  market: Market,
  fight: Fight,
  mapgame: MapGame,
  chest: Chest,
  island: Island,
  mapgametwo: MapgameTwo,
  mapgamethree: MapgameThree,
}
function fakeRequest() {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), 3000))
}

const defaultPage = Home

const Pages = () => {
  const [currentPage, setCurrentPage] = useState('home')
  const PageToRender = pages[currentPage] || defaultPage

  const { account, chainId } = useWeb3React()
  const { fastRefresh } = useRefresh()

  const dispatch = useAppDispatch()
  const isLoadingData = useIsLoading()

  useEffect(() => {
    if (account) {
      dispatch(fetchUserTokenData(account))
    }
  }, [fastRefresh])

  useEffect(() => {
    if (account) {
      dispatch(fetchEnemiesData())
      dispatch(fetchGameUserData(account))
      dispatch(fetchPrice())
    } else dispatch(resetUserData())
  }, [account])

  useEffect(() => {
    if (!account)
      fakeRequest().then(() => {
        dispatch(setIsLoading(false))
      })
  }, [account, isLoadingData])

  return <>{isLoadingData ? <LoadingFullScreen /> : <PageToRender setCurrentPage={setCurrentPage} />}</>
}

export default Pages
