import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { TABS, paymentToken } from 'config/constants/marketplace'
import {
  fetchUserAngelsToken,
  fetchBuyerFee,
  fetchApproveCharacterStatusSelector,
} from 'state/marketplace/selectors/selector'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { ethers } from 'ethers'
import numberWithCommas from 'utils/separator'
import { ChainId } from '@diviner-protocol/v2-sdk'
import { getCharacterAddress, getFakeCharacterAddress } from 'utils/addressHelpers'

const ItemStyled = styled.div`
  background-image: url('/images/market/bg-item.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 215px;
  height: 284px;
  margin: 12px 12px;
  display: flex;
  position: relative;
  cursor: pointer;
  .item-level {
    position: absolute;
    right: 22px;
    top: 51px;
    width: 50px;
  }

  .item-id {
    position: absolute;
    left: 35px;
    top: 42px;
    width: 50px;
    color: white;
  }

  .price {
    position: absolute;
    bottom: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 144px;
    height: 44px;
    background: url('../images/market/price.png') no-repeat;
    background-size: cover;
    cursor: pointer;
    color: #ffffff;
    font-size: 1.1em;
    font-weight: 600;

    img {
      width: 25px;
      margin-right: 10px;
    }
    &.cancel {
      background: url('../images/market/price-cancel.png') no-repeat;
    }
  }

  .item-img {
    margin: auto;
    margin-top: 40px;
    cursor: pointer;
  }
  .token-id {
    position: absolute;
    top: 55px;
    left: 28px;
    z-index: 4;
    color: white;

    font-weight: 600;
    font-size: 17px;
    text-transform: uppercase;
  }
  .token-color {
    color: #a01010;
  }

  .property {
    position: absolute;
    top: 52px;
    right: 15px;
  }
  .bg-id {
    /* background-image: url('/images/inventory/bg-id.png');
    background-repeat: no-repeat;
    background-size: contain; */
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 145px;
    height: 31px;
    font-family: Hind;
    font-style: normal;
    font-weight: bold;
    font-size: 19.9024px;
    line-height: 32px;
    text-align: center;
    color: #ffffff;
    text-shadow: 0px 0px 1.99024px #ffffff;
  }

  .disable {
    filter: grayscale(1);
    cursor: no-drop;
    color: #211915;
    &:hover {
      opacity: 1;
    }
  }
`

function color(name) {
  const lowerName = name.toLowerCase()
  switch (lowerName) {
    case 'dizo':
      return true
    case 'evilay':
      return true
    case 'robins':
      return true
    default:
      return false
  }
}

const CardItem = (props) => {
  const { handleShowPopup, handleCancel, handleBuy, handleApprove, data, activeTabId, handleApproveCharacter } = props
  const { account, chainId } = useWeb3React()
  const userAngelsToken = useSelector(fetchUserAngelsToken)
  const buyerFee = useSelector(fetchBuyerFee)
  const [disable, setDisable] = useState(false)

  const approveCharacterStatus = useSelector(fetchApproveCharacterStatusSelector)

  const disableForProcessing = (functionCall) => {
    setDisable(true)

    functionCall([() => setDisable(false)])
  }

  return (
    <ItemStyled className="item">
      <div className="bg-id">Level: {data.level || 1}</div>

      <img className="item-img" src={data.inventoryImg} alt="" onClick={handleShowPopup} />
      <img className="item-level" src={data.rarityImg} alt="" />
      <span className={`token-id ${color(data.name) ? null : 'token-color'}`}>#{data.tokenId}</span>

      {/* <img className="property" src="images/icon/angels-token.png" alt="" /> */}

      {activeTabId === TABS[0].id ? (
        data.seller && data.seller === account ? (
          <div
            className={`price cancel ${disable ? 'disable price' : ''}`}
            onClick={disable ? null : () => disableForProcessing(handleCancel)}
          >
            Cancel
          </div>
        ) : !userAngelsToken?.isAllowance ? (
          <div
            className={`price ${disable ? 'disable price' : ''}`}
            onClick={disable ? null : () => disableForProcessing(handleApprove)}
            disable={disable}
          >
            <img src="images/icon/angels-token.png" alt="angels-token.png" />
            Enable
          </div>
        ) : (
          <div
            className={`price ${disable ? 'disable price' : ''}`}
            onClick={disable ? null : () => disableForProcessing(handleBuy)}
            disable={disable}
          >
            {chainId ? (
              <>
                <img
                  src={paymentToken[chainId][data.paymentToken === ethers.constants.AddressZero ? 0 : 1].image}
                  alt="payment token"
                />
                <span>
                  {numberWithCommas(((parseFloat(data.price) * (parseFloat(buyerFee) + 100)) / 100).toFixed(2))}
                </span>
              </>
            ) : (
              <span>
                {numberWithCommas(((parseFloat(data.price) * (parseFloat(buyerFee) + 100)) / 100).toFixed(2))}
              </span>
            )}
          </div>
        )
      ) : data.id && parseInt(data.id) !== 0 ? (
        <div
          className={`price cancel ${disable ? 'disable price' : ''}`}
          onClick={disable ? null : () => disableForProcessing(handleCancel)}
          disable={disable}
        >
          Cancel
        </div>
      ) : !userAngelsToken.isAllowance ? (
        <div
          className={`price ${disable ? 'disable price' : ''}`}
          onClick={disable ? null : () => disableForProcessing(handleApprove)}
          disable={disable}
        >
          <img src="images/icon/angels-token.png" alt="angels-token.png" />
          Enable
        </div>
      ) : (data.nftAddress === getCharacterAddress() && !approveCharacterStatus?.isApprovedCharacter) ||
        (data.nftAddress === getFakeCharacterAddress() && !approveCharacterStatus?.isApprovedFakeCharacter) ? (
        <div
          className={`price ${disable ? 'disable price' : ''}`}
          onClick={disable ? null : () => disableForProcessing(handleApproveCharacter)}
          disable={disable}
        >
          Enable All
        </div>
      ) : (
        <div
          className={`price ${disable ? 'disable price' : ''}`}
          onClick={disable ? null : () => disableForProcessing(handleShowPopup)}
          disable={disable}
        >
          Sell
        </div>
      )}
    </ItemStyled>
  )
}

export default CardItem
