import React, { useEffect, useRef, useState } from 'react'
import { MusicButtonStyled } from './styled'

MusicButton.propTypes = {}

function MusicButton({ music }) {
  const [isPlayingMusic, setIsPlayingMusic] = useState(true)
  const musPlayerRef = useRef<HTMLAudioElement>(null)

  function clickMusic() {
    setIsPlayingMusic(!isPlayingMusic)
  }

  useEffect(() => {
    if (isPlayingMusic) {
      const clickPlay = () => {
        const promisePlay = musPlayerRef.current?.play()
        document.body.removeEventListener('mousemove', clickPlay)
        if (promisePlay) {
          //play fail when use didn't interact with document
          //add listener on user interact with browser
          promisePlay.catch((err) => {
            document.body.addEventListener('mousemove', clickPlay)
          })
        }
      }
      clickPlay()
      return () => {
        document.body.removeEventListener('mousemove', clickPlay)
      }
    }

    musPlayerRef.current?.pause()
  }, [isPlayingMusic])

  return (
    <MusicButtonStyled>
      <div onClick={clickMusic} className="music">
        <audio src={music} loop ref={musPlayerRef} autoPlay></audio>
        {isPlayingMusic ? (
          <img src="./images/music-on.png" alt="music on" className="music__img" />
        ) : (
          <img src="./images/music-off.png" alt="music off" className="music__img" />
        )}
      </div>
    </MusicButtonStyled>
  )
}

export default MusicButton
