import { ethers } from 'ethers'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'
import token from './game'

export const paymentToken = {
  56: [
    {
      name: 'BNB',
      address: ethers.constants.AddressZero,
      image: '/images/icon/icon-bnb.svg',
    },
    {
      name: 'Angel',
      address: token.angelAddresses[56],
      image: '/images/icon/angels-token.png',
    },
  ],
  97: [
    {
      name: 'BNB',
      address: ethers.constants.AddressZero,
      image: '/images/icon/icon-bnb.svg',
    },
    {
      name: 'Angel',
      address: token.angelAddresses[97],
      image: '/images/icon/angels-token.png',
    },
  ],
  137: [
    {
      name: 'MATIC',
      address: ethers.constants.AddressZero,
      image: '/images/icon/polygon-token.svg',
    },
    {
      name: 'Angel',
      address: token.angelAddresses[137],
      image: '/images/icon/angels-token.png',
    },
  ],
}

export const statusOptions = [
  { label: 'On sale', value: 'ON_SALE' },
  // { label: "Avalible", value: "AVALIBLE" },
]

export const typeOptions = [
  { label: 'Hero', value: 'HERO' },
  { label: 'Weapon', value: 'WEAPON' },
]

export const rankValue = [
  { value: 0, src: '/images/market/n.png', alt: '' },
  { value: 1, src: '/images/market/r.png', alt: '' },
  { value: 2, src: '/images/market/sr.png', alt: '' },
  { value: 3, src: '/images/market/ssr.png', alt: '' },
]

export const currencyValue = {
  56: [
    { value: ethers.constants.AddressZero, src: '/images/icon/icon-bnb.svg', alt: 'icon-bnb' },
    { value: token.angelAddresses[56], src: '/images/icon/angels-token.png', alt: 'angels-token' },
  ],
  97: [
    { value: ethers.constants.AddressZero, src: '/images/icon/icon-bnb.svg', alt: 'icon-bnb' },
    { value: token.angelAddresses[97], src: '/images/icon/angels-token.png', alt: 'angels-token' },
  ],
  137: [
    { value: ethers.constants.AddressZero, src: '/images/icon/polygon-matic.svg', alt: 'icon-matic' },
    { value: token.angelAddresses[137], src: '/images/icon/angels-token.png', alt: 'angels-token' },
  ],
}

export const raceValue = [
  { value: 1, label: 'Human' },
  { value: 2, label: 'Demon' },
  { value: 3, label: 'Angel' },
  { value: 4, label: 'ORC' },
]

export const options = [
  { component: ArrowUpOutlined, value: 'INCREASE' },
  { component: ArrowDownOutlined, value: 'DESCREASE' },
]

export const TABS = [
  { name: 'Marketplace', id: 'TAB_HERO' },
  // {name: 'Weapon', id: 'TAB_WEAPON'},
  { name: 'My Inventory', id: 'MY_INVENTORY' },
]
