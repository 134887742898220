export default {
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    137: '0x275617327c958bD06b5D6b871E7f491D76113dd8',
  },

  eggMarket: {
    56: '0x2Af4A1d58E12de09306AAc5Bc108ce03459BE226',
    97: '0x2b7dDCc69A21f6E35bf2a908FF9b3c3474bd7712',
  },
  fakeCharacter: {
    56: '0x2131452CcEA76123c81584cC49A495f5774Ae702',
    97: '0x1B1a7B7D094Cc0bE02B93384D68A93eB71EF0593',
  },

  game: {
    56: '0x0506871B68E5823693E115a5e2175b9A2c4CFB42',
    97: '0x8d20e874d3799e7205Adf0D03eF625Bb4bEE5763',
    137: '0x6f507B3c0986c011b890b534aD3ea4A188d5Ec97',
  },
  character: {
    56: '0xF46b29f3A8Cc3Bee845803C11a56251919A5f6c7',
    97: '0x688f9502EB8eA984E4F7059b2Eb5F29D21e10C7a',
    137: '0xc9e82bCf3Ab93aaC07a025DFE835A83AaE0A579A',
  },
  equipment: {
    56: '0xAdD5a0B24E7F987e8FAB863e86BaB1F583c6DFB6',
    97: '0x7EDD7F5aAe70E8F7795E5101F6f672A9Fb1e3cCB',
    137: '0xAdD5a0B24E7F987e8FAB863e86BaB1F583c6DFB6',
  },

  priceOracle: {
    56: '0xFBFb4C4E10EBD70DcE28B8b00FdAD6c8e5De38dc',
    97: '0x29150e28Aee936df7123Ab43e252eeaf3b97347C',
    137: '0xe672348d48e35eFE5c1Ee9945eDd00306458e50C',
  },

  gameWiki: {
    56: '0x7d88930d354BcB4cBB2F28eD4561b267baf147fA',
    97: '0x82a8cB461e67f171790fC14d48d72a09a38727Dc',
    137: '0x9BEc5e81557B33ef6CD87279953D4851a55A6309',
  },

  faucet: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x1b33379A0c8b97331d67fbFA082D85f1A2412911',
  },

  market: {
    56: '0xC34619417B7BFF08EF1DA1FF71A72E97B2ac1447',
    97: '0xffbE04c1D49C2c8B1352799A9c38e5DfeB9B6c85',
    137: '0x9CAa625B4aB857bEc0f0b1A200A375a967F6ADFb',
  },
  tokenTransferProxy: {
    56: '0xDDBa3aBECa5DdA842BebC5063f012C8f9E00B199',
    97: '0xDc4809f1ba57870eAE684d0853299c2A1C9ac824',
    137: '0x9CAa625B4aB857bEc0f0b1A200A375a967F6ADFb',
  },
  nftsTransferProxy: {
    56: '0xC9c0370D14e205D0a31B08385AC3f28B5F797785',
    97: '0x703e87ed9c8618C80b0633a1ccd65f054cdE06a9',
    137: '0x9CAa625B4aB857bEc0f0b1A200A375a967F6ADFb',
  },
  pool: {
    56: '0xd7817D4b4685b37978049e435364E705DDBFdcc5',
    97: '0x60BCeD760d7478a560380909286387880E00888d',
    137: '0xf97dfc8fc99B6c1978A1A59e9F542b3F352915b1',
  },

  mysteryBox: {
    56: '',
    97: '0x284649A0502fcEA5093FD905537e9FBB96774CB5',
  },
}
