import { useWeb3React } from '@web3-react/core'
import { useMigratePreSale } from 'hooks/useGame'
import { useState } from 'react'
import { useAppDispatch } from 'state'
import { fetchUserCharacters, fetchUserFakeCharacters } from 'state/game'
import styled from 'styled-components'
import Button from '../Button'

const ItemStyled = styled.div`
  background-image: url('/images/inventory/bg-item.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 209px;
  height: 288px;
  overflow: hidden;
  display: flex;
  position: relative;
  transition: ease all 0.5s;

  :hover {
    cursor: pointer;
    transform: scale(1.1);
  }

  .price {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100px;
    background-image: url(/images/inventory/bg_button.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3px;
    cursor: pointer;
  }

  .bg-id {
    position: absolute;
    top: 15px;
    left: 54%;
    z-index: 4;
    transform: translateX(-50%);
    width: 145px;
    font-style: normal;
    font-weight: 900;
    font-size: 1.7em;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Hind', sans-serif;
    text-shadow: 0px 0px 1.99024px #ffffff;
  }

  .bg-item {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .item-img {
    margin: auto;
    margin-top: 47px;
    margin-left: 16px;
    max-width: 100%;
  }

  .property {
    position: absolute;
    top: 55px;
    right: 15px;
    z-index: 4;
    max-width: 51px;
  }

  .active-button {
    color: white;
    font-size: 50px;
    z-index: 5;
  }
`

const BoxButtonStyled = styled.div`
  position: absolute;
  bottom: 20px;
  left: 53%;
  transform: translateX(-50%);
  z-index: 4;
  display: flex;
  width: 144px;
  height: 44px;
  border: none;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;

  .button-upgrade {
    width: 164px;
    height: 82px;
  }
`

const CardItem = ({ onSelectCharacter, heroId = '0', imgCharacter, imgProperty, title, isFakeCharacter = false }) => {
  const [isMigrating, setIsMigrating] = useState<boolean>(false)

  const { account } = useWeb3React()

  const { onMigrate } = useMigratePreSale()
  const dispatch = useAppDispatch()

  const handleMigrate = async () => {
    try {
      setIsMigrating(true)
      const tx = await onMigrate(heroId)

      const results = await tx?.wait()

      dispatch(fetchUserCharacters(account))
      dispatch(fetchUserFakeCharacters(account))

      setIsMigrating(false)
    } catch (error: any) {
      setIsMigrating(false)
      // const errorText = error?.data?.message ?? error.toString()
      // if (errorText.includes(angelBalanceMessageErrorMessageError)) {
      //   GlobalModal.show(<PopupNotice onClose={GlobalModal.hide} textNotice="You don't have enough balance." />)
      //   return
      // }
      // GlobalModal.show(<PopupNotice onClose={GlobalModal.hide} textNotice={errorMessage(errorText)} />)
    }
  }
  return (
    <>
      <ItemStyled className="item" onClick={onSelectCharacter}>
        <div className="bg-id">{title}</div>
        <img className="bg-item" src="images/inventory/bg-item.png" alt="" />
        <img className="item-img" src={imgCharacter} alt="" />
        <img className="property" src={imgProperty} alt="" />
        {isFakeCharacter && (
          <BoxButtonStyled>
            <Button isLoading={isMigrating} onClick={handleMigrate}>
              <span>Active</span>
            </Button>
          </BoxButtonStyled>
        )}
      </ItemStyled>
    </>
  )
}

export default CardItem
