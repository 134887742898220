import Button from 'components2/Common/Button'
import { useState, useCallback, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'

import styled from 'styled-components'
import { useGameUserData, useGamePrice } from 'state/hooks'
import { useAppDispatch } from 'state'
import game from 'config/constants/game'
import GlobalModal from 'components2/static/GlobalModal'
import { useApproveCallbackCustom } from 'hooks/useApproveCallback'
import { getGameAddress } from 'utils/addressHelpers'
import CardValue from 'components/CardValue'
import { useUpgrade } from 'hooks/useGame'
import { fetchUserTokenData, fetchUserCharacters, fetchUserGameInfo } from 'state/game/index'
import ConvertGem from '../ConvertGem'

import PopupNotice from '../PopupNotice'
import CharacterInfo from './CharacterInfo'
import Note from './Note'
import Warning from './Warning'
import CharacterReview from './CharacterReview'
import NoticeUpgrade from '../../NoticeUpgrade'

const notEnoughAngelMessageError = 'Insufficient Angel to unlock level'
const notEnoughGemMessageError = 'Not enough gem to unlock level'

export default function PopupUpgrade({ heroIndex = null, closePopup }) {
  const [isShowNote, setIsShowNote] = useState(false)
  const [isUpgrading, setUpgrading] = useState(false)

  const [isApproving, setIsApproving] = useState<boolean>(false)
  const [isShowButtonUpgrade, setIsShowButtonUpgrade] = useState<boolean>(false)

  const [selectedCharacterIndex, setSelectedCharacterIndex] = useState(heroIndex || 0)
  const { characters } = useGameUserData()
  const { account, chainId } = useWeb3React()
  const { unlockLevelPrice } = useGamePrice()
  const selectedChar = characters[selectedCharacterIndex] ?? null

  const { onUpgrade } = useUpgrade(selectedChar?.level)
  const dispatch = useAppDispatch()

  const [onApproveAngel] = useApproveCallbackCustom(game.angelAddresses[chainId], getGameAddress())

  const handleApprove = async () => {
    setIsApproving(true)
    try {
      const tx = await onApproveAngel()
      await tx?.wait()
      dispatch(fetchUserTokenData(account) as any)
      setIsApproving(false)
    } catch (error) {
      setIsApproving(false)
    }
  }
  const handleSelectHero = (value: number) => {
    if (value + selectedCharacterIndex > characters.length - 1 || value + selectedCharacterIndex < 0)
      setSelectedCharacterIndex(0)
    else setSelectedCharacterIndex(value + selectedCharacterIndex)
  }

  const handleShowNote = () => {
    setIsShowNote(!isShowNote)
  }

  const handleShowWarning = () => {
    setIsShowButtonUpgrade(!isShowButtonUpgrade)
  }

  const handleUpgrade = useCallback(async () => {
    if (unlockLevelPrice[parseInt(selectedChar?.level) - 1]?.successChance !== '100' && !isShowButtonUpgrade) {
      setIsShowButtonUpgrade(true)
      return
    }
    try {
      setUpgrading(true)
      const tx = await onUpgrade(selectedChar?.tokenId)
      const result = await tx?.wait()

      GlobalModal.show(
        <NoticeUpgrade
          isWin={result.events[result.events.length - 1].args.isSuccess}
          onClose={() =>
            GlobalModal.show(<PopupUpgrade heroIndex={selectedCharacterIndex} closePopup={GlobalModal.hide} />)
          }
        />,
      )
      setSelectedCharacterIndex(selectedCharacterIndex > 0 ? selectedCharacterIndex - 1 : 0)

      dispatch(fetchUserCharacters(account))
      dispatch(fetchUserGameInfo(account))
      setUpgrading(false)
      setIsShowNote(true)
    } catch (error: any) {
      const errorText = error?.data?.message ?? error.toString()

      if (errorText.includes(notEnoughGemMessageError)) {
        GlobalModal.show(
          <PopupNotice
            onClose={GlobalModal.hide}
            textNotice="Not enough Gem"
            onClick={() => GlobalModal.show(<ConvertGem />)}
            buttonTitle="Convert"
          />,
        )
        return
      }

      if (errorText.includes(notEnoughAngelMessageError)) {
        GlobalModal.show(<PopupNotice onClose={GlobalModal.hide} textNotice="You don't have enough balance." />)
        return
      }

      GlobalModal.show(<PopupNotice onClose={GlobalModal.hide} textNotice="Error" />)

      setUpgrading(false)
    }
  }, [onUpgrade, selectedCharacterIndex, unlockLevelPrice, characters])
  return (
    <Styled>
      <CharacterReview
        characters={characters}
        selectedCharacter={selectedCharacterIndex}
        handleSelectHero={handleSelectHero}
        onClose={closePopup}
      />
      {!isShowNote && !isShowButtonUpgrade && (
        <CharacterInfo
          handleShowNote={handleShowNote}
          selectedCharacter={selectedCharacterIndex}
          isUpgrading={isUpgrading}
          isApproving={isApproving}
          handleApprove={handleApprove}
          handleUpgrade={handleUpgrade}
        />
      )}
      {isShowNote && <Note handleShowNote={handleShowNote} />}
      {isShowButtonUpgrade && (
        <Warning
          handleShowNote={handleShowWarning}
          isUpgrading={isUpgrading}
          isApproving={isApproving}
          handleApprove={handleApprove}
          handleUpgrade={handleUpgrade}
        />
      )}
    </Styled>
  )
}

const Styled = styled.div`
  background-image: url('/images/pve/bg-popup.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 4rem 2rem;

  p {
    font-size: 20px;
    font-weight: 400;
    padding-left: 5px;
    color: #fff;
    margin: 0;
  }

  .ground__level {
    .button-upgrade {
      width: 250px;
    }
  }

  .character__name {
    text-align: center;
    p {
      font-size: 3rem;
      font-weight: 700;
      color: #fff;
      padding-left: 0;
    }
  }

  .ground {
    position: relative;
    background: #0d0d0d7a;
    margin: 0px 2rem;
    border-radius: 12px;
    width: 320px;
    height: 500px;

    &::before {
      position: absolute;
      content: '';
      background: linear-gradient(
        0.01deg,
        rgba(189, 166, 255, 0) -8.32%,
        rgba(174, 182, 255, 0.3) 34.81%,
        #b0aaf5 108.32%
      );
      top: 0px;
      height: 10px;
      width: 100%;
      left: 0;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
    }

    &.active {
      .ground__level--experience {
        h3 {
          text-transform: capitalize;
        }
      }

      .ground__content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;

        .success__content {
          display: block;
          padding: 1rem;
          padding-top: 2rem;
          text-align: center;
        }

        .button-note {
          position: absolute;
          bottom: 0px;
          left: 50%;
          transform: translate(-50%, 0%);
        }
      }

      .success__content {
        text-align: center;
        padding: 1rem;
        padding-top: 2rem;
        color: #e76868;
        font-weight: 500;
        font-style: italic;
      }

      .button-note {
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }

    .ground__level--experience {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 1rem 0px;
      position: relative;
      color: #fff;

      &::before {
        position: absolute;
        content: '';
        background: radial-gradient(
          45.45% 45.45% at 50% 50%,
          #b0aaf5 0.01%,
          rgba(174, 182, 255, 0.3) 33.85%,
          rgba(189, 166, 255, 0) 100%
        );
        bottom: 0;
        left: 0;
        width: 100%;
        height: 5px;
      }

      h3 {
        color: #fff;
        font-weight: 700;
        font-size: 1.7rem;
        margin: 0;
        text-transform: uppercase;
      }

      .span--update {
        color: #567bff;
        padding-left: 10px;
      }
    }

    .ground__level--experience.content {
      display: grid;
      grid-template-columns: auto auto;

      .experience--span {
        display: flex;
        justify-content: left;
        align-items: center;
      }

      span {
        font-size: 20px;
        font-weight: 400;
      }
    }

    .ground__price {
      display: flex;
      justify-content: center;
      align-items: center;

      .experience--icon {
        padding: 1rem 10px;

        img {
          max-width: 34px;
        }
      }
    }

    .experience--icon {
      display: flex;
      justify-content: left;
      align-items: center;
      padding: 1rem 0px;
    }

    .ground__success {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: ease all 0.5s;

      &:hover {
        opacity: 0.8;
      }

      p {
        padding-left: 0;
        padding-right: 10px;
        color: #e76868;
        font-weight: 600;
      }
    }
  }

  .close-icon {
    position: absolute;
    right: -25px;
    top: -33px;
    cursor: pointer;
  }

  .character {
    padding-left: 2rem;

    .character__img {
      width: 472px;
      height: 438px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .character__img--item {
        position: absolute;
        bottom: 46px;
        left: 17%;
      }

      .upgrade-character {
        width: 379px;
        z-index: 10;
        margin-bottom: 26px;
      }

      .next {
        transform: scaleX(-1);

        &:hover {
          transform: scaleX(-1.2);
          cursor: pointer;
        }
      }

      .previous:hover {
        transform: scaleX(1.2);
        cursor: pointer;
      }
    }
  }

  .button-upgrade {
    margin: auto;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 22px;
    line-height: 60px;
    text-align: center;
    color: #4b1b00;
    padding: 0px 3rem;
    color: #fff;

    &.disable {
      cursor: no-drop;
      color: #211915;
      &:hover {
        opacity: 1;
      }
    }
  }
`
