import { configureStore } from '@reduxjs/toolkit'
import { load } from 'redux-localstorage-simple'
import { useDispatch } from 'react-redux'
import pricesReducer from './prices'
import blockReducer from './block'
import user from './user/reducer'
import lists from './lists/reducer'
import game from './game'
import egg from './eggMarket'
import chest from './chest'
import marketplace from './marketplace'

// import prediction from './prediction'
import application from './application/reducer'
import multicall from './multicall/reducer'
import transactions from './transactions/reducer'

type MergedState = {
  user: {
    [key: string]: any
  }
  transactions: {
    [key: string]: any
  }
}
const PERSISTED_KEYS: string[] = ['user', 'transactions']
const loadedState = load({ states: PERSISTED_KEYS }) as MergedState
if (loadedState.user) {
  // loadedState.user.userDarkMode = getThemeCache()
}
const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    transactions,
    multicall,
    lists,
    user,
    application,
    game,
    // egg,
    marketplace,
    egg,
    chest,
    // prices: pricesReducer,
    block: blockReducer,
  },
  // middleware: [...(getDefaultMiddleware({ thunk: false }) as any), save({ states: PERSISTED_KEYS })],
  // preloadedState: loadedState,
})

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type AppState = ReturnType<typeof store.getState>

export default store
