import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import gameAbi from 'config/abi/BNBHero.json'
import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import multicall from 'utils/multicall'
import gameConfig, { gameCharacter } from 'config/constants/game'
import { getAddress, getEggMarketAddress, getFakeCharacterAddress } from 'utils/addressHelpers'
import { getEggMarketContract, getFakeCharacterContract } from 'utils/contractHelpers'
import { Character } from '../types'

export const fetchGameUserTokenInfo = async (account: string) => {
  const calls = []
  const angelToken = getAddress(gameConfig.angelAddresses)

  calls.push({ address: angelToken, name: 'balanceOf', params: [account] })
  calls.push({ address: angelToken, name: 'allowance', params: [account, getEggMarketAddress()] })

  try {
    const [angelBalance, angelAllowance] = await multicall(erc20ABI, calls, {
      requireSuccess: false,
    })

    return {
      angelBalance: angelBalance.toString(),
      angelAllowance: angelAllowance.toString(),
    }
  } catch (error) {
    console.log('error fetchGameUserTokenInfo', error)
  }
}

export const fetchAmountEggUser = async (account: string) => {
  try {
    const eggMarketContract = getEggMarketContract()
    const amountEgg = await eggMarketContract.users(account)
    return amountEgg.toString()
  } catch (error) {
    console.log('error fetch amount egg', error)
    return null
  }
}

export const fetchUserCharacter = async (account: string): Promise<Character[]> => {
  try {
    const fakeCharacterContract = getFakeCharacterContract()
    const tokenIds = await fakeCharacterContract?.tokensOfOwner(account)

    // console.log('tokenIds', tokenIds)
    const calls = []
    tokenIds.forEach((tokenId) => {
      calls.push({ address: getFakeCharacterAddress(), name: 'getHero', params: [tokenId.toString()] })
    })

    const results = await multicall(fakeCharacterAbi, calls, { requireSuccess: false })

    return results
      .map((result) => {
        return {
          tokenId: result[0].tokenId.toString(),
          heroRarity: result[0].heroRarity.toString(),
          heroName: result[0].heroName.toString(),
          ...gameCharacter[result[0].heroRarity.toNumber()][result[0].heroName.toNumber()],
        }
      })
      .sort((a, b) => b.heroRarity - a.heroRarity)
  } catch (error) {
    console.log('error fetchUserCharacter', error)
    return null
  }
}
