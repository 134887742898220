import styled from 'styled-components'

function CharaterHero({ lv = '', animateImg = null, hero = '', children }) {
  return (
    <CharaterHeroStyled>
      <div className="CharaterHero">
        <div className="CharaterHero__img">{animateImg && <img src={animateImg} alt="CharacterHero.png" />}</div>
        <div className="CharaterHero__content">
          <div className="CharaterHero__nameExp">
            <div className="CharaterHero__exp">{animateImg && <p>Lv. {lv}</p>}</div>
            <div className="CharaterHero__name">
              <p>{hero}</p>
            </div>
          </div>

          {children}
        </div>
      </div>
    </CharaterHeroStyled>
  )
}

export const CharaterHeroStyled = styled.div`
  background: url('./images/pve/bg-character.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 460px;
  height: 600px;
  position: relative;
  p {
    margin: 0;
    text-transform: capitalize;
    color: #ffac89;
  }

  .monster {
    display: none;
  }

  .CharaterHero__content {
    position: absolute;
    bottom: 10px;
    width: 100%;
    padding: 0px 2rem;
  }

  .CharaterHero__img {
    display: flex;
    justify-content: center;
    height: 381px;
    padding-top: 2rem;

    img {
      transform: scaleX(-1);
    }
  }

  .CharaterHero__exp {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #ffc165;
    font-weight: 600;
    font-size: 34px;
  }

  .CharaterHero__nameExp {
    position: relative;
    p {
      color: #fff;
      font-size: 22px;
      font-weight: 700;
      text-transform: uppercase;
    }
    .CharaterHero__name {
      text-align: center;
      padding-top: 1rem;
    }
    .CharaterHero__exp {
      position: absolute;
      top: 3px;
      left: 10px;
    }
  }

  .CharaterHero__attribute {
    justify-content: space-between;
    display: grid;
    grid-template-columns: auto auto;
    p {
      font-weight: 400;
      color: #fff;
      font-size: 16px;
    }

    .CharaterHero__attribute--bg {
      padding: 10px 0px;
      padding-bottom: 5px;
      display: flex;
      justify-content: space-between;
      margin: 5px;
      width: 150px;
      text-transform: capitalize;
      font-size: 14px;
    }
    .CharaterHero__attribute--icon {
      display: flex;
    }
  }
`
export default CharaterHero
