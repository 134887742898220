import BigNumber from 'bignumber.js'
import useRefresh from 'hooks/useRefresh'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { fetchPrices } from './prices'
import { PriceState, State } from './types'

// Prices
export const useFetchPriceList = () => {
  const { slowRefresh } = useRefresh()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(fetchPrices() as any)
  }, [dispatch, slowRefresh])
}

export const useGetApiPrices = () => {
  const prices: PriceState['data'] = useSelector((state: State) => state.prices.data)
  return prices
}

export const useGetApiPrice = (token: string) => {
  const prices = useGetApiPrices()

  if (!prices) {
    return null
  }
  return prices[token.toLowerCase()]
}

// export const usePriceDptBusd = (): BigNumber => {
//   return new BigNumber(DPT_PRICE) // TODO: this one will call api , current set price default
// }

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block)
}

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock)
}

// Game

export const useActiveCharacterId = () => {
  return useSelector((state: State) => state.game.activeCharacterId)
}

export const useFightStatus = () => {
  return useSelector((state: State) => state.game.fightStatus)
}

export const useFightResult = () => {
  return useSelector((state: State) => state.game.fightResult)
}

export const useGamePrice = () => {
  return useSelector((state: State) => state.game.gamePrice)
}

export const useActiveCharacter = () => {
  return useSelector((state: State) => state.game.userData.characters[state.game.activeCharacterId])
}

export const useSelectedRound = () => {
  return useSelector((state: State) => state.game.selectedRound)
}

export const useGameUserData = () => {
  return useSelector((state: State) => state.game.userData)
}

export const useGameEnemies = () => {
  return useSelector((state: State) => state.game.enemies)
}

export const useIsLoading = () => {
  return useSelector((state: State) => state.game.isLoading)
}

//  egg market
export const useEggs = () => {
  return useSelector((state: State) => state.egg.eggs)
}

export const useEggUserData = () => {
  return useSelector((state: State) => state.egg.userData)
}

export const useChests = () => {
  return useSelector((state: State) => state.chest)
}
