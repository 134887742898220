import React from 'react'
import styled from 'styled-components'

function CharacterMonster({ successChance, attack, defense, price }) {
  const expList = [
    {
      name: 'Success Chance',
      exp: attack,
      img: './images/popup/icon1.png',
    },
    // {
    //   name: 'Defense',
    //   exp: defense,
    //   img: './images/popup/icon2.png',
    // },
  ]
  return (
    <CharacterMonsterStyled className="monster">
      <div className="monster__exp gem">
        <div className="monster__exp--content ">
          <div className="monster__exp--icon ">
            <img src="./images/icon/gem-token.png" alt="icon.img" />
            <p>Estimate Reward</p>
          </div>
          <p className="monster__gem--price">
            {price} - {Math.floor(price * 1.4)}
          </p>
        </div>
      </div>

      <div className="monster__exp">
        {expList.map((exp, index) => (
          <div className="monster__exp--content" key={index}>
            <div className="monster__exp--icon">
              <img src={exp.img} alt="icon.img" />
              <p>{exp.name}</p>
            </div>
            <p>{successChance.toFixed(0)} %</p>
          </div>
        ))}
      </div>
    </CharacterMonsterStyled>
  )
}

const CharacterMonsterStyled = styled.div`
  p {
    font-weight: 400;
    color: #fff;
    font-size: 16px;
    margin-left: 10px;
  }

  .monster__exp {
    display: grid;
    /* grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between; */
    width: 100%;
    .monster__exp--content {
      display: flex;
      align-items: center;
      padding: 10px 0px;
      padding-bottom: 5px;
      margin: 5px;
      justify-content: space-between;
      .monster__exp--icon {
        display: flex;
        align-items: center;
      }
    }
  }
  .monster__exp.gem {
    display: block;
    justify-content: space-between;
    .monster__gem--price {
      font-weight: 700;
    }
    .monster__exp--content {
      justify-content: space-between;
      .monster__exp--icon {
        img {
          width: 25px;
        }
      }
    }
  }
`
export default CharacterMonster
