import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { mapKeys, omit, isEmpty } from 'lodash'
import {
  fetchingMarket,
  fetchingInventory,
  increaseMarketPage,
  updateApproveToken,
  updateMarketPage,
} from './actions/actions'
import { fetchUserToken } from './actions/tokenActions'
import { fetchInventoryCharacters } from './actions/inventoryActions'
import {
  fetchListingCharacters,
  fetchSellerFee,
  fetchBuyerFee,
  addListing,
  cancelListing,
  purchaseListing,
  fetchApproveCharacterStatus,
} from './actions/marketActions'
import { MarketplaceState } from './types/type'

const initialState: MarketplaceState = {
  listings: {},
  inventoryCharacters: {},
  pagintion: {
    marketPage: 0,
    limit: 52,
  },
  sorts: {
    price: 1,
  },
  filters: {
    onSale: false,
    rank: null,
    classCharacter: [],
    level: [0, 100],
    rangePrice: {
      from: 0,
      to: 0,
    },
    token: [],
  },
  fetching: {
    market: false,
    inventory: false,
  },
  user: {
    angels: {
      balance: '0',
      allowance: '0',
      isAllowance: false,
    },
  },
  fee: {
    sellerFee: '',
    buyerFee: '',
  },
  approveCharacterStatus: {
    isApprovedCharacter: false,
    isApprovedFakeCharacter: false,
  },
}

export const marketplaceSlicer = createSlice({
  name: 'marketplace',
  initialState,
  reducers: {
    sortByPrice: (state, action) => {
      state.sorts.price = action.payload
    },
    filterByRank: (state, action) => {
      state.filters.rank = action.payload
    },
    filterByClass: (state, action) => {
      state.filters.classCharacter = action.payload
    },
    filterByLevel: (state, action) => {
      state.filters.level = action.payload
    },
    filterByPriceFrom: (state, action) => {
      if (!action.payload) {
        state.filters.rangePrice.from = 0
        state.filters.rangePrice.to = 0
      } else if (action.payload >= 0)
        if (action.payload > state.filters.rangePrice.to) {
          state.filters.rangePrice.from = action.payload
          state.filters.rangePrice.to = action.payload
        } else state.filters.rangePrice.from = action.payload
    },
    filterByPriceTo: (state, action) => {
      if (!action.payload) {
        state.filters.rangePrice.from = 0
        state.filters.rangePrice.to = 0
      } else if (action.payload >= 0)
        if (action.payload < state.filters.rangePrice.from) {
          state.filters.rangePrice.from = action.payload
          state.filters.rangePrice.to = action.payload
        } else state.filters.rangePrice.to = action.payload
    },
    filterByOnSale: (state, action) => {
      state.filters.onSale = action.payload
    },
    filterByToken: (state, action) => {
      state.filters.token = action.payload
    },
    resetState: (state) => {
      state.listings = {}
      state.inventoryCharacters = {}
      state.pagintion = {
        marketPage: 0,
        limit: 52,
      }
      state.sorts = {
        price: 1,
      }
      state.filters = {
        onSale: false,
        rank: null,
        classCharacter: [],
        level: [0, 100],
        rangePrice: {
          from: 0,
          to: 0,
        },
        token: [],
      }
      state.fetching = {
        market: false,
        inventory: false,
      }
      state.user = {
        angels: {
          balance: '0',
          allowance: '0',
          isAllowance: false,
        },
      }
      state.fee = {
        sellerFee: '',
        buyerFee: '',
      }
      state.approveCharacterStatus = {
        isApprovedCharacter: false,
        isApprovedFakeCharacter: false,
      }
    },
    updateCharacterApproveStat: (state, action) => {
      state.approveCharacterStatus.isApprovedCharacter = action.payload
    },
    updateFakeCharacterApproveStat: (state, action) => {
      state.approveCharacterStatus.isApprovedFakeCharacter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchingMarket, (state, action: PayloadAction<boolean>) => {
        state.fetching.market = action.payload
      })
      .addCase(fetchingInventory, (state, action: PayloadAction<boolean>) => {
        state.fetching.inventory = action.payload
      })
      .addCase(increaseMarketPage, (state) => {
        state.pagintion.marketPage += 1
      })
      .addCase(updateMarketPage, (state, action) => {
        state.pagintion.marketPage = action.payload
      })
      .addCase(fetchUserToken.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(fetchBuyerFee.fulfilled, (state, action) => {
        state.fee.buyerFee = action.payload
      })
      .addCase(fetchSellerFee.fulfilled, (state, action) => {
        state.fee.sellerFee = action.payload
      })
      .addCase(updateApproveToken, (state, action) => {
        state.user.angels.isAllowance = action.payload
      })
      .addCase(fetchApproveCharacterStatus.fulfilled, (state, action) => {
        state.approveCharacterStatus = action.payload
      })
      .addCase(fetchListingCharacters.fulfilled, (state, action) => {
        if (isEmpty(action.payload)) return
        state.listings = { ...state.listings, ...mapKeys(action.payload, '_id') }
      })
      .addCase(fetchInventoryCharacters.fulfilled, (state, action) => {
        if (isEmpty(action.payload)) return
        state.inventoryCharacters = { ...state.inventoryCharacters, ...mapKeys(action.payload, '_id') }
      })
      .addCase(addListing.fulfilled, (state, action) => {
        if (isEmpty(action.payload)) return
        state.inventoryCharacters = {
          ...state.inventoryCharacters,
          [`${action.payload.listingNft}_${action.payload.id}`]: action.payload,
        }
        state.listings = { ...state.listings, [`${action.payload.listingNft}_${action.payload.id}`]: action.payload }
      })
      .addCase(cancelListing.fulfilled, (state, action) => {
        if (isEmpty(action.payload)) return
        state.inventoryCharacters = {
          ...state.inventoryCharacters,
          [`${action.payload.nftAddress}_${action.payload.tokenId}`]: action.payload,
        }
        state.listings = omit(state.listings, [`${action.payload.nftAddress}_${action.payload.tokenId}`])
      })
      .addCase(purchaseListing.fulfilled, (state, action) => {
        if (isEmpty(action.payload)) return
        state.inventoryCharacters = {
          ...state.inventoryCharacters,
          [`${action.payload.nftAddress}_${action.payload.tokenId}`]: action.payload,
        }
        state.listings = omit(state.listings, [`${action.payload.nftAddress}_${action.payload.tokenId}`])
      })
  },
})

export const {
  sortByPrice,
  filterByRank,
  filterByClass,
  filterByLevel,
  filterByPriceFrom,
  filterByPriceTo,
  filterByOnSale,
  filterByToken,
  resetState,
  updateCharacterApproveStat,
  updateFakeCharacterApproveStat,
} = marketplaceSlicer.actions

export default marketplaceSlicer.reducer
