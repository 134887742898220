import { ChainId } from '@diviner-protocol/v2-sdk'
import { useActiveWeb3React } from 'hooks'
import useAuth from 'hooks/useAuth'
import React from 'react'
import styled from 'styled-components'
import MusicButton from '../MusicButton'
Connect.propTypes = {}

function Connect({ Wallet, Claim, onFaucet, onMysteryEgg, onConvertGem, onMygrate }) {
  const { logout } = useAuth()
  const { account, chainId } = useActiveWeb3React()

  return (
    <ConnectStyled>
      {!account ? (
        <div className="Connect__connect" onClick={Wallet}>
          <img src="./images/connect/connect-wallet.png" alt="Connectwallet.png" />
          <p>Connect Wallet</p>
        </div>
      ) : (
        <div className="Connect__connect" onClick={logout}>
          <img src="./images/connect/connect-wallet.png" alt="Connectwallet.png" />
          <p>Logout Wallet</p>
        </div>
      )}
      {/* <div className="Connect__connect" onClick={Claim}>
        <img src="./images/connect/claim-reward.png" alt="Claimreward" />
        <p>Claim reward</p>
      </div> */}
      {/* 
      <div className="Connect__connect" onClick={onFaucet}>
        <img src="./images/connect/claim-reward.png" alt="Claimreward" />
        <p>Faucet</p>
      </div> */}

      <div className="Connect__connect" onClick={onMysteryEgg}>
        <img src="./images/connect/mystery-egg.png" alt="Mystery Egg" />
        <p>Mystery Egg</p>
      </div>

      <div className="Connect__connect" onClick={onConvertGem}>
        <img src="./images/connect/convert-cem.png" alt="convert-cem" />
        <p>Convert GEM</p>
      </div>

      {chainId === ChainId.BSC && (
        <div className="Connect__connect" onClick={onMygrate}>
          <img src="./images/connect/icon-migrate.png" alt="migrate" />
          <p>Migrate</p>
        </div>
      )}

      <MusicButton music="./home-music.mp3" />
    </ConnectStyled>
  )
}

export const ConnectStyled = styled.div`
  position: absolute;
  top: 40px;
  right: 40px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;

  @media (max-width: 480px) {
    right: 220px;
  }

  @media (max-width: 375px) {
    right: 120px;
  }

  img {
    padding-bottom: 5px;
    transition: ease all 0.5s;

    &:hover {
      transform: scale(1.1);
      cursor: pointer;
    }
  }
  .Connect__connect {
    text-align: center;
    cursor: pointer;
  }
`
export default Connect
