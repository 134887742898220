import React from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'

const LoadingFullScreen = (props) => {
  return (
    <LoadingMain className="loader-container" {...props}>
      <div className="box-video">
        <img src="./images/loading.gif" alt="" />
      </div>
    </LoadingMain>
  )
}

const LoadingMain = styled.div`
  .box-video {
    @media (max-width: 767px) {
      transform: rotate(90deg);
    }
  }

  img {
    position: absolute;
    z-index: 99999;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;

    @media (max-width: 767px) {
      width: 200px;
      top: -50px;
      left: 420px;
      transform: none;
    }
  }
`

export default LoadingFullScreen
