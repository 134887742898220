import styled from 'styled-components'

export const WidgetMenu = styled.div`
  padding: 2.5rem 3.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 400px;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
`

export const BoxAvatar = styled.div`
  position: relative;

  .img-avatar {
    position: relative;

    img.box-avatar {
      position: relative;
      top: 0;
      left: 0;
      z-index: 2;
      max-width: 443px;
    }

    .avatar {
      &:before {
        content: '';
        background: #ffffff;
        width: 120px;
        height: 120px;
        position: absolute;
        top: 27px;
        left: 20px;
      }

      img {
        position: absolute;
        top: 35px;
        left: 26px;
        z-index: 1;
        max-width: 110px;
      }
    }
  }

  span {
    position: absolute;
    top: 20%;
    left: 30%;
    color: #ffc165;
    font-size: 24px;
    font-weight: 600;
    z-index: 3;
  }

  p {
    position: absolute;
    top: 61%;
    left: 30%;
    color: #ffffff;
    font-size: 24px;
    font-weight: 500;
    z-index: 3;
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
      background: transparent;
      border: none;
      padding-left: 17px;
      cursor: pointer;
      transition: 0.5s ease-in-out;

      &:hover {
        opacity: 0.7;
        transition: 0.5s ease-in-out;
      }
    }
  }
`

export const BoxParameter = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  align-items: center;
  justify-self: center;
  padding-right: 9rem;

  .parameter__bnb {
    display: none;
    img.bnb-token {
      width: 47px;
    }
  }

  .parameter__gem {
    .content {
      span {
        margin-right: 20px;
      }
      img {
        top: 10px;
        width: 42px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`

export const Item = styled.div`
  position: relative;
  background: #00000050;
  height: 42px;
  display: flex;
  align-items: center;
  border-radius: 50px;

  .content {
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 50px 1fr 32px;
    align-items: center;

    span {
      color: #ffffff;
      font-size: 26px;
      font-weight: 500;
      /* -webkit-text-stroke: 1.5px #662e2a; */
    }

    > img {
      width: 50px;
      margin-left: -10px;

      &.gem-token {
        width: 55px;
      }
    }

    a {
      img {
        position: absolute;
        top: 5px;
        right: -10px;
        width: 40px;
      }
    }
    p {
      width: auto;
      height: 50px;
      background: transparent;
      border: none;
      position: relative;
      cursor: pointer;
      transition: 0.5s ease-in-out;

      &:hover {
        opacity: 0.7;
        transition: 0.5s ease-in-out;
      }

      img {
        position: absolute;
        top: 5px;
        right: -10px;
        width: 40px;
      }
    }
  }
  .token {
    display: flex;
    justify-content: center;
  }
`

export const BoxLeader = styled.div`
  display: none;
  width: 130px;
  text-align: center;
  position: absolute;
  top: 280px;
  left: 3.5rem;
  cursor: pointer;
  img {
    transition: all 0.5s ease;
  }
  &:hover {
    img {
      transform: scale(1.1);
    }
  }
  p {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
  }
`

export const ControlMenu = styled.div``

export const ItemControlMenu = styled.div``

export const ITem = styled.div``
