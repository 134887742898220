import React from 'react'
import styled from 'styled-components'
import Reward from './Reward'

function PopupResult({ reward, onBackToFight }) {
  return (
    <PopupLoseStyled onClick={onBackToFight}>
      <Reward reward={reward} />
    </PopupLoseStyled>
  )
}

export const PopupLoseStyled = styled.div`
  background: url('./images/mapgame/congratulation.png')});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 1000px;
  height: 725px;
  position: relative;
`
export default PopupResult
