// Set of helper functions to facilitate wallet setup

import { ChainId } from '@diviner-protocol/v2-sdk'
import { NETWORKS } from '../constants'

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (chainId?) => {
  const provider = (window as WindowChain).ethereum
  let networkParams

  if (!chainId) {
    networkParams = [NETWORKS[process.env.REACT_APP_CHAIN_ID]]
  }

  if (provider) {
    try {
      await provider.request({
        method: 'wallet_addEthereumChain',
        params: chainId ? [NETWORKS[chainId]] : networkParams,
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
    return false
  }
}

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const changeNetwork = async (chainId?) => {
  const provider = (window as WindowChain).ethereum

  if (provider) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        // params: chainId ?? process.env.REACT_APP_CHAIN_ID,
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })
      window.localStorage.setItem('chainId', chainId.toString())
      window.location.reload()
      return true
    } catch (error) {
      console.log('error change network : ', error)
      await setupNetwork()
      window.localStorage.setItem('chainId', chainId.toString())
      window.location.reload()

      return false
    }
  } else {
    console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
    return false
  }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @param tokenImage
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenImage: string,
) => {
  const tokenAdded = await (window as WindowChain).ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: tokenImage,
      },
    },
  })

  return tokenAdded
}
