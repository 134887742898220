import { useEffect, useState } from 'react'
import { useGameUserData } from 'state/hooks'
import { usePoolContract } from './useContract'

export const usePool = () => {
  const poolContract = usePoolContract()
  const [rateGemToAngel, setRateGemToAngel] = useState<number>(0)
  const [chargeFeePercent, setChargeFeePercent] = useState<number>(0)
  const { userTokenData, userGameData } = useGameUserData()

  useEffect(() => {
    const fetchPoolInfo = async () => {
      const { lastTimeClaim } = userGameData
      const now = Date.now()
      if (now < parseInt(lastTimeClaim) + 604800000) {
        const daySinceFirstClaimGem = Math.floor(7 - (parseInt(lastTimeClaim) + 604800000 - now) / 86400000)
        setChargeFeePercent(parseInt((await poolContract.chargeFeePercent(daySinceFirstClaimGem)).toString()))
      }
      setRateGemToAngel(parseInt((await poolContract.rateGemToAngel()).toString()))
    }

    fetchPoolInfo()
  }, [poolContract])

  return {
    rateGemToAngel,
    angelBalance: userTokenData.angelBalance,
    gemBalance: userGameData.gemReward,
    chargeFeePercent,
    lastTimeClaim: parseInt(userGameData.lastTimeClaim),
    angelAllowance: userTokenData.angelAllowance,
  }
}
