import BigNumber from 'bignumber.js'
import React from 'react'
import styled from 'styled-components'

const ItemStyledEgg = styled.div`
  background-image: url('/images/inventory/bg-item.png');
  background-repeat: no-repeat;
  background-size: contain;
  margin: 12px 12px;
  width: 204px;
  height: 284px;
  display: flex;
  position: relative;
  filter: grayscale(1);
  cursor: not-allowed;

  img.img-egg {
    transition: 0.5s ease-in-out;
    transform: scale(1);
  }

  &:hover {
    img.img-egg {
      transition: 0.5s ease-in-out;
      transform: scale(1.2);
    }
  }

  .price {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100px;
    background-image: url(/images/inventory/bg_button.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3px;
    cursor: pointer;
  }

  .bg-id {
    position: absolute;
    top: 15px;
    left: 54%;
    z-index: 4;
    transform: translateX(-50%);
    width: 145px;
    font-style: normal;
    font-weight: 900;
    font-size: 1.7em;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Hind', sans-serif;
    text-shadow: 0px 0px 1.99024px #ffffff;
  }

  .bg-item {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .item-img {
    margin: auto;
    margin-top: 47px;
    margin-left: 16px;
  }

  .property {
    position: absolute;
    top: 55px;
    right: 15px;
    z-index: 4;
  }
`
const BoxContentStyled = styled.div`
  position: absolute;
  top: 84px;
  left: 53%;
  height: 63%;
  transform: translateX(-50%);
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-egg {
    width: 70px;
    margin-bottom: 10px;
  }
  .angel-token {
    width: 33px;
  }
`

const BoxButtonStyled = styled.button`
  background: url('images/inventory/bg-btn.png') no-repeat;
  width: 144px;
  height: 44px;
  border: none;
  margin-bottom: 10px;
  cursor: not-allowed;
  transition: 0.5s ease-in-out;
  margin-top: auto;

  :disabled {
    opacity: 0.7;
    transition: 0.5s ease-in-out;
    color: #211915;
    cursor: not-allowed;
    filter: grayscale(1);
  }

  span {
    padding-left: 5px;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 500;
  }

  img {
    max-width: 30px;
  }
`

export default function CardItem({ id = null, egg, userData = null, onSelectEgg = null, onShowBrokenEgg = null }) {
  return (
    <>
      <ItemStyledEgg className="item" onClick={onSelectEgg}>
        <div className="bg-id">{egg?.ratio?.type}</div>
        <img className="bg-item" src="images/inventory/bg-item.png" alt="" />
        <img className="item-img" src="images/inventory/bg-diff.png" alt="" />

        <BoxContentStyled>
          <img className="img-egg" src={`images/egg/egg-${id + 1}.png`} alt="" />

          {/* <BoxButtonStyled>
            <img src="images/inventory/icon-diamond.png" alt="" />
            <span>205</span>
          </BoxButtonStyled> */}

          {userData.allowance === '0' ? (
            <BoxButtonStyled>
              <img src="images/icon/angels-token.png" alt="" />
              <span>Enable</span>
            </BoxButtonStyled>
          ) : (
            <BoxButtonStyled>
              <img src="images/icon/angels-token.png" alt="" />
              <span> {new BigNumber(egg?.angelToBuy).div(1e18).toFixed(0)}</span>
            </BoxButtonStyled>
          )}
        </BoxContentStyled>
      </ItemStyledEgg>
    </>
  )
}
