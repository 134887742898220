import { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { chain, isEmpty } from 'lodash'
import ListItemWithTabs, { CardItem } from 'components2/Common/ListItemWithTabs'
import { Row, Radio, Slider, Checkbox } from 'antd'
import Popup from 'components2/static/GlobalModal'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import useAuth from 'hooks/useAuth'
import { useWeb3React } from '@web3-react/core'
import {
  sortByPrice as sortByPriceAction,
  filterByRank as filterByRankAction,
  filterByClass as filterByClassAction,
  filterByLevel as filterByLevelAction,
  filterByPriceFrom as filterByPriceFromAction,
  filterByPriceTo as filterByPriceToAction,
  filterByOnSale as filterByOnSaleAction,
  filterByToken as filterByTokenAction,
  updateCharacterApproveStat,
  updateFakeCharacterApproveStat,
  resetState,
} from 'state/marketplace'
import {
  fetchingMarket,
  fetchingInventory,
  increaseMarketPage,
  updateApproveToken,
} from 'state/marketplace/actions/actions'
import { fetchUserToken } from 'state/marketplace/actions/tokenActions'
import { fetchGameUserData } from 'state/game'
import { fetchInventoryCharacters } from 'state/marketplace/actions/inventoryActions'
import {
  fetchSellerFee,
  fetchBuyerFee,
  fetchListingCharacters,
  cancelListing,
  purchaseListing,
  fetchApproveCharacterStatus,
} from 'state/marketplace/actions/marketActions'
import { statusOptions, rankValue, currencyValue, options, TABS } from 'config/constants/marketplace'
import {
  fetchingSelector,
  filteredListedCharactersSelector,
  filteredInventoryCharactersSelector,
  filterByRankSelector,
  filterByRangePriceSelector,
  filterByTokenSelector,
  paginationSelector,
  fetchUserAngelsToken,
} from 'state/marketplace/selectors/selector'
import game from 'config/constants/game'
import useRefresh from 'hooks/useRefresh'
import {
  getTokenTransferProxyAddress,
  getNFTsTransferProxyAddress,
  getCharacterAddress,
  getFakeCharacterAddress,
} from 'utils/addressHelpers'
import { useMarketplaceContract, useCharacterContract, useFakeCharacterContract } from 'hooks/useContract'
import { useApproveCallbackCustom } from 'hooks/useApproveCallback'
import { useActiveWeb3React } from 'hooks'

import PopupTransaction from 'components2/Common/Popup/PopupTransition'
import HomePage from 'views/Home/Component/header'
import ConnectWallet from 'components2/Common/Popup/ConnectWallet'
import PopupSell from './components/PopupSell'
import PopupBuy from './components/PopupBuy'
import Styled, { ConnectStyled, PlayGameStyled } from './styled'

export default function Market() {
  const scrollRef = useRef()
  const [activeTabId, setActiveTabId] = useState('TAB_HERO')
  const [isTransaction, setTransaction] = useState(<></>)

  // let HandlerPopupTransaction = <></>
  // useEffect(() => {
  //   HandlerPopupTransaction = <PopupTransaction/>
  //   if(isTransaction) {
  //     setTimeout(() => {
  //       HandlerPopupTransaction = <></>
  //     }, 3000);
  //   }

  // }, [isTransaction])

  const dispatch = useDispatch()
  const { account, chainId } = useActiveWeb3React()
  const marketplaceContract = useMarketplaceContract()
  const { logout } = useAuth()
  const { fastRefresh } = useRefresh()

  const ref = useRef(null)
  const intEntry = useIntersectionObserver(ref, {})
  const isVisible = !!intEntry?.isIntersecting

  const fetchingState = useSelector(fetchingSelector)
  const listedCharacters = useSelector(filteredListedCharactersSelector)
  const inventoryCharacters = useSelector(filteredInventoryCharactersSelector)
  const filterByRank = useSelector(filterByRankSelector)
  const filterByRangePrice = useSelector(filterByRangePriceSelector)
  const filterByToken = useSelector(filterByTokenSelector)
  const pagination = useSelector(paginationSelector)
  const userAngelsToken = useSelector(fetchUserAngelsToken)

  const characterContract = useCharacterContract()
  const fakeCharacterContract = useFakeCharacterContract()

  const handleShowPopupConnect = () => {
    if (!account) {
      Popup.show(<ConnectWallet onClose={Popup.hide} />)
    } else {
      logout()
    }
  }

  const handleActiveTab = (activeTab) => {
    if (!account) {
      return Popup.show(<ConnectWallet onClose={Popup.hide} />)
    }

    setActiveTabId(activeTab)
  }

  const handleShowPopupTransaction = useCallback(
    ({ success, tx, message }) => {
      setTransaction(<PopupTransaction success={success} tx={tx} message={message} chainId={chainId} />)
      setTimeout(() => {
        setTransaction(<></>)
      }, 5000)
    },
    [chainId],
  )

  const [onApproveAngel] = useApproveCallbackCustom(game.angelAddresses[chainId], getTokenTransferProxyAddress())
  const handleApprove = async (callback = null, others = []) => {
    if (!account) {
      return Popup.show(<ConnectWallet onClose={Popup.hide} />)
    }

    try {
      const tx = await onApproveAngel()
      await tx?.wait()

      if (callback) callback({ success: true, tx: tx?.hash, message: 'Approve Success' })

      dispatch(updateApproveToken(true))

      if (others.length > 0) {
        others.map((functionCall) => functionCall())
      }
    } catch (error) {
      dispatch(updateApproveToken(false))

      callback({ success: false, tx: null, message: 'Approve Fail' })
      if (others.length > 0) {
        others.map((functionCall) => functionCall())
      }
    }
  }

  const setApproveForAllCharacter = async (nftAddress, callback = null, others = []) => {
    if (!account) {
      return Popup.show(<ConnectWallet onClose={Popup.hide} />)
    }

    let tx
    try {
      if (nftAddress === getCharacterAddress()) {
        tx = await characterContract?.setApprovalForAll(getNFTsTransferProxyAddress(), true)
        await tx.wait()
        dispatch(updateCharacterApproveStat(true))
      } else if (nftAddress === getFakeCharacterAddress()) {
        tx = await fakeCharacterContract?.setApprovalForAll(getNFTsTransferProxyAddress(), true)
        await tx.wait()
        dispatch(updateFakeCharacterApproveStat(true))
      }

      if (callback) callback({ success: true, tx: tx?.hash, message: 'Approve Character Success' })
      if (others.length > 0) {
        others.map((functionCall) => functionCall())
      }
    } catch (error) {
      callback({ success: false, tx: null, message: 'Approve Character Fail' })
      if (others.length > 0) {
        others.map((functionCall) => functionCall())
      }
    }
  }

  const handleShowPopup = (data, callback = null, others = []) => {
    if (!account) {
      return Popup.show(<ConnectWallet onClose={Popup.hide} />)
    }

    if (activeTabId === TABS[1].id)
      return Popup.show(
        <PopupSell
          data={data}
          closePopup={Popup.hide}
          callback={callback}
          others={others}
          handleCancel={() => handleCancel(data, handleShowPopupTransaction, null)}
        />,
        {
          onPressBackground: null,
        },
      )
    if (activeTabId === TABS[0].id)
      return Popup.show(
        <PopupBuy
          closePopup={Popup.hide}
          data={data}
          handleApprove={() => handleApprove(handleShowPopupTransaction)}
          handleBuy={() => handleBuy(data, handleShowPopupTransaction, null)}
          handleCancel={() => handleCancel(data, handleShowPopupTransaction, null)}
        />,
      )
  }

  const handleCancel = (data, callback = null, others = []) => {
    if (!account) {
      return Popup.show(<ConnectWallet onClose={Popup.hide} />)
    }
    dispatch(
      cancelListing({
        listingNft: data.listingNft,
        id: data.id,
        options: { contract: marketplaceContract, callback, others },
      }),
    )
  }

  const handleBuy = (data, callback = null, others = []) => {
    if (!account) {
      return Popup.show(<ConnectWallet onClose={Popup.hide} />)
    }

    dispatch(
      purchaseListing({
        listingNft: data.listingNft,
        id: data.id,
        price: data.price,
        paymentToken: data.paymentToken,
        options: { contract: marketplaceContract, callback, others },
      }),
    )
    // console.log('dispatch')
    // dispatch(fetchUserToken(account))
  }

  const handleFilter = (key, value) => {
    switch (key) {
      case 'sort':
        dispatch(sortByPriceAction(value))
        break
      case 'rank':
        dispatch(filterByRankAction(value))
        break
      case 'race':
        dispatch(filterByClassAction(value))
        break
      case 'level':
        dispatch(filterByLevelAction(value))
        break
      case 'from':
        dispatch(filterByPriceFromAction(parseFloat(value)))
        break
      case 'to':
        dispatch(filterByPriceToAction(parseFloat(value)))
        break
      case 'currency':
        dispatch(filterByTokenAction(value))
        break
      //dispatch(filterByTokenAction());
      case 'status':
        dispatch(filterByOnSaleAction(value))
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (account && account !== undefined && chainId) {
      dispatch(resetState())

      dispatch(fetchUserToken(account))
      dispatch(fetchSellerFee())
      dispatch(fetchBuyerFee())
      dispatch(fetchApproveCharacterStatus(account))
    }
  }, [account, chainId])

  useEffect(() => {
    if (account && account !== undefined && chainId) {
      if (isVisible) {
        if (activeTabId === TABS[0].id) {
          if (listedCharacters.length === (pagination.marketPage + 1) * pagination.limit) {
            dispatch(increaseMarketPage())
          }
          dispatch(fetchingMarket(true))
        } else if (activeTabId === TABS[1].id) {
          dispatch(fetchingInventory(true))
        }
      }
    }
  }, [isVisible, activeTabId, account, chainId])

  useEffect(() => {
    if (chainId) {
      if (fetchingState.market) {
        dispatch(fetchListingCharacters())
      } else if (fetchingState.inventory && account && account !== undefined) {
        dispatch(fetchInventoryCharacters(account))
      }
    }
  }, [fetchingState, account])

  useEffect(() => {
    if (account && account !== undefined && chainId) {
      dispatch(fetchingMarket(true))
    }
  }, [fastRefresh, account])

  return (
    <Styled>
      {/* <div className="top-bar">
        <Back onClick={() => setCurrentPage('home')} />
      </div> */}
      <HomePage angelBalance={userAngelsToken?.balance || '0'} />
      {isTransaction}

      <Row justify="center" className="marketStyled">
        {activeTabId !== 'MY_INVENTORY' ? (
          <>
            <div className="filter-group">
              <div className="section">
                <div className="label">Sort by</div>
                <Radio.Group
                  onChange={(e) => handleFilter('sort', e.target.value === 'INCREASE' ? 1 : -1)}
                  // value={value4}
                  optionType="button"
                  buttonStyle="solid"
                  options={options.map((option) => ({
                    ...option,
                    label: <option.component />,
                  }))}
                />
              </div>

              <div className="section">
                <div className="label">Rank</div>
                <Checkbox.Group
                  onChange={(e) => handleFilter('rank', e)}
                  defaultValue={filterByRank}
                  options={rankValue.map((rank) => ({
                    ...rank,
                    label: <img src={rank.src} alt={rank.alt} />,
                  }))}
                />
              </div>

              {/* <div className="section">
              <div className="label" >Hệ</div>
              <Checkbox.Group 
                onChange={e => handleFilter("race", e)} 
                defaultValue={filterByClass} 
                options={raceValue}
              />
            </div> */}

              <div className="section" ref={scrollRef}>
                <div className="label">Level (1-9)</div>
                <Slider
                  range
                  defaultValue={[1, 9]}
                  min={1}
                  max={9}
                  onChange={(e) => handleFilter('level', e)}
                  tooltipVisible={false}
                />
              </div>

              <div className="section">
                <div className="label">Price</div>
                <Row>
                  <div className="price">
                    <div> From</div>
                    <input
                      type="number"
                      className="input-price"
                      value={filterByRangePrice.from}
                      onChange={(e) => handleFilter('from', e.target.value)}
                    />
                  </div>
                  <div className="price">
                    <div> To</div>
                    <input
                      type="number"
                      className="input-price"
                      value={filterByRangePrice.to}
                      onChange={(e) => handleFilter('to', e.target.value)}
                    />
                  </div>
                </Row>
              </div>

              <div className="section">
                <div className="label">Currency</div>
                <div className="checkbox__price">
                  <Checkbox.Group
                    onChange={(e) => handleFilter('currency', e)}
                    options={
                      chainId
                        ? currencyValue[chainId].map((currency) => ({
                            ...currency,
                            label: <img src={currency.src} alt={currency.alt} />,
                          }))
                        : []
                    }
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="filter-group">
            <div className="section">
              <div className="label">Status</div>
              <Checkbox.Group
                onChange={(e) => handleFilter('status', e.length > 0 && e[0] === 'ON_SALE')}
                defaultValue={[]}
                options={statusOptions}
              />
            </div>
            {/* <div className="section">
              <div className="label" >Type</div>
              <Checkbox.Group onChange={e => handleFilter("type", e)} defaultValue={[]}  options={typeOptions}/>
            </div> */}
          </div>
        )}

        <div className="group">
          <ListItemWithTabs
            tabs={TABS}
            onClickTab={(tab) => handleActiveTab(tab.id)}
            activeTabId={activeTabId}
            // filter={FilterInventory}
            market
          >
            {activeTabId === TABS[0].id && !isEmpty(listedCharacters)
              ? listedCharacters.map((item, index) => {
                  return (
                    <CardItem
                      key={index}
                      handleShowPopup={(callbacks) => handleShowPopup(item, handleShowPopupTransaction, callbacks)}
                      handleCancel={(callbacks) => handleCancel(item, handleShowPopupTransaction, callbacks)}
                      handleBuy={(callbacks) => handleBuy(item, handleShowPopupTransaction, callbacks)}
                      handleApprove={(callbacks) => handleApprove(handleShowPopupTransaction, callbacks)}
                      data={item}
                      activeTabId={activeTabId}
                    />
                  )
                })
              : activeTabId === TABS[1].id && !isEmpty(inventoryCharacters)
              ? inventoryCharacters.map((item, index) => {
                  return (
                    <CardItem
                      key={index}
                      handleShowPopup={(callbacks) => handleShowPopup(item, handleShowPopupTransaction, callbacks)}
                      handleCancel={(callbacks) => handleCancel(item, handleShowPopupTransaction, callbacks)}
                      handleApprove={(callbacks) => handleApprove(handleShowPopupTransaction, callbacks)}
                      data={item}
                      activeTabId={activeTabId}
                      handleApproveCharacter={(callbacks) =>
                        setApproveForAllCharacter(item.nftAddress, handleShowPopupTransaction, callbacks)
                      }
                    />
                  )
                })
              : null}

            <div className="items-loadmore" ref={ref}>
              {(activeTabId === TABS[0].id && fetchingState.market) ||
              (activeTabId === TABS[1].id && fetchingState.inventory) ? (
                <span>Loading more</span>
              ) : (isVisible && activeTabId === TABS[0].id && !fetchingState.market) ||
                (isVisible && activeTabId === TABS[1].id && !fetchingState.inventory) ? (
                <span></span>
              ) : (
                ''
              )}
            </div>
          </ListItemWithTabs>
        </div>
      </Row>

      <ConnectStyled>
        <div className="Connect__connect" onClick={handleShowPopupConnect}>
          <img src="./images/connect/connect-wallet.png" alt="Connectwallet" />
          <p>{account ? 'Logout' : 'Connect Wallet'}</p>
        </div>
      </ConnectStyled>

      <PlayGameStyled path="/">
        <Link to="/">
          <img src="./images/play-game.png" alt="play game" />
          <p>Play Game</p>
        </Link>
      </PlayGameStyled>
    </Styled>
  )
}
