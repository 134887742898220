import styled from 'styled-components'

function Back({ onClick }) {
  return (
    <BackStyled onClick={onClick}>
      <img src="./images/pve/back-button.png" alt="Back.png" />
    </BackStyled>
  )
}

export const BackStyled = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99;

  img {
    width: 100%;
    transition: ease all 0.5s;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
`
export default Back
