import { EggMarketConfig } from './types'

const eggs: EggMarketConfig[] = [
  {
    type: 'Soil',
    n: '62',
    r: '32',
    sr: '5',
    ssr: '1',
  },
  {
    type: 'Rock',
    n: '49',
    r: '42',
    sr: '7',
    ssr: '2',
  },
  {
    type: 'Glass',
    n: '40',
    r: '40',
    sr: '16',
    ssr: '4',
  },
  {
    type: 'Copper',
    n: '42',
    r: '36',
    sr: '21',
    ssr: '5',
  },
  {
    type: 'Silver',
    n: '29',
    r: '29',
    sr: '30',
    ssr: '12',
  },
  {
    type: 'Gold',
    n: '10',
    r: '23',
    sr: '42',
    ssr: '25',
  },
]

export default eggs
