import styled from 'styled-components'

const Styled = styled.div`
  width: 100%;
  height: 100%;
  background-image: url('/images/inventory/bg_2.png');

  .box-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .top-bar {
    display: flex;
  }

  .mint-worker {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    width: 300px;
    margin: 0px 30px;
    text-align: right;
    height: 80px;
    padding: 20px 0px;
    color: #ffc165;
  }
  .button {
    font-weight: bold;
    font-size: 40px;
    line-height: 49px;
    text-align: center;
    color: #43261c;
    display: flex;
    align-items: center;
    padding: 1rem 3rem;

    img {
      margin-right: 20px;
    }
  }

  .group {
    display: flex;
    position: relative;
    z-index: 2;
    padding: 110px 40px 40px;

    .inventory {
      margin-top: 100px;
      width: 1188px;

      .tab-pen {
        display: flex;
        align-items: flex-end;

        .tab {
          background-image: url('/images/inventory/bg-tab-pen.png');
          background-repeat: no-repeat;
          height: 78px;
          width: 212px;
          margin-right: 10px;
          font-weight: bold;
          font-size: 32px;
          line-height: 39px;
          text-align: center;
          color: #ffc165;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          padding: 22px;
          padding-left: 10px;

          &.active {
            height: 85px;
          }
        }
      }

      .wrapper-list-inventory {
        background-image: url('/images/inventory/bg-list-item.png');
        background-repeat: no-repeat;
        padding: 46px 40px;
        width: 1017px;

        .no-item {
          width: 100%;
          height: 100%;
        }

        .list-item {
          height: 700px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          overflow-y: auto;

          ::-webkit-scrollbar {
            width: 14px;
            background-color: #f5f5f5;
            border-radius: 5px;
          }
          ::-webkit-scrollbar-thumb {
            background: #f08005;
            border-radius: 5px;
          }
          ::-webkit-scrollbar-track {
            background-color: #935743;
          }
        }
        .item {
          background-image: url('/images/inventory/bg-item.png');
          background-repeat: no-repeat;
          background-size: contain;
          width: 215px;
          height: 284px;
          margin: 12px 28px;
          display: flex;
          position: relative;

          .item-img {
            margin: auto;
            margin-top: 40px;
          }

          .property {
            position: absolute;
            top: 25px;
            right: 15px;
          }

          .bg-id {
            background-image: url('/images/inventory/bg-id.png');
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
            width: 145px;
            height: 31px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            color: #1b0000;
          }
        }
      }
    }

    .review {
      width: 600px;
      height: 880px;
      position: relative;
      margin-left: 40px;

      .action {
        position: absolute;
        right: 6px;
        top: -40px;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        width: 108px;
        height: auto;

        img {
          width: 100%;
          cursor: pointer;
          transition: ease all 0.5s;

          &:hover {
            opacity: 0.8;
          }
        }

        /* .sell {
          margin-top: 120px;
        } */
      }

      img.item-review {
        top: 88px;
        left: 259px;
        width: 594px;
        margin: auto;
        position: absolute;
        transform: translateX(-50%);
      }

      .group-info {
        padding: 0 20px;
        position: absolute;
        left: -74px;
        bottom: -63px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: #0d0d0dbf;

        .main-info {
          width: 100%;
          display: flex;
          justify-content: space-between;
          position: relative;

          ::before {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            height: 2px;
            width: 100%;
            background-image: linear-gradient(90deg, transparent, #fafafa, transparent);
          }

          .level {
            font-weight: 500;
            font-size: 34px;
            line-height: 60px;
            color: #ffffff;
          }

          .user-name {
            font-style: italic;
            font-weight: normal;
            font-size: 3em;
            font-weight: 600;
            line-height: 60px;
            letter-spacing: 0.1em;
            color: #ffffff;
            margin: 0 auto;
            text-transform: uppercase;
          }
        }

        .info {
          width: 230px;
          left: 1327px;
          top: 0;
          border-radius: 3px;
          display: grid;
          grid-template-columns: 20px 1fr 1fr;
          grid-gap: 30px;
          align-items: center;
          padding: 0 10px;

          .icon {
            img {
              width: 30px;
            }
          }

          .name {
            font-weight: 300;
            font-size: 20px;
            line-height: 60px;
            text-transform: capitalize;
            color: #ffc165;
          }

          .detail {
            font-weight: 300;
            font-size: 20px;
            line-height: 60px;
            text-transform: capitalize;
            color: #ffffff;
          }
        }
      }
    }
  }
`

export default Styled
