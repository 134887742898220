import React from 'react'
import Button from 'components2/Common/Button'

export default function Note({ handleShowNote }) {
  return (
    <div className="ground active">
      <div className="ground__level">
        <div className="ground__level--experience">
          <h3>note</h3>
        </div>

        <div className="ground__content">
          <p className="success__content">
            Success is possible when upgrading, and the hero&apos;s NFT will disappear after the upgrade fails.
          </p>
          <div className="button-note">
            <Button onClick={handleShowNote}>Cancel</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
