import { ChainId } from '@diviner-protocol/v2-sdk'
import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'
import { getChainId } from './web3React'

// TODO : Improve
export const getAddress = (address: Address, chainId?: ChainId): string => {
  const tempChainId = getChainId()
  // const mainNetChainId = 56
  const envChainId = process.env.REACT_APP_CHAIN_ID
  return address[tempChainId] ?? address[envChainId]
}

export const getCakeAddress = (chainId?: ChainId) => {
  return getAddress(tokens.cake.address, chainId)
}

export const getEggMarketAddress = (chainId?: ChainId) => {
  return getAddress(addresses.eggMarket, chainId)
}

export const getFakeCharacterAddress = (chainId?: ChainId) => {
  return getAddress(addresses.fakeCharacter, chainId)
}

export const getGameAddress = (chainId?: ChainId) => {
  return getAddress(addresses.game, chainId)
}

export const getCharacterAddress = (chainId?: ChainId) => {
  return getAddress(addresses.character, chainId)
}

export const getEquipmentAddress = (chainId?: ChainId) => {
  return getAddress(addresses.equipment, chainId)
}

export const getGameWikiAddress = (chainId?: ChainId) => {
  return getAddress(addresses.gameWiki, chainId)
}

export const getOracleAddress = (chainId?: ChainId) => {
  return getAddress(addresses.priceOracle, chainId)
}

export const getFaucetAddress = (chainId?: ChainId) => {
  return getAddress(addresses.faucet, chainId)
}

export const getMulticallAddress = (chainId?: ChainId) => {
  return getAddress(addresses.multiCall, chainId)
}

export const getWbnbAddress = (chainId?: ChainId) => {
  return getAddress(tokens.wbnb.address, chainId)
}

export const getMarketplaceAddress = (chainId?: ChainId) => {
  return getAddress(addresses.market, chainId)
}

export const getTokenTransferProxyAddress = (chainId?: ChainId) => {
  return getAddress(addresses.tokenTransferProxy, chainId)
}

export const getNFTsTransferProxyAddress = (chainId?: ChainId) => {
  return getAddress(addresses.nftsTransferProxy, chainId)
}

export const getPoolAddress = (chainId?: ChainId) => {
  return getAddress(addresses.pool, chainId)
}

export const getMysteryBoxAddress = (chainId?: ChainId) => {
  return getAddress(addresses.mysteryBox, chainId)
}
