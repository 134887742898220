import { useWeb3React } from '@web3-react/core'
import Mask from 'components2/Common/EggAnimationMask'
import PopupCongratulation from 'components2/Common/Popup/PopupCreateNewHero'
import GlobalModal from 'components2/static/GlobalModal'
import game from 'config/constants/game'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { useApproveCallbackCustom } from 'hooks/useApproveCallback'
import { useCreateNewHero } from 'hooks/useGame'
import React, { useState } from 'react'
import { useAppDispatch } from 'state'
import { fetchUserTokenData, fetchUserCharacters } from 'state/game'
import { useGamePrice, useGameUserData } from 'state/hooks'
import styled from 'styled-components'
import { getGameAddress } from 'utils/addressHelpers'
import CardValue from 'components/CardValue'
import PopupNotice from 'components2/Common/Popup/PopupNotice'
import Button from 'components2/Common/Button'

const busdBalanceMessageError = 'not enough busd to create hero'
const angelBalanceMessageErrorMessageError = 'not enough angel to create hero'
const messageError = 'Out of egg with type'

function errorMessage(text) {
  switch (true) {
    case !text:
      return 'Error'
    case text.includes(busdBalanceMessageError):
      return "You don't have enough Busd."
    case text.includes(angelBalanceMessageErrorMessageError):
      return "You don't have enough Angel."
    case text.includes(messageError):
      return 'Out of stock.'
    default:
      return 'Error'
  }
}

export default function Recruit() {
  const [isApproving, setIsApproving] = useState<boolean>(false)
  const [isCreating, setIsCreating] = useState<boolean>(false)
  const { account, chainId } = useWeb3React()
  const dispatch = useAppDispatch()
  const { userTokenData } = useGameUserData()
  const { createNewHeroPrice } = useGamePrice()

  // const [isDepositing, setIsDepositing] = useState<boolean>(false)

  const [onApproveAngel] = useApproveCallbackCustom(game.angelAddresses[chainId], getGameAddress())
  const [onApproveBusd] = useApproveCallbackCustom(game.busdAddresses[chainId], getGameAddress())

  const { onCreateNewHero } = useCreateNewHero()

  const handleApprove = async (tokenName: string) => {
    setIsApproving(true)
    try {
      let tx
      if (tokenName === 'angel') {
        tx = await onApproveAngel()
      } else {
        tx = await onApproveBusd()
      }
      await tx?.wait()
      dispatch(fetchUserTokenData(account))
      setIsApproving(false)
    } catch (error) {
      setIsApproving(false)
    }
  }

  const handleCreateNewHero = async () => {
    try {
      setIsCreating(true)
      const tx = await onCreateNewHero()

      GlobalModal.show(<Mask eggType={5} />)

      const results = await tx?.wait()

      dispatch(fetchUserCharacters(account))

      GlobalModal.hide()

      GlobalModal.show(<PopupCongratulation onClose={GlobalModal.hide} newHero={results.events[5].args} />)

      setIsCreating(false)
    } catch (error: any) {
      setIsCreating(false)
      const errorText = error?.data?.message ?? error.toString()
      if (errorText.includes(angelBalanceMessageErrorMessageError)) {
        GlobalModal.show(<PopupNotice onClose={GlobalModal.hide} textNotice="You don't have enough balance." />)
        return
      }
      GlobalModal.show(<PopupNotice onClose={GlobalModal.hide} textNotice={errorMessage(errorText)} />)
    }
  }

  const secondCoinImage =
    chainId === 137 ? (
      <img src="images/icon/usdc-coin.png" alt="usdc-token.png" />
    ) : (
      <img src="images/inventory/price-busd.svg" alt="busd-token.png" />
    )

  return (
    <ItemStyled className="item">
      <div className="bg-id">Recruit</div>
      <img className="bg-item" src="images/inventory/bg-item.png" alt="" />
      <img className="item-img" src="images/inventory/bg-diff.png" alt="" />
      <div className="recruit__img">
        <img className="img-egg" src="images/inventory/egg.png" alt="" />
      </div>
      <BoxContentStyled>
        {userTokenData.angelAllowance === '0' ? (
          <BoxButtonStyled>
            <Button isLoading={isApproving} onClick={() => handleApprove('angel')}>
              <img src="/images/icon/angels-token.png" alt="angels-token.png" />
              <span>Enable</span>
            </Button>
          </BoxButtonStyled>
        ) : userTokenData.busdAllowance === '0' ? (
          <BoxButtonStyled>
            <Button isLoading={isApproving} onClick={() => handleApprove('busd')}>
              {secondCoinImage}
              <span>Enable</span>
            </Button>
          </BoxButtonStyled>
        ) : (
          <div className="buttonBuy">
            <div className="buttonBuy__price">
              <div className="buttonBuy__price--icon">
                <img src="images/icon/angels-token.png" alt="angels-token.png" />
                <p>
                  <CardValue
                    value={parseFloat(formatEther(BigNumber.from(createNewHeroPrice.angel))) || 0}
                    decimals={0}
                  ></CardValue>
                </p>
              </div>
              <div className="buttonBuy__price--icon">
                {secondCoinImage}
                {/* <img src="images/inventory/price-busd.svg" alt="price-busd.svg" /> */}
                <p>
                  <CardValue
                    value={parseFloat(formatEther(BigNumber.from(createNewHeroPrice.busd))) || 0}
                    decimals={0}
                  ></CardValue>
                </p>
              </div>
            </div>
            <BoxButtonStyled>
              <Button isLoading={isCreating} onClick={handleCreateNewHero}>
                <span>Buy</span>
              </Button>
            </BoxButtonStyled>
          </div>
        )}
      </BoxContentStyled>
    </ItemStyled>
  )
}

const ItemStyled = styled.div`
  background-image: url('/images/inventory/bg-item.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 204px;
  height: 284px;
  display: flex;
  position: relative;

  .price {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100px;
    background-image: url(/images/inventory/bg_button.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3px;
    cursor: pointer;
  }

  .bg-id {
    position: absolute;
    top: 15px;
    left: 54%;
    z-index: 4;
    transform: translateX(-50%);
    width: 145px;
    font-style: normal;
    font-weight: 900;
    font-size: 1.7em;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-family: 'Hind', sans-serif;
    text-shadow: 0px 0px 1.99024px #ffffff;
  }

  .bg-item {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }

  .item-img {
    margin: auto;
    margin-top: 47px;
    margin-left: 16px;
  }

  .property {
    position: absolute;
    top: 55px;
    right: 15px;
    z-index: 4;
  }

  .recruit__img {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);

    .img-egg {
      width: 70px;
      margin-bottom: 10px;
    }
  }
`
const BoxContentStyled = styled.div`
  position: absolute;
  bottom: 15px;
  left: 53%;
  transform: translateX(-50%);
  z-index: 4;
  display: flex;
  flex-direction: column;
  align-items: center;

  .angel-token {
    width: 33px;
  }
  .buttonBuy {
    .buttonBuy__price {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 0px;
      padding-bottom: 20px;
      .buttonBuy__price--icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 5px;
        p {
          font-size: 16px;
        }
        img {
          width: 25px;
          margin-right: 5px;
        }
      }
    }
    p {
      font-weight: 500;
      font-size: 20px;
      margin: 0;
      color: #fff;
    }
  }
`

const BoxButtonStyled = styled.div`
  /* background: url('images/inventory/bg-btn.png') no-repeat; */
  width: 144px;
  height: 44px;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;

  .button-upgrade {
    width: 164px;
    height: 82px;
  }

  img {
    width: 30px;
  }

  &:hover {
    opacity: 0.7;
    transition: 0.5s ease-in-out;
  }

  span {
    /* color: #ffffff; */
    text-transform: capitalize;
    font-weight: 500;
    font-size: 20px;
  }
`
