import { BigNumber } from 'bignumber.js'
import predictionABI from 'config/abi/prediction.json'
import { getGameAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'
import { getChainId } from 'utils/web3React'
import { enemiesConfig } from 'config/constants/game'
import { getCharacterContract, getGameWikiContract } from 'utils/contractHelpers'

const fetchEnemies = async () => {
  const gameWikiContract = getGameWikiContract()
  const enemies = await gameWikiContract.getFullEnemies(0, 20) // Todo

  return [
    ...enemies.map((enemy, index) => {
      return {
        attack: enemy.attack.toString(),
        defense: enemy.defense.toString(),
        gemRewards: enemy.gemRewards.toString(),
        requiredLevel: enemy.requiredLevel.toString(),
        ...enemiesConfig[index],
      }
    }),
  ]
}

export default fetchEnemies
