import { useWeb3React } from '@web3-react/core'
import Back from 'components2/Common/Back/index'
import Button from 'components2/Common/Button'
import BigNumber from 'bignumber.js'
import PopupNotice from 'components2/Common/Popup/PopupNotice'
import Popup from 'components2/static/GlobalModal'
import { ethers } from 'ethers'
import { useFight } from 'hooks/useGame'
import React, { useCallback, useState, useEffect } from 'react'
import Slider from 'react-slick'
import PopupUpgrade from 'components2/Common/Popup/PopupUpgrade/index'
import { useAppDispatch } from 'state'

import {
  fetchCharacterWithTokenId,
  fetchUserGameInfo,
  setActiveRoundId,
  setEnergy,
  setFightResult,
  setFightStatus,
} from 'state/game'
import { useActiveCharacterId, useGameEnemies, useGameUserData, useIsLoading, useSelectedRound } from 'state/hooks'
import ConvertGem from 'components2/Common/Popup/ConvertGem'

import { useBuyEnergy } from '../../hooks/useGame'
import { useGamePrice } from '../../state/hooks'
import Character from './components/Character'
import CharaterHero from './components/CharaterHero'
import CharacterHeroEXP from './components/CharacterHeroEXP'
import CharacterMonster from './components/CharacterMonster'
import { AngelsStyled } from './Styled'

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 1,
}

const balanceMessageError = 'not enough balance create hero'
const notEnoughGemMessageError = 'not enough gem to fight'
const outOfEnergyMessageError = 'not enough energy'
const requireLevelMessageError = 'You need to unlock the level'
const messageError = 'Out of egg with type'

function errorMessage(text) {
  switch (true) {
    case !text:
      return 'Error'
    case text.includes(balanceMessageError):
      return "You don't have enough balance."
    case text.includes(notEnoughGemMessageError):
      return "You don't have enough Gem."
    case text.includes(outOfEnergyMessageError):
      return 'Out of energy.'
    case text.includes(messageError):
      return 'Out of stock.'
    case text.includes(requireLevelMessageError):
      return 'Your character need Upgrade.'
    default:
      return 'Error'
  }
}

function AngelsPVE({ setCurrentPage }) {
  const [isFighting, setIsFighting] = useState<boolean>(false)
  const [isPendingTx, setIsPendingTx] = useState<boolean>(false)
  const [timesAutoFight, setTimesAutoFight] = useState<number>(1)
  const [successChance, setSuccessChance] = useState<number>(0)
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()
  const characterId = useActiveCharacterId()
  const selectedRound = useSelectedRound()

  const isLoading = useIsLoading()
  const { characters, userGameData } = useGameUserData()
  const selectedChar = characters[characterId]

  const enemies = useGameEnemies()
  const selectedEnemy = enemies[selectedRound]

  const { onFight } = useFight(selectedRound === 9 && userGameData.gemReward === '0')
  const { onBuyEnergy } = useBuyEnergy()

  const { fightPrice } = useGamePrice()

  useEffect(() => {
    const damageHero = parseInt(selectedChar.attack) - parseInt(selectedEnemy.defense)
    const damageEnemy = parseInt(selectedEnemy.attack) - parseInt(selectedChar.defense)
    const chance =
      damageHero - damageEnemy > 0 ? 50 + (damageHero - damageEnemy) / 1.5 : 50 + (damageHero - damageEnemy)
    setSuccessChance(() => {
      if (chance < 0) return 5
      return chance > 100 ? 100 : chance
    })
  }, [characterId, characters, selectedRound])

  const handleFight = useCallback(async () => {
    try {
      setIsFighting(true)
      const tx = await onFight(selectedChar.tokenId.toString(), selectedRound.toString(), timesAutoFight)

      dispatch(setFightStatus(0))
      setCurrentPage('fight')

      const result = await tx.wait()

      const { gemRewards } = result.events[0].args

      const totalGemReward = gemRewards.reduce((total, cur) => total.add(cur), ethers.BigNumber.from('0'))

      dispatch(
        setFightResult({
          angelReward: '0',
          gemReward: totalGemReward.toString(),
        }),
      )
      dispatch(setFightStatus(2))

      setIsFighting(false)

      dispatch(fetchCharacterWithTokenId(selectedChar.tokenId))
      dispatch(fetchUserGameInfo(account))
    } catch (error: any) {
      setIsFighting(false)
      const errorText = error?.data?.message ?? error.toString()

      if (errorText.includes(requireLevelMessageError)) {
        Popup.show(
          <PopupNotice
            onClose={Popup.hide}
            textNotice="Your character need Upgrade."
            onClick={() => Popup.show(<PopupUpgrade closePopup={Popup.hide} heroIndex={characterId} />)}
            buttonTitle="Upgrade"
          />,
        )
        return
      }

      if (errorText.includes(outOfEnergyMessageError)) {
        Popup.show(
          <PopupNotice
            lastFightTime={parseInt(selectedChar.lastFightTime)}
            onClose={Popup.hide}
            textNotice="Out of energy."
          />,
        )

        return
      }

      if (errorText.includes(notEnoughGemMessageError)) {
        Popup.show(
          <PopupNotice
            onClose={Popup.hide}
            textNotice="Not enough Gem."
            onClick={() => Popup.show(<ConvertGem />)}
            buttonTitle="Convert"
          />,
        )
        return
      }

      Popup.show(<PopupNotice onClose={Popup.hide} textNotice="Error" />)
      setCurrentPage('angelspve')
    }
  }, [selectedChar, characterId, characters, onFight])

  const handleSelectCharacter = (index: number) => {
    dispatch(setActiveRoundId(index))
  }

  const handleBuyEnergy = useCallback(async () => {
    try {
      setIsPendingTx(true)
      const tx = await onBuyEnergy(selectedChar.tokenId.toString())

      await tx.wait()

      dispatch(
        setEnergy({
          tokenId: selectedChar.tokenId,
          newEnergy: '5',
        }),
      )
    } catch (error: any) {
      // Popup.show(<PopupNotice onClose={Popup.hide} textNotice={errorMessage(errorText)} />)
    } finally {
      setIsPendingTx(false)
    }
  }, [selectedChar, onBuyEnergy])

  const handleBackMapGame = useCallback(async () => {
    if (selectedRound < 10) setCurrentPage('mapgame')
    else setCurrentPage('mapgametwo')
  }, [])

  return (
    <AngelsStyled>
      {isLoading ? (
        <div></div>
      ) : (
        <div className="container">
          <Back onClick={handleBackMapGame} />
          <div className="angelspve">
            <CharaterHero lv={selectedChar?.level} animateImg={selectedChar?.animateImg} hero={selectedChar?.name}>
              <CharacterHeroEXP
                erj={selectedChar?.energy}
                attack={selectedChar?.attack}
                def={selectedChar?.defense}
                speed={selectedChar?.speed}
              />
            </CharaterHero>
            <div className="angelspve__vs">
              <div className="angelspve__vs--video">
                <video autoPlay playsInline loop muted>
                  <source src="/images/pve/vs-punch.webm" type="video/webm" />
                  <source src="/images/pve/vs-punch.mov" type="video/quicktime" />
                </video>
              </div>

              <div className="show__gem">
                <img src="./images/icon/gem-token.png" alt="icon gem" />
                <span>{parseInt(ethers.utils.formatEther(fightPrice[selectedRound])) * timesAutoFight}</span>
              </div>

              <div className="angelspve__vs--svg">
                <Button isLoading={isFighting || characters.length === 0} onClick={handleFight}>
                  Fight
                </Button>
              </div>

              <div className="box__boost">
                <div className="box__tooltip">
                  <p>
                    We have estimate gas price and refund 0.001 BNB per fight. Please do not modify gas price or the
                    transaction may fail.
                  </p>
                  <img src="images/icon/icon-gass.png" alt="icon gas" />
                </div>

                <h3 className="box__boost__title">auto fight</h3>

                <div className="box__boost__item">
                  {[1, 2, 3, 4, 5].map((item) => (
                    <div
                      className={`item ${timesAutoFight === item ? 'active' : ''}`}
                      onClick={() => setTimesAutoFight(item)}
                    >
                      x{item}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="angelspve__green-monster">
              <CharaterHero
                lv={enemies[selectedRound]?.requiredLevel}
                animateImg={enemies[selectedRound].img}
                hero={enemies[selectedRound].name}
              >
                <CharacterMonster
                  successChance={successChance}
                  defense={enemies[selectedRound]?.defense}
                  attack={enemies[selectedRound]?.attack}
                  price={new BigNumber(enemies[selectedRound].gemRewards).div(1e18).toNumber()}
                />
              </CharaterHero>
            </div>
          </div>
          <div className="angelspve__slick">
            <Slider {...settings}>
              {characters.map((character, index) => (
                <Character
                  onClick={() => handleSelectCharacter(index)}
                  key={index}
                  thumbImg={character.thumbImg}
                  lv={character.level}
                  className={characterId === index ? 'character active' : 'character '}
                />
              ))}
            </Slider>
          </div>
        </div>
      )}
    </AngelsStyled>
  )
}

export default AngelsPVE
