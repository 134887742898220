import React, { useState } from 'react'
import styled from 'styled-components'
import { BLOCK_EXPLORER_URLS, SCAN_SITES } from 'constants/index'

function PopupTransition({ success, tx, message, chainId }) {
  return (
    <PopupTransitionStyled>
      <div className="transition__content">
        <div className="transition__content--img">
          {!success ? (
            <img src="/images/connect/fail.svg" alt="fail.svg" />
          ) : (
            <img src="/images/connect/success.svg" alt="success.svg" />
          )}

          <h3 className="transition__title">{message}</h3>
        </div>

        {tx ? (
          <a className="transition__link" href={`${BLOCK_EXPLORER_URLS[chainId]}/tx/${tx}`} target="blank">
            View on {SCAN_SITES[chainId]}
          </a>
        ) : null}
      </div>
    </PopupTransitionStyled>
  )
}

const PopupTransitionStyled = styled.div`
  background: url('./images/connect/popup-transition.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  right: 30px;
  top: 30px;
  width: 600px;
  height: 150px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .transition__title {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
    padding: 1rem 0px;
  }
  .transition__link {
    font-size: 22px;
    font-weight: 400;
    color: #70aeff;
    text-decoration: underline;
  }
  .transition__content--img {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 40px;
      margin-right: 10px;
    }
    img.close-icon {
      position: absolute;
      right: -24px;
      top: -24px;
      cursor: pointer;
      width: 65px;
    }
  }
`
export default PopupTransition
