import styled from 'styled-components'

export const FightSceneStyled = styled.div`
  position: relative;
  padding-top: 175px;
  background: url('./images/pve/bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;

  .main-character {
    position: absolute;
    display: flex;
    justify-content: center;
    position: absolute;
    width: 286px;
    height: 536px;
    top: 252px;

    &.left {
      left: 188px;
      transform: scaleX(-1);
    }

    &.right {
      right: 188px;
    }

    &.overlay {
      top: 10px;
      z-index: 5;
      width: 572px;
      height: 1072px;
      img {
        height: 100%;
      }
    }
  }

  .slick-prev {
    top: 40%;
    &:before {
      content: url('./images/pve/SlickLeft.svg');
    }
  }

  .slick-next {
    top: 40%;
    &:before {
      content: url('./images/pve/SlickRight.svg');
    }
  }
`

export const SlickContainerStyled = styled.div`
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;

  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%);
`
