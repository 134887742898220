import styled from 'styled-components'
import { useActiveCharacter, useFightResult, useFightStatus, useSelectedRound } from 'state/hooks'

const FightingCloudImg = styled.img`
  animation: bouncing-animation;
  animation-duration: 6s;
  animation-fill-mode: forwards;
  animation-iteration-count: 6;
  z-index: 3;

  @keyframes bouncing-animation {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5);
    }
    10% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    20% {
      transform: translate(-50%, -50%) scale(0.5);
    }
    30% {
      transform: translate(-50%, -50%) scale(1);
    }
    40% {
      transform: translate(-50%, -50%) scale(0.5);
    }
    50% {
      transform: translate(-50%, -50%) scale(1);
    }
    60% {
      transform: translate(-50%, -50%) scale(0.5);
    }
    70% {
      transform: translate(-50%, -50%) scale(1);
    }
    80% {
      transform: translate(-50%, -50%) scale(0.5);
    }
    90% {
      transform: translate(-50%, -50%) scale(1.5);
    }
    100% {
      transform: translate(-50%, -50%) scale(0.5);
    }
  }

  .scale-custom {
    transform: translate(-50%, -50%) scale(0);
    transition: 0.5s ease-out;
  }
`

const FightingCloud = (props) => {
  const fightStatus = useFightStatus()

  return (
    <FightingCloudImg
      src="/images/pve/fight-cloud.gif"
      alt=""
      className={`${fightStatus === 2 && 'scale-custom'}`}
      style={{ height: 1400, top: '45%', left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)' }}
      {...props}
    />
  )
}

export default FightingCloud
