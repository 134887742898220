import React from 'react'
import Button from 'components2/Common/Button'
import { useGameUserData, useGamePrice } from 'state/hooks'
import CardValue from 'components/CardValue'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'

export default function CharacterInfo({
  handleShowNote,
  handleApprove,
  selectedCharacter,
  isUpgrading,
  isApproving,
  handleUpgrade,
}) {
  const { characters, userTokenData } = useGameUserData()
  const { unlockLevelPrice } = useGamePrice()
  const level = parseInt(characters[selectedCharacter]?.level)

  return (
    <div className="ground">
      <div className="ground__level">
        <div className="ground__level--experience">
          <>
            <h3>Lv.{level || ''}</h3>
            <img src="/images/popup/lv-up.png" alt="lv-up" />
            <h3>
              Lv.
              {level ? level + 1 : ''}
            </h3>
          </>
        </div>
        <>
          <div className="ground__level--experience content">
            <div className="experience--icon">
              <img src="/images/popup/icon1.png" alt="Icon1" /> <p>Attack</p>
            </div>
            <div className="experience--span">
              <span>
                <CardValue value={parseFloat(characters[selectedCharacter]?.attack) || 0} decimals={0} />
              </span>
              <span className="span--update">+5</span>
            </div>
            <div className="experience--icon">
              <img src="/images/popup/icon2.png" alt="Icon1" /> <p>Defense</p>
            </div>
            <div className="experience--span">
              <span>
                <CardValue value={parseFloat(characters[selectedCharacter]?.defense) || 0} decimals={0} />
              </span>
              <span className="span--update">+2</span>
            </div>
            <div className="experience--icon">
              <img src="/images/popup/icon3.png" alt="Icon1" /> <p>Speed</p>
            </div>
            <div className="experience--span">
              <span>
                <CardValue value={parseFloat(characters[selectedCharacter]?.speed) || 0} decimals={0} />
              </span>
              <span className="span--update">+1</span>
            </div>
          </div>

          <div className="ground__price">
            <div className="experience--icon">
              <img src="/images/icon/angels-token.png" alt="Icon1" />
              <p>
                <CardValue
                  value={parseFloat(formatEther(BigNumber.from(unlockLevelPrice[level - 1]?.angel || 0)))}
                  decimals={0}
                />
              </p>
            </div>
            <div className="experience--icon">
              <img src="./images/icon/gem-token.png" alt="Icon1" />
              <p>
                <CardValue
                  value={parseFloat(formatEther(BigNumber.from(unlockLevelPrice[level - 1]?.gem || 0)))}
                  decimals={0}
                />
              </p>
            </div>
          </div>

          <div className="ground__success" onClick={handleShowNote}>
            <p>
              Success <CardValue value={parseInt(unlockLevelPrice[level - 1]?.successChance) || 0} decimals={0} />%
            </p>
            <img src="/images/popup/icon6.png" alt="Icon6.png" />
          </div>

          {userTokenData.angelAllowance === '0' ? (
            <Button onClick={() => handleApprove()} isLoading={isApproving || characters.length === 0}>
              <img src="/images/icon/angels-token.png" alt="angels-token.png" />
              Enable
            </Button>
          ) : (
            <Button onClick={handleUpgrade} isLoading={isUpgrading || characters.length === 0}>
              Upgrade
            </Button>
          )}
        </>
        )
      </div>
    </div>
  )
}
