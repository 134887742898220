import styled from 'styled-components'

export default function Button({ children, isLoading = false, onClick = null }) {
  return (
    <Styled onClick={isLoading ? null : onClick}>
      <div className={isLoading ? 'button-upgrade disable' : 'button-upgrade'}>{children}</div>
    </Styled>
  )
}

const Styled = styled.div`
  .button-upgrade {
    background-image: url('/images/popup/button-bg.gif');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: max-content;
    cursor: pointer;
    transition: ease all 0.5s;
    text-transform: capitalize;
    width: 240px;
    height: 100px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    transition: 0.5s ease-in-out;

    &.disable {
      transition: 0.5s ease-in-out;
      filter: grayscale();
      cursor: no-drop;
      color: #211915;
    }

    &:hover {
      transition: ease all 0.5s;
      opacity: 0.8;
    }
  }

  img {
    width: 45px;
    padding: 0;
    margin-right: 5px;
  }
`
