import { createAsyncThunk } from '@reduxjs/toolkit'
import { groupBy, merge, keyBy, map, values as _values } from 'lodash'
import marketplaceApi from 'config/abi/AngelCreedMarketPlace.json'
import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import characterAbi from 'config/abi/character.json'
import { getMarketplaceAddress, getFakeCharacterAddress, getCharacterAddress } from 'utils/addressHelpers'
import { getMarketplaceContract, getCharacterContract, getFakeCharacterContract } from 'utils/contractHelpers'
import { _getCharactersByIds, _getListingDataByIdentifiers } from 'state/marketplace/utils/contractInteraction'
import { fetchingInventory } from './actions'

export const fetchInventoryCharacters = createAsyncThunk(
  'marketplace/fetchInventoryCharacters',
  async (account: string, { dispatch }) => {
    let characters = []
    let characterIds = []
    let fakeCharacterIds = []

    try {
      const sellerListings = await _fetchInventoryListingCharacters(account)
      const characterContract = getCharacterContract()
      const fakeCharacterContract = getFakeCharacterContract()

      await Promise.all([
        characterContract?.tokensOfOwner(account),
        fakeCharacterContract?.tokensOfOwner(account),
      ]).then((values) => {
        characterIds = [...values[0], ...map(sellerListings[getCharacterAddress()], 'id')]
        if (values[1] && values[1].length > 0) {
          fakeCharacterIds = [...values[1], ...map(sellerListings[getFakeCharacterAddress()], 'id')]
        }
      })

      await Promise.all([
        _getCharactersByIds(getCharacterAddress(), characterAbi, 'getHero', characterIds, true),
        _getCharactersByIds(getFakeCharacterAddress(), fakeCharacterAbi, 'getHero', fakeCharacterIds, false),
      ]).then((values) => {
        characters = [
          ..._values(merge(keyBy(values[0], '_id'), keyBy(sellerListings[getCharacterAddress()], '_id'))),
          ..._values(merge(keyBy(values[1], '_id'), keyBy(sellerListings[getFakeCharacterAddress()], '_id'))),
        ]
      })

      dispatch(fetchingInventory(false))

      return characters
    } catch (e) {
      console.log(e)
    }
  },
)

const _fetchInventoryListingCharacters = async (account) => {
  const marketplaceContract = getMarketplaceContract()
  const tokenListed = await marketplaceContract?.getListingBySeller(account)

  const results = await _getListingDataByIdentifiers(
    getMarketplaceAddress(),
    marketplaceApi,
    'getListingByIdentifier',
    tokenListed,
  )

  return groupBy(results, 'listingNft')
}
