import axios from 'axios'
import PopupLeaderBoard from 'components2/Common/Popup/PopupLeaderBoard'
import GlobalModal from 'components2/static/GlobalModal'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const baseURL = 'https://api-app.angelscreed.com/leaderboard?amount=10'

function LeaderBoard(props) {
  const listReward = [
    {
      id: 1,
      reward: '',
    },
    {
      id: 2,
      reward: '15.000',
    },
    {
      id: 3,
      reward: '10.000',
    },
    {
      id: 4,
      reward: '3.500',
    },
    {
      id: 5,
      reward: '3.500',
    },
    {
      id: 6,
      reward: '1.500',
    },
    {
      id: 7,
      reward: '1.500',
    },
    {
      id: 8,
      reward: '1.500',
    },
    {
      id: 9,
      reward: '1.500',
    },
    {
      id: 10,
      reward: '1.500',
    },
  ]

  const [listLeader, setListLeader] = useState(null)

  useEffect(() => {
    const fetchLeaderBoard = async () => {
      const { data } = await axios.get(baseURL)
      setListLeader(data)
    }
    fetchLeaderBoard()
  }, [])

  if (!listLeader) {
    return (
      <>
        <PopupLeaderBoard onClick={GlobalModal.hide} title="LEADERBOARD">
          <LeaderBoardStyled>
            <div className="title">
              <p>ID</p>
              <p>Total GEM earned</p>
              <p>Reward</p>
            </div>
            <div className="leaderBoard__Loading"></div>
          </LeaderBoardStyled>
        </PopupLeaderBoard>
      </>
    )
  }
  const trimPlayer = listLeader.map((item) => ({
    ...item,
    player: `${item.player.slice(0, 4)}...${item.player.slice(38)}`,
  }))
  return (
    <PopupLeaderBoard onClick={GlobalModal.hide} title="LEADERBOARD">
      <LeaderBoardStyled>
        <div className="title">
          <p>ID</p>
          <p>Total GEM earned</p>
          <p>Reward</p>
        </div>

        {listLeader.map((reward, index) => (
          <div className="border__contents" key={index}>
            <div className="border__contents__item id">
              {index === 0 ? (
                <span className="id" style={styledId1}>
                  {index + 1}
                </span>
              ) : index === 1 ? (
                <span className="id" style={styledId2}>
                  {index + 1}
                </span>
              ) : index === 2 ? (
                <span className="id" style={styledId3}>
                  {index + 1}
                </span>
              ) : (
                <span className="id">{index + 1}</span>
              )}
              <span>{trimPlayer[index].player}</span>
            </div>
            <div className="border__contents__item">
              <img src="./images/icon/gem-token.png" alt="gem-token.png" />
              <span>{reward.totalGemRewards}</span>
            </div>
            <div className="border__contents__item price">
              {index === 0 ? (
                <div className="border__contents__item--img">
                  {' '}
                  <img src="/images/market/ssr.png" alt="img-token" />
                </div>
              ) : (
                <img src="/images/icon/angels-token.png" alt="img-token" />
              )}

              <span>{listReward[index].reward}</span>
            </div>
          </div>
        ))}
      </LeaderBoardStyled>
    </PopupLeaderBoard>
  )
}

const styledId1 = {
  background: '#FFB800',
  border: '3px solid #FFD977',
  color: '#fff',
}

const styledId2 = {
  background: '#858585',
  border: '3px solid #BABABA',
  color: '#fff',
}

const styledId3 = {
  background: '#F2894E',
  border: '3px solid #FFBF9B',
  color: '#fff',
}

const LeaderBoardStyled = styled.div`
  position: relative;
  .leaderBoard__Loading {
    position: absolute;
    top: 35%;
    left: 35%;
    transform: translate(-50%, 0%);
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 220px;
    height: 220px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  height: 680px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    padding: 0px 50px;
  }
  .border__contents {
    border-top: 1px solid #b7b7b7;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 8px 0px;
    span {
      font-size: 14px;
      font-weight: 500;
    }
    span.id {
      width: 50px;
      border: solid 3px transparent;
      height: 50px;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      font-weight: 700;
      color: #2b4fac;
      margin-right: 5px;
    }
    .border__contents__item.id {
      width: 260px;
    }
    .border__contents__item {
      width: 340px;
      display: flex;
      justify-content: left;
      align-items: center;
      img {
        width: 40px;
        margin-right: 5px;
      }

      .border__contents__item--img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        img {
          width: 75px;
          height: 100%;
        }
      }
    }
    .border__contents__item.price {
      width: 100px;
      display: flex;
      margin-left: 10px;
      justify-content: left;
      align-items: center;
    }
  }
`
export default LeaderBoard
