import characterAbi from 'config/abi/character.json'
import erc20ABI from 'config/abi/erc20.json'
import gameConfig, { gameCharacter } from 'config/constants/game'
import { getAddress, getCharacterAddress, getFakeCharacterAddress, getGameAddress } from 'utils/addressHelpers'
import { getCharacterContract, getGameContract, getFakeCharacterContract } from 'utils/contractHelpers'
import multicall from 'utils/multicall'
import fakeCharacterAbi from 'config/abi/FakeCharacter.json'
import { Character } from '../types'

export const fetchGameUserTokenInfo = async (account: string) => {
  const calls = []
  const angelToken = getAddress(gameConfig.angelAddresses)
  const busdToken = getAddress(gameConfig.busdAddresses)

  calls.push({ address: angelToken, name: 'balanceOf', params: [account] })
  calls.push({ address: busdToken, name: 'balanceOf', params: [account] })
  calls.push({ address: angelToken, name: 'allowance', params: [account, getGameAddress()] })
  calls.push({ address: busdToken, name: 'allowance', params: [account, getGameAddress()] })

  try {
    const [angelBalance, busdBalance, angelAllowance, busdAllowance] = await multicall(erc20ABI, calls, {
      requireSuccess: false,
    })

    return {
      angelBalance: angelBalance.toString(),
      gemBalance: busdBalance.toString(),
      angelAllowance: angelAllowance.toString(),
      busdAllowance: busdAllowance.toString(),
    }
  } catch (error) {
    console.log('error fetchGameUserTokenInfo', error)
    return {
      angelBalance: '0',
      gemBalance: '0',
      angelAllowance: '0',
      busdAllowance: '0',
    }
  }
}

export const fetchGameUserInfo = async (account: string) => {
  try {
    const gameContract = getGameContract()

    const user = await gameContract.users(account)
    return {
      currentRound: user.currentRound.toString(),
      angelReward: user.angelReward.toString(),
      gemReward: user.gemReward.toString(),
      lastTimeClaim: (parseInt(user.lastTimeClaim.toString()) * 1000).toString(),
    }
  } catch (error) {
    console.log('error fetchGameUserRewards', error)
    return {
      currentRound: '0',
      angelReward: '0',
      gemReward: '0',
      lastTimeClaim: '0',
    }
  }
}

export const fetchUserCharacter = async (account: string): Promise<Character[]> => {
  const characterContract = getCharacterContract()
  const tokenIds = await characterContract?.tokensOfOwner(account)

  const calls = []
  tokenIds.forEach((tokenId) => {
    calls.push({ address: getCharacterAddress(), name: 'getHero', params: [tokenId.toString()] })
  })

  try {
    const results = await multicall(characterAbi, calls, { requireSuccess: false })

    return results
      .map((result) => {
        return {
          heroRarity: result[0].heroRarity.toString(),
          heroName: result[0].heroName.toString(),
          attack: result[0].attack.toString(),
          defense: result[0].defense.toString(),
          speed: result[0].speed.toString(),
          energy: result[0].energy.toString(),
          tokenId: result[0].tokenId.toString(),
          level: result[0].level.toString(),
          swordId: result[0].swordId.toString(),
          armorId: result[0].armorId.toString(),
          shoesId: result[0].shoesId.toString(),
          timesBoostedEnergy: result[0].timesBoostedEnergy.toString(),
          lastFightTime: (parseInt(result[0].lastFightTime.toString()) * 1000).toString(),
          ...gameCharacter[result[0].heroRarity.toNumber()][result[0].heroName.toNumber()],
        }
      })
      .sort((a, b) => b.heroRarity - a.heroRarity)
  } catch (error) {
    console.log('error fetchUserCharacter', error)
    return []
  }
}

export const fetchUserFakeCharacter = async (account: string): Promise<Character[]> => {
  try {
    const fakeCharacterContract = getFakeCharacterContract()
    if (!fakeCharacterContract) {
      return []
    }
    const tokenIds = await fakeCharacterContract?.tokensOfOwner(account)

    // console.log('tokenIds', tokenIds)
    const calls = []
    tokenIds.forEach((tokenId) => {
      calls.push({ address: getFakeCharacterAddress(), name: 'getHero', params: [tokenId.toString()] })
    })

    const results = await multicall(fakeCharacterAbi, calls, { requireSuccess: false })

    return results
      .map((result) => {
        return {
          tokenId: result[0].tokenId.toString(),
          heroRarity: result[0].heroRarity.toString(),
          heroName: result[0].heroName.toString(),
          ...gameCharacter[result[0].heroRarity.toNumber()][result[0].heroName.toNumber()],
        }
      })
      .sort((a, b) => b.heroRarity - a.heroRarity)
  } catch (error) {
    console.log('error fetchUserCharacter', error)
    return []
  }
}

export const fetchCharacter = async (heroId: string): Promise<Character> => {
  const characterContract = getCharacterContract()
  try {
    const character = await characterContract.getHero(heroId)
    return {
      heroRarity: character.heroRarity.toString(),
      heroName: character.heroName.toString(),
      attack: character.attack.toString(),
      defense: character.defense.toString(),
      speed: character.speed.toString(),
      energy: character.energy.toString(),
      tokenId: character.tokenId.toString(),
      level: character.level.toString(),
      swordId: character.swordId.toString(),
      armorId: character.armorId.toString(),
      shoesId: character.shoesId.toString(),
      timesBoostedEnergy: character.timesBoostedEnergy.toString(),
      lastFightTime: (parseInt(character.lastFightTime.toString()) * 1000).toString(),
      ...gameCharacter[character.heroRarity.toNumber()][character.heroName.toNumber()],
    }
  } catch (error) {
    console.log('error fetch character')
    return null
  }
}

export const fetchUserEquipment = async (account: string) => {
  // const enemyContract = getEquipmentContract()

  // console.log('fetch equipment')
  return []
}
