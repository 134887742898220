import priceOracleAbi from 'config/abi/PriceOracle.json'
import { ethers } from 'ethers'
import { getOracleAddress } from 'utils/addressHelpers'
import { getGameWikiContract, getPriceOracleContract } from 'utils/contractHelpers'
import multicall from 'utils/multicall'
import { upgradeChance } from 'config/constants/game'
import { GamePrice } from '../types'

const fetchGamePrice = async (): Promise<GamePrice> => {
  const oracleContract = getPriceOracleContract()
  const gameWikiContract = getGameWikiContract()
  const calls = []
  try {
    calls.push({ address: getOracleAddress(), name: 'getFullGemToFightInRound', params: [] })
    calls.push({ address: getOracleAddress(), name: 'getAngelUnlockLevel', params: [] })
    calls.push({ address: getOracleAddress(), name: 'getGemUnlockLevel', params: [] })
    calls.push({ address: getOracleAddress(), name: 'gemToRegain1Energy', params: [] })
    calls.push({ address: getOracleAddress(), name: 'angelToOpenMysteryBox', params: [] })

    const [rawFightPrice, rawAngelUnlockPrice, rawGemUnlockPrice, rawGemToRegain1Energy, rawAngelToOpenMysteryBox] =
      await multicall(priceOracleAbi, calls, {
        requireSuccess: false,
      })

    const [angelToCreateHero, usdToCreateHero, rawFailureUnlock] = await Promise.all([
      oracleContract.angelToCreateHero(),
      oracleContract.usdToCreateHero(),
      gameWikiContract.getFailureUnlockLevel(),
    ])

    return {
      createNewHeroPrice: {
        angel: angelToCreateHero.toString(),
        busd: usdToCreateHero.toString(),
      },
      unlockLevelPrice: rawAngelUnlockPrice[0].map((angel, index) => {
        return {
          angel: angel.toString(),
          gem: rawGemUnlockPrice[0][index].toString(),
          successChance: (100 - upgradeChance[index]).toString(),
          // successChance: (100 - rawFailureUnlock[index].toNumber()).toString(),
        }
      }),
      fightPrice: rawFightPrice[0].map((p) => p.toString()),
      regain1EnergyPrice: ethers.utils.formatEther(rawGemToRegain1Energy[0]),
      openMysteryBoxPrice: rawAngelToOpenMysteryBox[0].toString(),
    }
  } catch (error) {
    console.log('error', error)
  }
}

export default fetchGamePrice
