import React, { useState } from 'react'
import { Pagination } from 'antd'
import Back from 'components2/Common/Back'
import Popup from 'components2/static/GlobalModal'
import ListItemWithTabsInventory, { CardItem } from 'components2/Common/ListItemWithTabsInventory'
import CardValue from 'components/CardValue'
import PopupNotice from 'components2/Common/Popup/PopupNotice'

import PopupUpgrade from 'components2/Common/Popup/PopupUpgrade/index'
import { useGameUserData, useIsLoading } from 'state/hooks'
import Recruit from './components/Recruit'
import Styled from './styled'

export default function Inventory({ setCurrentPage }) {
  const [activeTabId, setActiveTabId] = useState('TAB_HERO')
  const [selectCharacter, setSelectCharacter] = useState(0)

  const [curPage, setCurPage] = useState(1)
  const { characters, fakeCharacters } = useGameUserData()

  const inventoryCharacter = fakeCharacters?.concat(characters)
  const isLoading = useIsLoading()

  const formattedInventory = inventoryCharacter?.slice((curPage - 1) * Pagesize, curPage * Pagesize)

  const handleShowPopupUpgrade = () => {
    const selectedIndex = selectCharacter + (curPage - 1) * Pagesize - fakeCharacters.length
    if (inventoryCharacter.length === 0)
      Popup.show(<PopupNotice onClose={Popup.hide} textNotice="You don't have any character" />)
    else Popup.show(<PopupUpgrade heroIndex={selectedIndex} closePopup={Popup.hide} />)
  }

  // commit
  return (
    <Styled>
      <div className="box-video">
        <video autoPlay playsInline loop muted>
          <source src="/images/inventory/bgvideo.webm" type="video/webm" />
          <source src="/images/inventory/bgvideo.mov" type="video/quicktime" />
        </video>
      </div>

      <div className="top-bar">
        <Back onClick={() => setCurrentPage('home')} />
      </div>

      <div className="group">
        <ListItemWithTabsInventory
          tabs={[
            { name: 'Hero', id: 'TAB_HERO' },
            { name: 'Weapon', id: 'TAB_WEAPON' },
          ]}
          onClickTab={(tab) => setActiveTabId(tab.id)}
          activeTabId={activeTabId}
          filter={null}
        >
          {activeTabId === 'TAB_HERO' ? (
            <TabHero
              total={inventoryCharacter?.length ?? 0}
              onSelectCharacter={setSelectCharacter}
              formattedInventory={formattedInventory ?? []}
              currentPage={curPage}
              setCurrentPage={setCurPage}
              isLoading={isLoading}
            />
          ) : (
            <img className="no-item" src="images/inventory/no-item.png" alt="" />
          )}
        </ListItemWithTabsInventory>
        {!isLoading && (
          <div>
            <div className="review">
              <img className="item-review" src={formattedInventory[selectCharacter]?.reviewImg} alt="" />

              <div className="action">
                <img className="upgrade" src="/images/inventory/upgrade.png" alt="" onClick={handleShowPopupUpgrade} />
                {/* <img className="sell" src="/images/inventory/sell.png" alt="" /> */}
              </div>

              <div className="group-info">
                <div className="main-info">
                  <div className="level">LV. {formattedInventory[selectCharacter]?.level}</div>
                  <div className="user-name">{formattedInventory[selectCharacter]?.name}</div>
                </div>

                <div className="info">
                  <div className="icon">
                    <img src="/images/inventory/icon1.png" alt="" />
                  </div>
                  <div className="name">Energy</div>
                  <div className="detail">
                    <CardValue
                      value={parseFloat(formattedInventory[selectCharacter]?.energy) || 0}
                      decimals={0}
                    ></CardValue>
                    /5
                  </div>
                </div>

                <div className="info">
                  <div className="icon">
                    <img src="/images/inventory/icon3.png" alt="" />
                  </div>
                  <div className="name">Defense</div>
                  <div className="detail">
                    <CardValue
                      value={parseFloat(formattedInventory[selectCharacter]?.defense) || 0}
                      decimals={0}
                    ></CardValue>
                  </div>
                </div>
                <div className="info">
                  <div className="icon">
                    <img src="/images/inventory/icon2.png" alt="" />
                  </div>
                  <div className="name">Attack</div>
                  <div className="detail">
                    <CardValue
                      value={parseFloat(formattedInventory[selectCharacter]?.attack) || 0}
                      decimals={0}
                    ></CardValue>
                  </div>
                </div>
                <div className="info">
                  <div className="icon">
                    <img src="/images/popup/icon3.png" alt="" />
                  </div>
                  <div className="name">Speed</div>
                  <div className="detail">
                    <CardValue
                      value={parseFloat(formattedInventory[selectCharacter]?.speed) || 0}
                      decimals={0}
                    ></CardValue>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Styled>
  )
}

const Pagesize = 7

const TabHero = ({ total, onSelectCharacter, formattedInventory, currentPage, setCurrentPage, isLoading }) => {
  return (
    <>
      <Recruit />
      {formattedInventory.map((item, index) => {
        if (item.attack)
          return (
            <CardItem
              onSelectCharacter={() => onSelectCharacter(index)}
              imgCharacter={item.inventoryImg}
              imgProperty={item.rarityImg}
              title={item.name}
            />
          )
        return (
          <CardItem
            heroId={item.tokenId}
            onSelectCharacter={() => onSelectCharacter(index)}
            imgCharacter={item.inventoryImg}
            imgProperty={item.rarityImg}
            title={item.name}
            isFakeCharacter
          />
        )
      })}
      <div className="box-pagination">
        <Pagination
          defaultCurrent={1}
          pageSize={Pagesize}
          total={total}
          current={currentPage}
          onChange={(page) => {
            setCurrentPage(page)
          }}
        />
      </div>
    </>
  )
}
