import React from 'react'
import styled from 'styled-components'
import PopupConnect from 'components2/Common/Popup/PopupConnect'
import { gameCharacter } from 'config/constants/game'

function PopupEgg({ onClose, newHero }) {
  const newCharacter = {
    id: newHero?._heroId.toString(),
    ...gameCharacter[newHero?.heroRarity.toNumber()][newHero?.heroName.toNumber()],
  }
  return (
    <PopupConnect onClick={onClose} title="congratulations">
      <PopupConnectStyled>
        <LeftStyled>
          ID: <span>{newCharacter.id - 1}</span>
        </LeftStyled>

        <CenterStyled>
          <CharacterStyled>
            {/* <img src="images/inventory/character/angel-review.png" alt="" /> */}
            <img src={newCharacter.reviewImg} alt="" />
          </CharacterStyled>
          <img className="bg-bot" src="images/egg/bottom-nv.png" alt="" />

          <TitleNameStyled>{newCharacter.name}</TitleNameStyled>
        </CenterStyled>

        <RightStyled>
          <img src={newCharacter.rarityImg} alt="" />
        </RightStyled>
      </PopupConnectStyled>
    </PopupConnect>
  )
}

const PopupConnectStyled = styled.div`
  background: url('../images/egg/mask.png') no-repeat;
  width: 100%;
  height: 585px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 70px 0;
`

const LeftStyled = styled.div`
  font-size: 1.6em;
  font-weight: 700;
  color: #ffffff;
  padding-left: 50px;

  span {
    font-weight: 500;
  }
`

const CenterStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  .bg-bot {
    margin-top: -47px;
  }
`

const CharacterStyled = styled.div`
  position: relative;
  z-index: 2;

  img {
    width: 300px;
  }
`
const TitleNameStyled = styled.div`
  font-size: 2.6em;
  font-weight: 600;
  color: #ffffff;
  margin-top: 15px;
`

const RightStyled = styled.div`
  text-align: center;

  img {
    width: 100px;
  }
`
export default PopupEgg
