import { useWeb3React } from '@web3-react/core'
import Back from 'components2/Common/Back'
import GlobalModal from 'components2/static/GlobalModal'
import { useClaimRewards } from 'hooks/useGame'
import React, { useState } from 'react'
import { useAppDispatch } from 'state'
import { fetchUserGameInfo, setSelectedRound } from 'state/game'
import { useGameUserData } from 'state/hooks'
import PopupResult from './PopupResult'
import Styled from './styled'

export default function Mapgame({ setCurrentPage }) {
  const [isOpenChest, setIsOpenChest] = useState(false)
  const dispatch = useAppDispatch()
  const { userGameData } = useGameUserData()
  const { onClaimRewards } = useClaimRewards()
  const { account, chainId } = useWeb3React()
  const handleSelectMap = (round: number) => {
    dispatch(setSelectedRound(round))
    setCurrentPage('angelspve')
  }

  const handleClaimReward = async () => {
    try {
      const tx = await onClaimRewards()
      const result = await tx?.wait()

      setIsOpenChest(true)
      setTimeout(() => {
        GlobalModal.show(
          <PopupResult
            // reward={result.events[result.events.length - 1].args.angelReward.toString()}
            reward="2000000000000000000000"
            onBackToFight={GlobalModal.hide}
          />,
        )
      }, 1000)
      dispatch(fetchUserGameInfo(account))
    } catch (error) {
      setIsOpenChest(false)
    }
  }
  return (
    <Styled>
      <div className="top-bar">
        <Back onClick={() => setCurrentPage('island')} />
      </div>

      <div className="box-content">
        {Array.from(Array(10).keys()).map((m) => {
          if (m === 9)
            return (
              <div
                className={`item  ${parseInt(userGameData.currentRound) >= 10 ? 'done' : 'active-win'}`}
                onClick={parseInt(userGameData.currentRound) >= 9 ? () => handleSelectMap(m) : null}
              >
                {m + 1}
              </div>
            )
          if (m < parseInt(userGameData.currentRound))
            return (
              <div className="item active-star" onClick={() => handleSelectMap(m)}>
                {m + 1}
              </div>
            )
          if (m === parseInt(userGameData.currentRound))
            return (
              <div className="item active-knife" onClick={() => handleSelectMap(m)}>
                {m + 1}
              </div>
            )
          return <div className="item">{m + 1}</div>
        })}

        {/* <div className="item active-star">1</div>
        <div className="item active-star">2</div>

        <div className="item active-knife">3</div>

        <div className="item">4</div>

        <div className="item">5</div>

        <div className="item">6</div>

        <div className="item">7</div>

        <div className="item">8</div>

        <div className="item">9</div> */}

        {/* <div className="item active-win">10</div> */}
      </div>

      <div className="box-diamond-chest">
        {userGameData.currentRound === '10' && userGameData.angelReward !== '0' ? (
          <div>
            {!isOpenChest ? (
              <img
                className="ring"
                src="./images/mapgame/diamond-chest-ringring.gif"
                alt=""
                onClick={handleClaimReward}
              />
            ) : (
              <img src="./images/mapgame/diamond-chest-light.gif" alt="" />
            )}
          </div>
        ) : (
          <>
            {userGameData.currentRound === '10' && userGameData.angelReward === '0' ? (
              <img src="./images/mapgame/diamond-chest-light.gif" alt="" />
            ) : (
              <img src="./images/mapgame/diamond-chest.png" alt="" />
            )}
          </>
        )}
        {/*  */}
      </div>
    </Styled>
  )
}
