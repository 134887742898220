import { useWeb3React } from '@web3-react/core'
import CardValue from 'components/CardValue'
import Button from 'components2/Common/Button'
import PopupConnect from 'components2/Common/Popup/PopupConnect'
import GlobalModal from 'components2/static/GlobalModal'
import { useFaucetContract } from 'hooks/useContract'
import { useFaucet } from 'hooks/useGame'
import useRefresh from 'hooks/useRefresh'
import React, { useEffect, useState } from 'react'
import { useAppDispatch } from 'state'
import styled from 'styled-components'

function ClaimRewald(props) {
  const [isFaucet, setIsFaucet] = useState(true)
  const [totalUser, setTotalUser] = useState('0')
  const { onSendToken } = useFaucet()
  const dispatch = useAppDispatch()
  const { account, chainId } = useWeb3React()

  const faucetContract = useFaucetContract()
  const { fastRefresh } = useRefresh()

  const [isLoading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const fetchData = async () => {
      const user = await faucetContract.users(account)
      const totalBig = await faucetContract.totalUser()
      setIsFaucet(user.toString() === 'true')
      setTotalUser(totalBig.toString())
    }
    fetchData()
  }, [account, fastRefresh])

  const handleFaucet = async () => {
    try {
      setLoading(true)
      const tx = await onSendToken()
      await tx?.wait()

      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const claimList = [
    {
      img: './images/icon/angels-token.png',
      price: '500000',
    },
    {
      img: './images/inventory/price-busd.svg',
      price: '500000',
    },
  ]
  return (
    <PopupConnect onClick={GlobalModal.hide} title="Faucet">
      <ClaimRewardStyled>
        <div className="header">Total user faucet: {totalUser}</div>
        <div className="claimRewald">
          {claimList.map((claim, index) => (
            <div className="claimRewald__price" key={index}>
              <img src={claim.img} alt="icon-price" />
              <CardValue value={!isFaucet ? parseFloat(claim.price) : 0} decimals={0}></CardValue>
            </div>
          ))}
        </div>
        <Button onClick={handleFaucet} isLoading={isLoading || isFaucet}>
          Faucet
        </Button>
      </ClaimRewardStyled>
    </PopupConnect>
  )
}

export const ClaimRewardStyled = styled.div`
  text-align: center;
  padding-bottom: 3rem;
  .button-upgrade {
    margin: 0 auto;
  }
  .claimRewald {
    display: flex;
    justify-content: space-around;
    padding: 3rem 2rem;
    padding-bottom: 4rem;
  }
  .header {
    color: #fff;
    font-size: 50px;
    padding-top: 3rem;
  }

  .claimRewald__price {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: ease all 0.5s;
    color: #ffffff;
    font-size: 26px;
    font-weight: 800;
    &:hover {
      opacity: 0.8;
    }
    p {
      margin: 0;
      color: #fff;
      font-size: 34px;
      font-weight: 500;
    }
    img {
      width: 55px;
      margin-right: 10px;
    }
  }
`
export default ClaimRewald
