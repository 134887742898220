import { useFightResult } from 'state/hooks'
import styled from 'styled-components'

const CharWinImg = styled.div`
  animation-name: char-win-animation;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  z-index: 1;
  opacity: 0;

  @keyframes char-win-animation {
    0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5);
    }

    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }

  .flip {
    transform: scaleX(-1);
  }
`

const CharWin = (props) => {
  const fightResult = useFightResult()

  const { leftCharImg, rightCharImg } = props

  const src = fightResult.gemReward !== '0' ? leftCharImg : rightCharImg
  const flip = fightResult.gemReward !== 0
  return (
    <CharWinImg
      {...props}
      style={{ top: '45%', left: '50%', position: 'absolute', transform: 'translate(-50%, -50%)' }}
    >
      <img src={src} alt="win-char" className={flip ? 'flip' : ''} style={{ height: 536 }} />
    </CharWinImg>
  )
}

export default CharWin
