import { useWeb3React } from '@web3-react/core'
import CardValue from 'components/CardValue'
import Button from 'components2/Common/Button'
import PopupConnect from 'components2/Common/Popup/PopupConnect'
import GlobalModal from 'components2/static/GlobalModal'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { useClaimRewards } from 'hooks/useGame'
import React, { useState } from 'react'
import { useAppDispatch } from 'state'
import { fetchGameUserData } from 'state/actions'
import { useGameUserData } from 'state/hooks'
import styled from 'styled-components'

function ClaimRewald(props) {
  const { onClaimRewards } = useClaimRewards()
  const { userGameData } = useGameUserData()
  const dispatch = useAppDispatch()
  const { account, chainId } = useWeb3React()

  const [isLoading, setLoading] = useState<boolean>(false)

  const handleClaimRewards = async () => {
    try {
      setLoading(true)
      const tx = await onClaimRewards()
      await tx?.wait()

      dispatch(fetchGameUserData(account) as any)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const claimList = [
    {
      img: './images/icon/angels-token.png',
      price: formatEther(BigNumber.from(userGameData.angelReward)),
    },
    {
      img: './images/icon/gem-token.png',
      price: formatEther(BigNumber.from(userGameData.gemReward)),
    },
    // {
    //   img: './images/icon/key-reward.png',
    //   price: userGameData.keyReward,
    // },
  ]
  return (
    <PopupConnect onClick={GlobalModal.hide} title="CLAIM REWARD">
      <ClaimRewardStyled>
        <div className="claimRewald">
          {claimList.map((claim, index) => (
            <div className="claimRewald__price" key={index}>
              <img src={claim.img} alt="icon-price" />
              <CardValue value={parseFloat(claim.price) || 0} decimals={2}></CardValue>
            </div>
          ))}
        </div>
        <Button onClick={handleClaimRewards} isLoading={isLoading || parseFloat(claimList[1].price) === 0}>
          Claim
        </Button>
      </ClaimRewardStyled>
    </PopupConnect>
  )
}

export const ClaimRewardStyled = styled.div`
  text-align: center;
  padding-bottom: 3rem;
  .button-upgrade {
    margin: 0 auto;
  }
  .claimRewald {
    display: flex;
    justify-content: space-around;
    padding: 8rem 2rem;
    padding-bottom: 4rem;
  }

  .claimRewald__price {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: ease all 0.5s;
    color: #ffffff;
    font-size: 26px;
    font-weight: 900;
    &:hover {
      opacity: 0.8;
    }
    p {
      padding-left: 10px;
      margin: 0;
      color: #fff;
      font-size: 34px;
      font-weight: 500;
    }
  }
`
export default ClaimRewald
