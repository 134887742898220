import React from 'react'
import CardValue from 'components/CardValue'

function CharacterHeroEXP({ erj = '0', attack = '0', def = '0', speed = '0' }) {
  return (
    <div className="CharaterHero__attribute">
      <div className="CharaterHero__attribute--bg">
        <div className="CharaterHero__attribute--icon">
          <img src="./images/popup/icon7.png" alt="icon" />
          <p>Energy</p>
        </div>
        <p>
          <CardValue value={parseFloat(erj) || 0} decimals={0}></CardValue>
        </p>
      </div>
      <div className="CharaterHero__attribute--bg">
        <div className="CharaterHero__attribute--icon">
          <img src="./images/popup/icon1.png" alt="icon" />
          <p>Attack</p>
        </div>

        <p>
          <CardValue value={parseFloat(attack) || 0} decimals={0}></CardValue>
        </p>
      </div>
      <div className="CharaterHero__attribute--bg">
        <div className="CharaterHero__attribute--icon">
          <img src="./images/popup/icon2.png" alt="icon" />
          <p>Defense</p>
        </div>

        <p>
          <CardValue value={parseFloat(def) || 0} decimals={0}></CardValue>
        </p>
      </div>
      <div className="CharaterHero__attribute--bg">
        <div className="CharaterHero__attribute--icon">
          <img src="./images/popup/icon3.png" alt="icon" />
          <p>Speed</p>
        </div>

        <p>
          <CardValue value={parseFloat(speed) || 0} decimals={0}></CardValue>
        </p>
      </div>
    </div>
  )
}

export default CharacterHeroEXP
