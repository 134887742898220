import React from 'react'
import styled from 'styled-components'

const PopupConnect = ({ onClick, title, children }) => {
  return (
    <PopupConnectStyled>
      <div className="popupConnect__wallet">
        <img className="close-icon" src="/images/pve/close-icon.png" alt="close-icon" onClick={onClick} />
        <div className="popupConnect__wallet--img">
          <p>{title}</p>
        </div>
      </div>
      {children}
    </PopupConnectStyled>
  )
}

export const PopupConnectStyled = styled.div`
  background: url('./images/connect/bg-connect.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  width: 780px;
  .close-icon {
    position: absolute;
    right: -25px;
    top: -33px;
    cursor: pointer;
  }

  .popupConnect__wallet--img {
    background: url('./images/connect/wallet-title.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: -28px;
    left: 50%;
    width: 350px;
    text-align: center;
    transform: translate(-50%, 0px);
    z-index: 2;
    p {
      margin: 0;
      font-size: 22px;
      font-weight: 700;
      color: #fff;
      padding: 1rem 0px;
      text-transform: uppercase;
    }
  }
`
export default PopupConnect
